import * as React from "react";
import {
  ScrollView,
  View,
  RefreshControl,
  FlatList,
  Pressable,
  SectionList,
  Alert,
  Modal,
  Platform,
  Dimensions,
} from "react-native";
import {
  Text,
  Image,
  Theme,
  Button,
  ScaledButton,
  TurboSymbol,
  ImageBox,
} from "@app/ui";
import { find } from "lodash";
import { useEffect, useState } from "react";
import { LinearGradient } from "expo-linear-gradient";
import { useNavigation } from "@react-navigation/native";
import { PodcastCellItem } from "@app/music";
import { useHasAppFeature, useIsAdmin } from "@app/user/hooks/user";
import {
  follow,
  useFollowMutation,
  useIsFollowing,
} from "@app/social/hooks/follow";
import { useHeaderHeight } from "@react-navigation/elements";

interface Props {
  onHeightChange?: any;
}

export default (props: Props) => {
  const navigation = useNavigation();
  const coverImage = find(props?.media, { type: "cover" });
  const headerImage = find(props?.media, { type: "hero" });
  //const [isFollowing, setIsFollowing] = useState(props.social?.is_following);
  const isPlayable = useHasAppFeature("music");
  const isAdmin = useIsAdmin();
  const canUseSubscriptions = useHasAppFeature("points");
  const followMutation = useFollowMutation("page", props.id);
  const isFollowing = useIsFollowing("page", props.id, true);
  const isLoading = followMutation.isLoading || isFollowing.isLoading;
  const headerHeight = useHeaderHeight();

  // const setIsFollowedDelayed = (value: boolean) => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsFollowing(value);
  //     setIsLoading(false);
  //   }, 500);
  // };

  const renderLocation = () => {
    const styleText = {
      fontSize: 13,
      fontFamily: Theme.fonts.titles.medium,
      lineHeight: 20,
    };
    const styleSeparator = {
      fontFamily: Theme.fonts.titles.medium,

      lineHeight: 20,

      //marginVertical: 4,
    };

    const styles = props?.styles;

    return (
      <View style={{ flexDirection: "row", marginVertical: 4 }}>
        <Text style={styleText}>Deejay</Text>
        <Text style={styleSeparator}>・</Text>
        <Text style={styleText}>Barcelona, Spain</Text>
        <Text style={styleSeparator}>・</Text>
        {styles &&
          styles.map((style) =>
            renderMiniPill(
              style.name,
              { marginTop: 2, marginRight: 4 },
              style.appearance?.colors
            )
          )}
      </View>
    );
  };

  const renderDescription = () => {
    return (
      <Text
        style={{
          fontSize: 13,
          opacity: 0.75,
          fontFamily: Theme.fonts.titles.regular,
          marginVertical: 4,
          lineHeight: 16,
        }}
      >
        {props.description || "Description goes here"}
      </Text>
    );
  };

  const renderMiniPill = (name: string, style?, colors?) => {
    return (
      <ScaledButton onPress={() => Alert.alert(name)} key={name}>
        <LinearGradient
          style={{
            height: 15,
            borderRadius: 8,
            paddingHorizontal: 8,
            ...style,
          }}
          colors={[
            colors?.primary || "#ff512f",
            colors?.secondary || "#dd2476",
          ]}
          //colors={["#000", "#fff"]}
          start={{ x: 0.5, y: 0 }}
          end={{ x: 1, y: 1 }}
        >
          <Text
            style={{
              textTransform: "uppercase",
              lineHeight: 16,
              fontSize: 9,
              fontFamily: Theme.fonts.titles.medium,
            }}
          >
            {name}
          </Text>
        </LinearGradient>
      </ScaledButton>
    );
  };

  const renderActions = () => {
    const buttonStyle = {
      fontSize: 14,
      fontFamily: Theme.fonts.titles.medium,
      flex: 1,
    };

    return (
      <View style={{ flexDirection: "row", marginVertical: 16 }}>
        {isFollowing?.data?.is_following ? (
          <View style={{ flexDirection: "row", flex: 1 }}>
            <Button
              backgroundColor={"#444444"}
              textColor={"#ffffff"}
              onPress={() => {
                Alert.alert(props.name, "Are you sure?", [
                  {
                    text: "Cancel",
                    style: "cancel",
                  },
                  {
                    text: "Unfollow",
                    onPress: () => followMutation.mutate({ follow: false }),
                    style: "destructive",
                  },
                ]);
              }}
              containerStyle={{ marginRight: 12, ...buttonStyle }}
              symbol={"heart-broken-solid"}
              isLoading={isLoading}
            />
            <Button
              backgroundColor={"#444444"}
              textColor={"#ffffff"}
              onPress={() => setIsFollowedDelayed(true)}
              containerStyle={{ marginRight: 6, ...buttonStyle }}
              symbol={"alert-solid"}
              isLoading={isLoading}
            />
          </View>
        ) : (
          <Button
            text={"Follow"}
            backgroundColor={"#20D0A2"}
            textColor={"#ffffff"}
            onPress={() => {
              followMutation.mutate({ follow: true });
            }}
            containerStyle={{ marginRight: 6, ...buttonStyle }}
            symbol={"heart-line"}
            isLoading={isLoading}
          />
        )}
        {canUseSubscriptions && (
          <Button
            text={"Subscribe"}
            backgroundColor={isFollowing ? "#20D0A2" : "#444444"}
            textColor={"#ffffff"}
            onPress={() => Alert.alert("Soon... 🤟")}
            containerStyle={{ marginLeft: 6, ...buttonStyle }}
            symbol={"star-line"}
          />
        )}
      </View>
    );
  };

  const renderSuperPlay = () => {
    const buttonSize = 52;

    return (
      <View
        style={{
          height: 64,
          position: "absolute",
          zIndex: 22,
          marginTop: -44,
          right: 20,
        }}
      >
        <ScaledButton
          onPress={() => Alert.alert("Play")}
          style={{
            width: buttonSize,
            height: buttonSize,
            borderRadius: buttonSize / 2,
            backgroundColor: "#20D0A2",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TurboSymbol
            name="play-solid"
            color="#fff"
            size={20}
            style={{ marginLeft: 4 }}
          />
        </ScaledButton>
      </View>
    );
  };

  const renderTitle = () => {
    return (
      <Text
        style={{
          fontSize: 32,
          marginTop: 8,
          fontFamily: Theme.fonts.titles.medium,
        }}
      >
        {props.name}
      </Text>
    );
  };

  const renderSubsCarousel = () => {
    return (
      <FlatList
        data={props.podcasts}
        horizontal={true}
        renderItem={({ item }) => (
          <PodcastCellItem
            item={item}
            itemSize={88}
            fontSize={11}
            itemPadding={6}
          />
        )}
        keyExtractor={(item, index) => "podcast-" + item.id}
        showsHorizontalScrollIndicator={false}
        style={{
          marginBottom: 0,
          paddingHorizontal: 12,
        }}
      />
    );
  };

  const renderSubs = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
        }}
      >
        {props.podcasts.map((item, index) => (
          <ScaledButton
            style={{
              width: "48%",
              height: 48,
              backgroundColor: "#444",
              borderRadius: 4,
              marginHorizontal: 2,
              marginBottom: 8,
              flexDirection: "row",
              alignItems: "center",
              overflow: "hidden",
            }}
            onPress={() => navigation.push("Podcast", { id: item.id })}
          >
            <ImageBox
              width={48}
              aspectRatio={1}
              borderRadius={0}
              source={{ uri: find(item?.media, { type: "cover" })?.thumb_url }}
            />
            <Text
              style={{
                lineHeight: 16,
                marginHorizontal: 8,
                fontFamily: Theme.fonts.titles.medium,
                fontSize: 11,
                flex: 1,
              }}
              numberOfLines={2}
            >
              {item.name}
            </Text>
          </ScaledButton>
        ))}
      </View>
    );
  };

  const onViewLayout = (event) => {
    setLayout({
      ...layout,
      width: event.nativeEvent.layout.width,
      height: event.nativeEvent.layout.height,
    });

    if (props.onHeightChange) {
      props.onHeightChange({
        height: event.nativeEvent.layout.height,
      });
    }
  };

  const [layout, setLayout] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  });

  return (
    <View
      //style={{ ...Platform.select({ native: { marginTop: -headerHeight } }) }}
      onLayout={onViewLayout}
    >
      {/*<View*/}
      {/*  style={{*/}
      {/*    height: headerHeight,*/}
      {/*  }}*/}
      {/*></View>*/}
      {headerImage ? (
        <ImageBox
          source={{ uri: headerImage.url }}
          aspectRatio={headerImage.aspect_ratio}
          borderWidth={0}
          style={{
            borderWidth: 0,
            borderColor: "red",
          }}
        />
      ) : (
        <View style={{ height: 160 }}></View>
      )}
      <View style={{ zIndex: 5 }}>
        {isAdmin && isPlayable && renderSuperPlay()}
        <LinearGradient
          style={{
            height: 100,
            zIndex: 5,
            marginTop: -100,
          }}
          colors={["transparent", Theme.colors.background]}
        />
      </View>
      <View style={{ paddingHorizontal: 20, paddingBottom: 4 }}>
        {renderTitle()}
        {renderActions()}
        {renderLocation()}
        {renderDescription()}
      </View>
      {renderSubsCarousel()}
    </View>
  );
};
