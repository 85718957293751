import initialState from "@app/config/redux/initialState";

export default function (state = initialState.auth, action) {
  switch (action.type) {
    case "navigation_state":
      const tabState = action.state?.routes[action.state.index]?.state;
      const navigation = {
        tab: {
          index: tabState?.index,
          name: tabState?.routes[tabState.index]?.name,
          key: tabState?.routes[tabState.index]?.key,
        },
      };
      return {
        ...state,
        state: navigation, // action.state,
      };
    default:
      return state;
  }
}
