import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { useQuery } from "react-query";
import { instance } from "@app/config/api/api";
import { get } from "lodash";

interface Props {}

export default (props: Props) => {
  const useMyMusicStyles = () => {
    return useQuery(
      "me.music.styles",
      async () => {
        const { data } = await instance.get("me/music/styles");
        const dataItems = get(data, "data");

        //          const headerItem = get(data, "data.header");

        return { items: dataItems };
      },
      { enabled: true }
    );
  };
  const { data, isLoading, refetch, isError } = useMyMusicStyles();

  return <View></View>;
};
