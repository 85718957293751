import * as React from "react";
import {
  ScrollView,
  View,
  RefreshControl,
  FlatList,
  Pressable,
  SectionList,
  Alert,
  Modal,
  Platform,
  Dimensions,
} from "react-native";
import {
  Text,
  Image,
  Theme,
  Button,
  ScaledButton,
  TurboSymbol,
  ImageBox,
  BackgroundGradient,
  Actionable,
  HeaderBar,
  BadgePill,
} from "@app/ui";
import { find } from "lodash";
import { useState } from "react";
import { LinearGradient } from "expo-linear-gradient";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useMutation, useQueryClient } from "react-query";
import { instance } from "@app/config/api/api";
import { useFollowMutation } from "@app/social/hooks/follow";
import { Portal } from "@gorhom/portal";
import { useHeaderHeight } from "@react-navigation/elements";

export default (props) => {
  const coverImage = find(props?.media, { type: "cover" });
  const headerImage = find(props?.media, { type: "hero" });
  const [isFollowing, setIsFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const headerHeight = useHeaderHeight();
  const isPlayable = true;
  const [headerLayout, setHeaderLayout] = useState<string>("full");
  const colorTint = coverImage?.colors?.tertiary || "#20D0A2";
  const navigation = useNavigation();
  const route = useRoute();

  const followMutation = useFollowMutation("podcast", props.id);

  const setIsFollowedDelayed = (value: boolean) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsFollowing(value);
      setIsLoading(false);
    }, 500);
  };

  const renderLocation = () => {
    const styleText = {
      fontSize: 13,
      fontFamily: Theme.fonts.titles.medium,
      lineHeight: 20,
    };
    const styleSeparator = {
      fontFamily: Theme.fonts.titles.medium,

      lineHeight: 20,

      //marginVertical: 4,
    };

    const styles = props.styles;

    return (
      <View style={{ flexDirection: "row", marginVertical: 4 }}>
        <Text style={styleText}>Podcast</Text>
        <Text style={styleSeparator}>・</Text>
        <Text style={styleText}>{props.episodes_count} Episodes</Text>
        <Text style={styleSeparator}>・</Text>
        {styles?.map((style) => (
          <BadgePill
            name={style.name}
            slug={style.slug}
            style={{
              marginTop: 2,
              marginRight: 4,
            }}
            onPress={() =>
              navigation.push("MusicHome", {
                title: name,
                style: slug,
              })
            }
          />
        ))}
      </View>
    );
  };

  const renderDescription = () => {
    return (
      <Text
        style={{
          fontSize: 13,
          opacity: 0.75,
          fontFamily: Theme.fonts.titles.regular,
          marginVertical: 4,
          lineHeight: 16,
        }}
      >
        {}
      </Text>
    );
  };

  const follow = (type: string, id: string) => {};

  const renderActions = () => {
    const buttonStyle = {
      fontSize: 14,
      fontFamily: Theme.fonts.titles.medium,
      flex: 1,
    };

    return (
      <View style={{ flexDirection: "row", marginVertical: 16 }}>
        {isFollowing ? (
          <View style={{ flexDirection: "row", flex: 1 }}>
            <Button
              backgroundColor={"#444444"}
              textColor={"#ffffff"}
              onPress={() => followMutation.mutate("podcast", props.id)}
              containerStyle={{ marginRight: 12, ...buttonStyle }}
              symbol={"heart-line"}
              isLoading={isLoading}
            />
            <Button
              backgroundColor={"#444444"}
              textColor={"#ffffff"}
              onPress={() => setIsFollowedDelayed(true)}
              containerStyle={{ marginRight: 6, ...buttonStyle }}
              symbol={"alert-solid"}
              isLoading={isLoading}
            />
          </View>
        ) : (
          <Button
            text={"Play"}
            backgroundColor={colorTint}
            textColor={"#ffffff"}
            onPress={() =>
              followMutation.mutate({ type: "podcast", id: props.id })
            }
            containerStyle={{ marginRight: 6, ...buttonStyle }}
            symbol={"play-solid"}
            isLoading={isLoading}
          />
        )}
        <Button
          text={"Subscribe"}
          backgroundColor={isFollowing ? colorTint : "#444444"}
          textColor={"#ffffff"}
          onPress={() =>
            setHeaderLayout(headerLayout === "compact" ? "full" : "compact")
          }
          containerStyle={{ marginRight: 6, ...buttonStyle }}
          symbol={"star-line"}
        />
      </View>
    );
  };

  const renderSuperPlay = () => {
    const buttonSize = 52;

    return (
      <View
        style={{
          height: 64,
          position: "absolute",
          zIndex: 10,
          marginTop: -64,
          right: 20,
        }}
      >
        <ScaledButton
          onPress={() => Alert.alert("Play")}
          style={{
            width: buttonSize,
            height: buttonSize,
            borderRadius: buttonSize / 2,
            backgroundColor: colorTint,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TurboSymbol
            name="play-solid"
            color="#fff"
            size={20}
            style={{ marginLeft: 4 }}
          />
        </ScaledButton>
      </View>
    );
  };

  const renderTitle = () => {
    const styles = {
      full: {
        container: {
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          marginVertical: 16,
        },
        cover: {
          width: 256,
          height: 256,
          borderRadius: 14,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 7,
          },
          shadowOpacity: 0.43,
          shadowRadius: 9.51,

          elevation: 15,
        },
        textContainer: { marginVertical: 16, alignItems: "center" },
        title: {
          fontFamily: Theme.fonts.titles.medium,
          fontSize: 22,
        },
        artist: {
          fontFamily: Theme.fonts.titles.medium,
          fontSize: 16,
          color: colorTint,
          marginTop: 8,
        },
      },
      compact: {
        container: {
          flexDirection: "row",
          flex: 1,
          marginVertical: 16,
        },
        cover: {
          width: 120,
          height: 120,
          borderRadius: 4,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 7,
          },
          shadowOpacity: 0.43,
          shadowRadius: 9.51,

          elevation: 15,
        },
        textContainer: {
          padding: 16,
          justifyContent: "center",
          flex: 1,
        },
        title: {
          fontFamily: Theme.fonts.titles.medium,
          fontSize: 22,
          lineHeight: 26,
        },
        artist: {
          marginTop: 8,
          fontFamily: Theme.fonts.titles.medium,
          fontSize: 14,
          color: colorTint,
        },
      },
    };

    const style = styles[headerLayout];
    return (
      <View style={[style.container]}>
        {coverImage ? (
          <ImageBox
            borderRadius={style.cover.borderRadius}
            width={style.cover.width}
            height={style.cover.height}
            aspectRatio={style.cover.aspect_ratio}
            source={{ uri: coverImage.url }}
            borderWidth={0}
            containerStyleX={{
              shadowColor: "#000",
              //marginTop: -2,
              shadowOffset: {
                width: 0,
                height: 8,
              },
              shadowOpacity: 0.15,
              shadowRadius: 4,

              elevation: 9,
            }}
          />
        ) : (
          <View
            style={{
              width: style.cover.width,
              height: style.cover.height,
              backgroundColor: "#444",
              borderRadius: style.cover.borderRadius,
            }}
          />
        )}
        <View style={[style.textContainer]}>
          <Text style={[style.title]}>{props.name}</Text>
          {props.author?.id && (
            <Actionable
              onPress={() =>
                navigation.push("PageScreen", {
                  id: props.author.id,
                  slug: props.author.slug,
                  //title: item.name,
                })
              }
            >
              <Text
                style={[style.artist, { color: coverImage?.colors?.primary }]}
              >
                {props.author?.name || ""}
              </Text>
            </Actionable>
          )}
        </View>
      </View>
    );
  };

  const renderNavigationBar = () => {
    return (
      <HeaderBar
        safeArea={false}
        containerStyle={{
          //opacity: 0.5,
          position: "absolute",
          left: 0,
          right: 0,
          zIndex: 4,
        }}
        backgroundColor={Theme.colors.chrome}
        //scrollSize={200}
        //scrollOffset={scrollOffset}
        scrollPosition={props.context?.scrollPosition}
        blur={true}
      ></HeaderBar>
    );
  };

  const useGradient = true;
  const gradientHeight = headerLayout === "compact" ? 302 : 500;
  const colorG = coverImage?.colors?.secondary || "#444";

  //console.warn("context", props);

  return (
    <View style={{}}>
      {/*<Portal hostName={route.key}>{renderNavigationBar()}</Portal>*/}
      {/*{renderNavigationBar()}*/}
      <BackgroundGradient
        color={colorG}
        height={gradientHeight}
        marginTop={Platform.select({ native: -headerHeight })}
      />
      <View
        style={{
          paddingHorizontal: 20,
          paddingBottom: 4,
          marginTop: headerHeight,
          // ...Platform.select({ android: { paddingTop: 72 } }),
        }}
      >
        {/*{isPlayable && renderSuperPlay()}*/}
        {renderTitle()}
        {renderActions()}
        {renderLocation()}
        {renderDescription()}
      </View>
    </View>
  );
};
