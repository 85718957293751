import initialState from "@app/config/redux/initialState";

export default function (state = initialState.auth, action) {
  switch (action.type) {
    case "logged_in":
      return {
        ...state,
        logged_in: true,
        access_token: action.accessToken,
      };
    case "me_success":
      return {
        ...state,
        user: action.user,
      };
    case "logout":
      return {
        ...state,
        logged_in: false,
        access_token: undefined,
        user: undefined,
      };
    case "navigation_state":
      const tabState = action.state?.routes[action.state.index]?.state;
      const navigation = {
        tab: {
          index: tabState?.index,
          name: tabState?.routes[tabState.index]?.name,
          key: tabState?.routes[tabState.index]?.key,
        },
      };
      //console.warn("navigation_state", action);
      //console.warn("reduced", navigation);

      return {
        ...state,
        navigation, // action.state,
      };
    default:
      return state;
  }
}
