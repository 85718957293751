"use strict";
import React, { Component, ReactNode } from "react";
import {
  View,
  Text,
  Image as NativeImage,
  Pressable,
  Platform,
} from "react-native";
import {
  TouchableOpacity,
  BorderlessButton,
  RectButton,
  BaseButton,
  TouchableHighlight,
} from "react-native-gesture-handler";
import {
  StyleSheet,
  BoxResponsive,
  Theme,
  TurboSymbol,
  ImageBox,
  Actionable,
} from "@app/ui";
import FontAwesome5Pro from "react-native-vector-icons/FontAwesome5Pro";

let ContextMenu;
let PointerInteractionView;

if (Platform.OS !== "web") {
  ContextMenu = require("react-native-context-menu-view").default;
}

if (Platform.OS === "ios") {
  PointerInteractionView = require("@thefunbots/react-native-pointer-interactions")
    .PointerInteractionView;
}

enum CellTypeStyle {
  default = "default",
  compact = "compact",
}

interface Props {
  children?: ReactNode;
  key?: string;
  title?: string;
  subtitle?: string;
  titleLines?: number;
  description?: string;
  accessory?: any;
  accessoryText?: string;
  disclosure?: boolean;
  icon?: number | any;
  iconName?: string;
  image?: string;
  imageBackgroundColor?: string;
  symbol?: string;
  separator?: boolean;
  onPress?: Function;
  isLastItem?: boolean;
  footer?: any;
  footerIndent?: boolean;
  selected?: boolean;
}

interface State {}

class CellItem extends Component<Props, State> {
  static defaultProps: Props = {
    disclosure: false,
    titleLines: 1,
    separator: true,
    isLastItem: false,
    footerIndent: true,
  };

  constructor(props: Props) {
    super(props);
  }

  renderIcon() {
    return (
      <View style={styles.iconContainer}>
        {!this.props.symbol && this.props.icon && (
          <NativeImage
            style={styles.icon}
            resizeMode="contain"
            source={this.props.icon}
          />
        )}
        {!this.props.symbol && this.props.iconName && (
          <FontAwesome5Pro
            name={this.props.iconName}
            light
            size={22}
            color={Theme.colors.textSecondary}
          />
        )}
        {this.props.image && (
          <ImageBox
            source={{ uri: this.props.image }}
            width={36}
            //backgroundColor={this.props.imageBackgroundColor}
            aspectRatio={1}
          />
        )}
        {this.props.symbol && (
          <TurboSymbol
            name={this.props.symbol}
            color={Theme.colors.textSecondary}
            size={22}
          />
        )}
      </View>
    );
  }

  renderSeparator() {
    return Platform.OS === "ios" ? <View style={[styles.separator]} /> : null;
  }

  renderTexts() {
    return (
      <View style={[styles.textsContainer]}>
        {this.renderTitle()}
        {this.renderAccessoryText()}
        {this.renderAccessoryView()}
      </View>
    );
  }

  renderTitle() {
    return (
      <View style={styles.textContainer}>
        <Text numberOfLines={this.props.titleLines} style={styles.title}>
          {this.props.title}
        </Text>
        {this.props.subtitle && (
          <Text numberOfLines={this.props.titleLines} style={styles.subtitle}>
            {this.props.subtitle}
          </Text>
        )}
      </View>
    );
  }

  renderAccessoryText() {
    return this.props.accessoryText ? (
      <Text style={styles.accessoryText} numberOfLines={1}>
        {this.props.accessoryText}
      </Text>
    ) : null;
  }

  renderAccessoryView() {
    return this.props.accessory && this.props.accessory();
  }

  renderDisclosure() {
    return (
      <View style={styles.disclosureContainer}>
        {this.props.disclosure && (
          <FontAwesome5Pro
            name={"chevron-right"}
            light
            size={12}
            color={Theme.colors.textSecondary}
          />
        )}
      </View>
    );
  }

  renderFooter() {
    return this.props.footer ? (
      <View
        style={[styles.footer, this.props.footerIndent && styles.footerIndent]}
      >
        {/*{this.props.footerIndent && this.renderSeparator()}*/}
        {this.props.footer()}
      </View>
    ) : null;
  }

  renderBody() {
    return (
      <View style={[!this.props.onPress && styles.root, {}]}>
        <View style={[styles.container, {}]}>
          {this.renderIcon()}
          {this.renderTexts()}
          {this.renderDisclosure()}
        </View>
        {this.renderFooter()}
        {!this.props.isLastItem && this.renderSeparator()}
      </View>
    );
  }

  render() {
    if (this.props.onPress) {
      if (Platform.OS === "ios") {
        return (
          // <PointerInteractionView style={styles.pressable} pointerMode="lift">
          <Pressable
            key={this.props.key}
            activeOpacity={0.7}
            onPress={this.props.onPress}
            style={({ pressed }) => {
              return {
                backgroundColor: pressed
                  ? Theme.colors.chromeHigh
                  : Theme.colors.chrome,
              };
            }}
          >
            {this.renderBody()}
          </Pressable>
          // </PointerInteractionView>
        );
      }

      return (
        <Pressable
          key={this.props.key}
          activeOpacity={0.7}
          android_ripple={{
            color: Theme.colors.chromeHigh,
          }}
          onPress={this.props.onPress}
          style={[
            styles.pressable,
            {
              backgroundColor: Theme.colors.chrome,
            },
          ]}
        >
          {this.renderBody()}
        </Pressable>
      );
    } else {
      return this.renderBody();
    }
  }
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: Theme.colors.chrome, // "$chromeColor",
  },
  pressable: {
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },
  container: {
    flexDirection: "row",
    //overflow: 'hidden',
    minHeight: 60, // 58
    ...Platform.select({
      ios: {
        minHeight: 52,
      },
    }),
  },
  separator: {
    backgroundColor: Theme.colors.separator, //,
    height: 0,
    marginTop: 1,
    opacity: 0.5,
    ...Platform.select({
      ios: {
        height: 0.5,
        marginTop: 0.5,
        opacity: 0.3,
      },
    }),
  },
  textsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  titleContainer: {
    borderBottomWidth: 0.5,
    borderBottomColor: Theme.colors.separator, //"$lineSeparator",
  },
  textContainer: {
    flex: 1,
    justifyContent: "center",
  },
  title: {
    fontSize: 15,
    textAlign: "left",
    fontFamily: Theme.fonts.titles.regular,
    color: Theme.colors.text, //"$textColor",
    //flex: 1,
    marginRight: 4,
    //letterSpacing: -0.7,
  },
  subtitle: {
    fontSize: 15,
    textAlign: "left",
    fontFamily: Theme.fonts.titles.regular,
    color: Theme.colors.text, //"$textColor",
    //flex: 1,
    marginRight: 4,
    marginTop: 4,
    opacity: 0.5,
    //letterSpacing: -0.7,},
  },
  accessoryText: {
    color: Theme.colors.text, //"$textColor",
    fontSize: 15,
    textAlign: "right",
    fontFamily: Theme.fonts.titles.regular,
    //fontFamily: Theme.font.regular,
    //maxWidth: '30%',
    marginRight: 8,
  },
  iconContainer: {
    width: 70,
    alignItems: "center",
    justifyContent: "center",
  },
  icon: { width: 32, height: 32 },
  disclosureContainer: {
    width: 24,
    marginRight: 12,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 0,
  },
  disclosure: {
    width: 20,
    height: 11,
    tintColor: Theme.colors.separatorHigh, //"$lineSeparatorHigh",
    marginHorizontal: 2,
    marginTop: 2,
    //opacity: 0.3,
  },
  footer: {
    paddingTop: 2,
    marginBottom: 16,
    paddingHorizontal: 16,
  },
  footerIndent: {
    paddingLeft: 70,
  },
});

export default CellItem;
