import * as React from "react";
import {
  ScrollView,
  View,
  RefreshControl,
  FlatList,
  Pressable,
  SectionList,
  Alert,
  Modal,
  Platform,
  Dimensions,
  Animated,
} from "react-native";

import { Component, useEffect, useLayoutEffect, useRef, useState } from "react";
import { getGames } from "@app/games/services/data";
import { concat, find } from "lodash";

import {
  Actionable,
  HeaderButton,
  ImageBox,
  ScaledButton,
  Text,
  Theme,
  TurboSymbol,
  Feed,
  BackButton,
  HeaderBar,
  ExtendedView,
  StatePlaceholder,
} from "@app/ui";
import { Track } from "@app/music/models/track";
import { addTrack } from "@app/music/services/player";
import {
  itemSizeForColumns,
  useContentWidth,
  useIsLargeScreen,
  useNumberOfColumnsForFullScreen,
} from "@app/utils/hooks/screen_dimensions";
import { LinearGradient } from "expo-linear-gradient";
import tinycolor from "tinycolor2";
import { useNavigation, useRoute } from "@react-navigation/native";
import { renderItemByName } from "@app/ui/feed/items";
import { PodcastCellItem } from "@app/music";
import { Portal, PortalProvider, PortalHost } from "@gorhom/portal";
import { useHeaderHeight } from "@react-navigation/elements";

interface Props {
  header?: Component | null;
  navigationHeader?: Component | null;
  navigationHeaderFake?: boolean;
  data: any;
  loadMore?: boolean;
  loading?: boolean;
  error?: any;
  page?: Number;
  refreshControl?: boolean;
  getItems?: Function;
  showSidebar?: boolean;
}

export default (props: Props) => {
  const navigation = useNavigation();
  const route = useRoute();
  const contentWidth = useContentWidth();
  const _deltaY = new Animated.Value(0);

  const data = props.data;

  const headerHeight = useHeaderHeight();
  const [currentPage, setCurrentPage] = useState(1);
  const deviceWidth = useContentWidth();
  const isLargeScreen = useIsLargeScreen();
  const showSidebar = props.showSidebar || isLargeScreen;

  const itemSize = isLargeScreen ? 220 : 180;
  const coverSize = itemSize - 16;
  const coverRadius = isLargeScreen ? 12 : 8;
  const imageBoxBorderWidth = 0.5;
  const rowSize = Math.floor(deviceWidth / itemSize);
  const numberOfColumns = useNumberOfColumnsForFullScreen(itemSize);
  const itemSize2 = itemSizeForColumns(2);
  const itemSize3 = itemSizeForColumns(3);

  enum SectionListType {
    MusicStyle = "music_style",
    MusicPodcast = "music_podcast",
    MusicPodcastChapter = "music_podcast_chapter",
    Page = "page",
  }
  interface SectionListItem {
    type: SectionListType;
    header: string;
    data: Array<any>;
  }

  const styles: SectionListItem = {
    type: SectionListType.MusicStyle,
    header: "Styles",
    data: [
      {
        items: [
          {
            name: "Makina",
            slug: "makina",
            colors: { primary: "#ff512f", secondary: "#dd2476" },
          },
          {
            name: "SynthWave",
            slug: "synthwave",
            colors: { primary: "#c33764", secondary: "#1d2671" },
          },
          {
            name: "Jumper",
            slug: "jumper",

            colors: { primary: "#2b5876", secondary: "#1d2671" },
          },
          {
            name: "Trance",
            slug: "trance",

            colors: { primary: "#c4e0e5", secondary: "#4ca1af" },
          },
          {
            name: "Minimal Techno",
            slug: "minimal-techno",
            colors: { primary: "#ddd6f3", secondary: "#faaca8" },
          },
          {
            name: "Acid House",
            slug: "acid-house",
            colors: { primary: "#eef58b", secondary: "#E7F219" },
          },
          {
            name: "Goa Trance",
            slug: "goa-trance",
            colors: { primary: "#57ff39", secondary: "#ac00b8" },
          },
          {
            name: "DarkWave",
            slug: "darkwave",
            colors: { primary: "#a50c0c", secondary: "#410000" },
          },
        ],
      },
    ],
  };
  const style = props.route?.params?.style;
  let initialItems = style ? [] : [styles];

  const [sectionItems, setSectionItems] = useState<SectionListItem[]>(
    initialItems
  );

  const addSongToQueue = (track: Track, appearance?: Object) => {
    const generatedID = track.id + "_" + Date.now();
    const finalTrack = { ...track };

    return addTrack(finalTrack, appearance, true);
  };

  const renderSectionItemCell = ({ item, section }) => {
    if (!section.data) {
      console.warn("Upps, no section data for " + item.type);
      return (
        <View style={{ backgroundColor: "#000", height: 22, width: 22 }}></View>
      );
    }

    const paddingHorizontal = 8;
    const marginBottom = 8;
    const data = item;

    if (section.type === SectionListType.MusicStyle) {
      return (
        <FlatList
          data={data}
          horizontal={true}
          renderItem={renderStyleItemCompact}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item, index) => "style-" + item.slug}
          style={{
            marginTop: marginBottom,
            marginBottom: marginBottom,
            paddingHorizontal: paddingHorizontal + 8,
          }}
        />
      );
    } else if (section.type === SectionListType.MusicPodcast) {
      return (
        <FlatList
          data={data}
          //numColumns={numberOfColumns}
          horizontal={true}
          // columnWrapperStyle={{
          //   flex: 1,
          //   justifyContent: "flex-start",
          // }}
          renderItem={({ item }) => (
            <PodcastCellItem item={item} navigation={navigation} />
          )}
          keyExtractor={(item, index) => "podcast-" + item.id}
          showsHorizontalScrollIndicator={false}
          ItemSeparatorComponent={() => (
            <View
              style={{
                //height: 4,
                //height: 0.5,
                flex: 1,
                backgroundColor: "rgba(255,255,255,.1)",
                //marginTop: -18,
              }}
            />
          )}
          //ListFooterComponent={renderFooter}
          onEndReachedThreshold={0.4}
          style={{
            marginBottom: marginBottom,
            paddingHorizontal: paddingHorizontal + 4,
          }}
        />
      );
    } else if (
      section.type === SectionListType.MusicPodcastChapter
      //  ||    section.type === "podcast.chapter.items"
    ) {
      return (
        <FlatList
          data={data}
          // numColumns={numberOfColumns}
          // refreshControl={
          //   <RefreshControl
          //     refreshing={loading}
          //     onRefresh={() => getMyItems(1)}
          //   />
          // }
          // columnWrapperStyle={{
          //   flex: 1,
          //   justifyContent: "flex-start",
          // }}
          horizontal={true}
          renderItem={renderPodcastChapterItem}
          keyExtractor={(item, index) => "podcast-chapter-" + item.id}
          showsHorizontalScrollIndicator={false}
          ItemSeparatorComponent={() => (
            <View
              style={{
                //height: 4,
                flex: 1,
                backgroundColor: "rgba(255,255,255,.1)",
                //marginTop: -18,
              }}
            />
          )}
          //ListFooterComponent={renderFooter}
          onEndReachedThreshold={0.4}
          style={{
            marginBottom: marginBottom,
            paddingHorizontal: paddingHorizontal + 4,
          }}
        />
      );
    } else if (section.type === SectionListType.Page) {
      return (
        <FlatList
          data={data}
          //numColumns={numberOfColumns}
          horizontal={true}
          // refreshControl={
          //   <RefreshControl
          //     refreshing={loading}
          //     onRefresh={() => getMyItems(1)}
          //   />
          // }
          // columnWrapperStyle={{
          //   flex: 1,
          //   justifyContent: "flex-start",
          // }}
          showsHorizontalScrollIndicator={false}
          renderItem={renderPage}
          keyExtractor={(item, index) => "page-" + item.slug}
          ItemSeparatorComponent={() => (
            <View
              style={{
                //height: 4,
                flex: 1,
                backgroundColor: "rgba(255,255,255,.1)",
                //marginTop: -18,
              }}
            />
          )}
          //ListFooterComponent={renderFooter}
          onEndReachedThreshold={0.4}
          style={{
            marginBottom: marginBottom,
            paddingHorizontal: paddingHorizontal + 4,
          }}
        />
      );
    }

    const customView = renderItemByName(section.type, {
      ...section,
      data: item,
    });

    return customView || <View></View>;
  };

  const renderStyleItem = ({ item }) => {
    const colors = item.colors || { primary: "#353535", secondary: "#202020" };
    const textColor = tinycolor(colors.secondary).isDark() ? "#fff" : "#000";

    return (
      <ScaledButton
        key={"style-" + item.id}
        onPress={() =>
          navigation.push("Home", {
            title: item.name,
            style: item.slug,
          })
        }
        style={{
          borderWidth: 0,
          borderColor: "rgba(255,255,255,.25)",
          borderRadius: 4,
          height: 120,
          width: 120,
          aspectRatio: 1,
          margin: 4,
          overflow: "hidden",
          boxSizing: "border",
        }}
      >
        <TurboSymbol
          name={"music-style-" + item.slug}
          size={48}
          color={textColor}
          style={{
            opacity: 0.9,
            marginBottom: 10,
            position: "absolute",
            zIndex: 2,
            width: 120,
            aspectRatio: 1,
            textAlign: "center",
            marginTop: 24,
          }}
        />
        <LinearGradient
          style={{
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
            padding: 8,
          }}
          colors={[colors.primary, colors.secondary]}
          //colors={["#000", "#fff"]}
          start={{ x: 0.5, y: 0 }}
          end={{ x: 1, y: 1 }}
        >
          {item.name.split(" ").map((name) => {
            return (
              <Text
                key={name}
                style={{
                  color: textColor,
                  textTransform: "uppercase",
                  fontWeight: "600",
                  opacity: 0.9,
                  fontSize: 11,
                  letterSpacing: 1.5,
                  textAlign: "right",
                }}
              >
                {name}
              </Text>
            );
          })}
        </LinearGradient>
      </ScaledButton>
    );
  };

  const renderStyleItemCompact = ({ item }) => {
    const colors = item.appearance?.colors || {
      primary: "#353535",
      secondary: "#202020",
    };
    const textColor = tinycolor(colors.secondary).isDark()
      ? "#ffffff"
      : "#000000";

    return (
      <ScaledButton
        pointerMode={"lift"}
        key={"style-" + item.id}
        onPress={() =>
          navigation.push("MusicHome", {
            title: item.name,
            style: item.slug,
          })
        }
        style={{
          backgroundColor: "#fff",
          borderWidth: 0,
          //borderColor: "rgba(255,255,255,.25)",
          borderRadius: 5,
          height: 56,
          width: 120,
          //aspectRatio: 1,
          margin: 4,
          overflow: "hidden",
          boxSizing: "border",
          textAlign: "left",
        }}
      >
        <TurboSymbol
          name={"music-style-" + item.slug}
          size={28}
          color={textColor}
          style={{
            opacity: 0.9,
            marginTop: 6,
            marginRight: 0,
            position: "absolute",
            right: 8,
            zIndex: 2,
            //width: 120,
            //aspectRatio: 1,
            textAlign: "right",
          }}
        />
        <LinearGradient
          style={{
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "flex-end",
            padding: 8,
          }}
          colors={[colors.primary, colors.secondary]}
          //colors={["#000", "#fff"]}
          start={{ x: 0.5, y: 0 }}
          end={{ x: 1, y: 1 }}
        >
          {item.name.split(" ").map((name) => {
            return (
              <Text
                key={name}
                style={{
                  color: textColor,
                  textTransform: "uppercase",
                  opacity: 0.9,
                  fontSize: 11,
                  letterSpacing: 1.5,
                  textAlign: "left",
                  fontFamily: Theme.fonts.titles.medium,
                  lineHeight: 16,
                  marginBottom: -5,
                }}
              >
                {name}
              </Text>
            );
          })}
        </LinearGradient>
      </ScaledButton>
    );
  };

  const renderPodcastItemXX = ({ item }) => {
    //console.warn(item);
    const cover = item.media[0];
    const name = item.name || "Podcast";
    const itemSize = itemSize2;
    return (
      <ScaledButton
        key={"podcast-" + item.id}
        containerStyle={{ flex: 1 }}
        style={{
          //display: "flex",
          //flex: 1 / numberOfColumns,
          //flexGrow: 1,
          flexBasis: 100 / numberOfColumns + "%",
          padding: 8,
          marginBottom: 4,
          width: itemSize,
        }}
        onPress={() => {
          navigation.push("Podcast", { id: item.id });
        }}
        actions={[
          { title: "Play Now", systemIcon: "play.circle.fill" },
          { title: "Add to queue", systemIcon: "add-queue" },
          { title: "Add to favorites", systemIcon: "star" },
        ]}
      >
        {cover ? (
          <ImageBox
            source={{ uri: cover?.url }}
            backgroundColor={cover?.colors?.primary}
            borderRadius={coverRadius}
            borderWidth={imageBoxBorderWidth}
          />
        ) : (
          <View
            style={{
              aspectRatio: 1,
              backgroundColor: "#444",
              borderRadius: coverRadius,
            }}
          ></View>
        )}
        <View style={{ marginVertical: 8 }}>
          <Text
            style={{
              fontFamily: Theme.fonts.titles.medium,
              fontSize: 13,
              lineHeight: 16,
            }}
          >
            {item.name}
          </Text>
          {item.artist && (
            <Text
              style={{
                fontFamily: Theme.fonts.titles.regular,
                opacity: 0.7,
                marginTop: 3,
                fontSize: 12,
                lineHeight: 15,
              }}
            >
              {item.artist || "Artist"}
            </Text>
          )}
        </View>
      </ScaledButton>
    );
  };

  const renderPodcastChapterItem = ({ item }) => {
    //console.warn(item);
    const cover = item.media[0];
    const avMedia = item.av_media[0];
    const stream = avMedia?.streams[0];
    const name = item.name || "Podcast";
    const duration = parseInt(avMedia.media?.duration);

    const track = stream
      ? {
          id: stream.uuid,
          uuid: stream.uuid,
          url: stream.content.url,
          artist: item.artist || "Artist",
          album: name,
          title: name,
          artwork: cover?.url,
          duration: duration,
          entity_type: "podcasts_chapters",
          entity_id: item.id,
        }
      : null;

    return (
      <ScaledButton
        pointerMode={"highlight"}
        key={"podcast-chapter-" + item.id}
        containerStyle={{ flex: 1 }}
        style={{
          //display: "flex",
          //flex: 1 / numberOfColumns,
          //flexGrow: 1,
          //padding: 8,
          marginBottom: 3,
          width: itemSize3,
          ...Platform.select({
            native: {
              flexBasis: 100 / numberOfColumns + "%",
              padding: props.itemPadding || 8,
            },
            web: {
              margin: 8,
            },
          }),
        }}
        onPress={() => {
          track
            ? addSongToQueue(track, { colors: cover?.colors })
            : alert("Not available");
        }}
        actions={[
          { title: "Play Now", systemIcon: "play.circle.fill" },
          { title: "Add to queue", systemIcon: "add-queue" },
          { title: "Add to favorites", systemIcon: "star" },
        ]}
      >
        <ImageBox
          source={{ uri: cover?.url }}
          backgroundColor={cover?.colors?.primary}
          borderRadius={coverRadius}
          borderWidth={imageBoxBorderWidth}
        />
        <View style={{ marginVertical: 8 }}>
          <Text
            style={{
              fontFamily: Theme.fonts.titles.medium,
              lineHeight: 16,
              fontSize: 13,
            }}
            numberOfLines={2}
          >
            {item.name}
          </Text>
          {item.artist && (
            <Text
              numb
              style={{
                fontFamily: Theme.fonts.titles.regular,
                opacity: 0.7,
                marginTop: 3,
                fontSize: 12,
                lineHeight: 15,
              }}
              numberOfLines={2}
            >
              {item.artist || "Artist"}
            </Text>
          )}
        </View>
      </ScaledButton>
    );
  };

  const renderPage = ({ item }) => {
    //console.warn(item);
    const cover = find(item.media, { type: "cover" });
    const name = item.name || "Page";
    //const itemWidth = Dimensions.get("window").width;
    const withDescription = false;
    //const itemSize = withDescription ? 180 : 140;
    const roundedAvatars = true;
    const itemSize = itemSize3;
    const roundedSize = roundedAvatars ? itemSize / 2 : coverRadius;
    const borderRadius = withDescription ? 0 : roundedSize;
    const numberOfLines = contentWidth < 422 ? 2 : 4;
    const colorBoxFrom = tinycolor(cover?.colors?.secondary)
      .lighten(24)
      .toString();
    const colorBoxMiddle = tinycolor(colorBoxFrom).lighten(12).toString();
    const colorBoxTo = cover?.colors?.secondary;
    const colorText = tinycolor(cover?.colors?.secondary).isDark()
      ? "#ffffff"
      : "#000000";

    return (
      <ScaledButton
        pointerMode={"highlight"}
        key={"page-" + item.id}
        containerStyle={{ flex: 1 }}
        style={{
          //display: "flex",
          //flex: 1 / numberOfColumns,
          //flexGrow: 1,
          //flexBasis: 100 / numberOfColumns + "%",
          width: itemSize3,
          ...Platform.select({
            native: {
              flexBasis: 100 / numberOfColumns + "%",
              padding: props.itemPadding || 8,
            },
            web: {
              margin: 8,
            },
          }),
          borderRadius: coverRadius,
          overflow: "hidden",
        }}
        onPress={() => {
          navigation.push("PageScreen", {
            id: item.id,
            slug: item.slug,
            //title: item.name,
          });
        }}
      >
        {cover ? (
          <ImageBox
            source={{ uri: cover?.url }}
            backgroundColor={cover?.colors?.primary}
            title={item.name}
            borderWidth={imageBoxBorderWidth}
            borderRadius={borderRadius}
          />
        ) : (
          <View
            style={{
              width: "100%",
              aspectRatio: 1,
              backgroundColor: Theme.colors.card,
              borderRadius,
              ...Platform.select({
                web: {
                  paddingTop: "100%",
                },
              }),
            }}
          ></View>
        )}
        {withDescription ? (
          <LinearGradient
            style={{
              flex: 1,
              padding: 8,
            }}
            colors={[colorBoxFrom, colorBoxMiddle, colorBoxTo]}
            //colors={["#000", "#fff"]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}
          >
            <View
              style={
                {
                  // paddingVertical: 8,
                  // paddingHorizontal: 8,
                  // backgroundColor: cover?.colors?.primary,
                }
              }
            >
              <Text
                style={{
                  fontFamily: Theme.fonts.titles.regular,
                  fontSize: 11,
                  color: colorText,
                }}
                numberOfLines={numberOfLines}
                ellipsizeMode="tail"
              >
                {item.description ||
                  "Description goes here, this is super amazing do you thing no hahaha. Cool always dont wait more haha yes mola"}
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: "row", marginTop: 6 }}>
              <Text
                style={{
                  fontFamily: Theme.font.medium,
                  fontSize: 11,
                  marginRight: 4,
                  color: colorText,
                  opacity: 0.7,
                  textTransform: "capitalize",
                }}
              >
                {item.category}
              </Text>
              <Text
                style={{
                  fontFamily: Theme.fonts.titles.regular,
                  fontSize: 11,
                  color: colorText,
                }}
              >
                Makina
              </Text>
            </View>
          </LinearGradient>
        ) : (
          <View style={{ marginVertical: 8 }}>
            <Text
              style={{
                fontFamily: Theme.fonts.titles.medium,
                fontSize: 13,
                textAlign: roundedAvatars ? "center" : "left",
              }}
            >
              {item.name}
            </Text>
          </View>
        )}
      </ScaledButton>
    );
  };

  const renderHeader = ({ section: { title, subtitle, meta } }) => {
    return (
      <View
        style={{
          paddingVertical: title ? 8 : 0,
          paddingHorizontal: title ? 20 : 0,
          height: title ? "auto" : 0,
          //backgroundColor: "#000000",
        }}
      >
        <Text
          style={{
            color: Theme.colors.text,
            fontSize: 20,
            fontFamily: Theme.fonts.titles.medium,
          }}
        >
          {title}
        </Text>
        {subtitle && (
          <Text
            style={{
              marginTop: 4,
              color: Theme.colors.text,
              opacity: 0.7,
              fontSize: 13,
              fontFamily: Theme.fonts.text.regular,
              marginBottom: 4,
            }}
          >
            {subtitle}
          </Text>
        )}
      </View>
    );
  };

  const keyExtractor = (item, index) => {
    //console.warn("key", item);
    return "item." + item.type + index;
  };

  const contentInsetAdjustmentBehavior = props.navigationHeaderFake
    ? "never"
    : "automatic";

  const contentInset = props.navigationHeaderFake
    ? null //{ top: -headerHeight }
    : null;

  const renderContent = () => {
    return (
      <SectionList
        ListHeaderComponent={showSidebar ? null : props.header}
        sections={data}
        keyExtractor={keyExtractor}
        renderItem={renderSectionItemCell}
        stickySectionHeadersEnabled={false}
        //scrollToOverflowEnabled
        showsVerticalScrollIndicator={__DEV__}
        onScroll={Animated.event([
          { nativeEvent: { contentOffset: { y: _deltaY } } },
        ])}
        scrollEventThrottle={16}
        listEmptyComponent={() => <StatePlaceholder text="No data" />}
        //removeClippedSubviews={true}
        renderSectionHeader={renderHeader}
        refreshControl={
          <RefreshControl
            refreshing={props.loading}
            onRefresh={props.getItems}
          />
        }
        // style={{
        //   padding: 0,
        //   flex: 1,
        // }}
        contentInset={contentInset}
        //contentOffset={{ x: 0, y: 0 }}
        // overScrollMode={"always"}
        // contentInset={{ top: 0 }}
        contentInsetAdjustmentBehavior={contentInsetAdjustmentBehavior}
        automaticallyAdjustContentInsets={false}
        // automaticallyAdjustsScrollIndicatorInsets={false}
        // contentContainerStyle={{
        //   ...Platform.select({
        //     android: { marginTop: headerHeight, paddingBottom: headerHeight },
        //   }),
        // }}
      />
    );
  };

  return (
    <>
      {props.navigationHeaderFake && (
        <HeaderBar
          safeArea={false}
          containerStyle={{
            //opacity: 0.5,
            ...Platform.select({
              native: { position: "absolute", left: 0, right: 0, zIndex: 2 },
            }),
          }}
          backgroundColor={Theme.colors.chrome}
          //scrollSize={200}
          scrollOffset={Platform.select({
            ios: 0,
            android: 0,
          })}
          scrollPosition={_deltaY}
          blur={true}
        />
      )}
      {route.key && <PortalHost name={route.key} />}
      <ExtendedView sideComponent={props.header}>
        {renderContent()}
      </ExtendedView>
    </>
  );
};
