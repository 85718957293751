import NewsStoriesListScreen from "./components/news";
import NewsStoriesDetailScreen from "./components/news_story";
import { TurboProvider, TurboScreen } from "@app/core/providers/ModuleProvider";

const Screens: TurboScreen[] = [
  //News
  { name: "NewsList", component: NewsStoriesListScreen, path: "news" },
  {
    name: "NewsStory",
    component: NewsStoriesDetailScreen,
    options: { title: "" },
    path: "news/story/:id",
  },
];

const NewsProvider: TurboProvider = {
  screens: {
    screens: Screens,
  },
  key: "news",
};

export { NewsProvider, NewsStoriesListScreen, NewsStoriesDetailScreen };
