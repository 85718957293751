import * as React from "react";
import { Text, View, StyleSheet, Pressable, Platform } from "react-native";
import { Theme, Image, TurboSymbol } from "@app/ui";
import FontAwesome5Pro from "react-native-vector-icons/FontAwesome5Pro";
import { isWeb } from "@app/config";
import { PointerInteractionView } from "@thefunbots/react-native-pointer-interactions";

interface Props {
  text: string;
  selected: boolean;
  badge?: string | number;
  iconName?: string;
  symbol?: string;
  //icon?: any;
  onPress?: any;
  compact?: boolean;
}

const buttonSize = isWeb ? 44 : 44;
const iconSize = isWeb ? 24 : 24;
const borderRadius = isWeb ? 5 : 8;

export default ({
  text,
  selected,
  badge,
  iconName,
  symbol,
  onPress,
  compact,
}: Props) => {
  return (
    <PointerInteractionView pointerMode="automatic">
      <Pressable
        onPress={onPress}
        // underlayColor="#fff"
        style={[styles.button]}
      >
        <View
          style={[
            styles.buttonContainer,
            selected ? styles.buttonContainerSelected : undefined,
          ]}
        >
          <View style={[styles.buttonIconContainer]}>
            {/*{icon && (*/}
            {/*  <Image*/}
            {/*    source={icon}*/}
            {/*    style={[*/}
            {/*      styles.buttonIconImage,*/}
            {/*      selected ? styles.buttonIconImageSelected : undefined,*/}
            {/*    ]}*/}
            {/*    resizeMode="contain"*/}
            {/*  />*/}
            {/*)}*/}
            {!symbol &&
              iconName &&
              (selected ? (
                <FontAwesome5Pro
                  name={iconName}
                  solid
                  size={iconSize}
                  color={Theme.colors.text}
                />
              ) : (
                <FontAwesome5Pro
                  name={iconName}
                  light
                  size={iconSize}
                  color={Theme.colors.text}
                />
              ))}
            {symbol && (
              <TurboSymbol
                name={symbol}
                size={iconSize}
                color={selected ? Theme.colors.text : Theme.colors.disabled}
              />
            )}
          </View>
          {!compact && (
            <Text
              style={[
                styles.buttonText,
                selected ? styles.buttonTextSelected : undefined,
              ]}
            >
              {text}
            </Text>
          )}
          {badge && (
            <View
              style={[
                styles.badgeContainer,
                compact ? styles.badgeContainerCompact : undefined,
              ]}
            >
              <Text style={[styles.badgeText]}>{badge}</Text>
            </View>
          )}
        </View>
      </Pressable>
    </PointerInteractionView>
  );
};

const styles = StyleSheet.create({
  button: { borderRadius: borderRadius, marginVertical: 2 },
  buttonContainer: {
    height: buttonSize,
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 4,
    borderRadius: borderRadius,
    ...Platform.select({
      web: {
        cursor: "pointer",
        paddingHorizontal: 4,
      },
    }),
  },

  buttonContainerSelected: {
    //backgroundColor: Theme.colors.separatorHigh + "30",
    //backgroundColor: Theme.colors.primary,
  },

  buttonIconContainer: {
    width: buttonSize,
    height: buttonSize,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 4,
  },

  buttonIconImage: {
    opacity: 0.5,
    tintColor: Theme.colors.text,
    width: iconSize,
    height: iconSize,
  },

  buttonIconImageSelected: {
    opacity: 1,
    tintColor: Theme.colors.text,
  },

  buttonText: {
    opacity: 0.5,
    color: Theme.colors.text,
    fontSize: 14,
    lineHeight: buttonSize + 1,
    fontFamily: Theme.fonts.titles.medium,
    flexGrow: 1,
    ...Platform.select({
      web: {
        fontSize: 14,
      },
    }),
    //letterSpacing: 0.5,
  },

  buttonTextSelected: {
    opacity: 1,
    color: Theme.colors.text,
    fontFamily: Theme.fonts.titles.medium,
  },
  badgeContainer: {
    position: "absolute",
    right: 14,
    top: 12,
    zIndex: 2,
    justifyContent: "center",
  },
  badgeContainerCompact: {
    right: 2,
    top: 4,
  },
  badgeText: {
    backgroundColor: Theme.colors.primary,
    color: Theme.colors.text,
    borderRadius: 10,
    overflow: "hidden",
    fontSize: 12,
    height: 20,
    minWidth: 20,
    textAlign: "center",
    lineHeight: 20,
    fontFamily: Theme.font.medium,
  },
});
