import ChatConversationsListScreen from "@app/chat/screens/conversations-screen";
import ChatConversationScreen from "@app/chat/screens/conversation";
import ChatConversations from "./components/conversations-list";
import { TurboProvider, TurboScreen } from "@app/core/providers/ModuleProvider";

const Screens: TurboScreen[] = [
  //Chat
  {
    name: "ChatConversationsList",
    component: ChatConversationsListScreen,
    options: { title: "Chats" },
    path: "chats",
  },
  {
    name: "ChatConversation",
    component: ChatConversationScreen,
    options: { title: "Conversation" },
    path: "chat/:id",
    modal: true,
  },
];

const ChatProvider: TurboProvider = {
  screens: { screens: Screens },
  key: "debug",
};

export {
  ChatConversationsListScreen,
  ChatConversationScreen,
  ChatConversations,
  ChatProvider,
};
