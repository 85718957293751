import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Pressable,
  Platform,
  View,
  ImageSourcePropType,
  ImageStyle,
  NativeEventEmitter,
  StyleSheet,
  ViewStyle,
  StyleProp,
  Text,
  Animated,
  Dimensions,
  ScrollView,
} from "react-native";
import {
  BackButton,
  Feed,
  FeedApi,
  HeaderButton,
  HeaderButtonGroup,
  Spinner,
  Theme,
} from "@app/ui";
import { ApiData, withApi } from "@app/config";
import { HScrollView } from "react-native-head-tab-view";
import { SceneMap, TabBar } from "react-native-tab-view";
import { CollapsibleHeaderTabView } from "react-native-tab-view-collapsible-header";
import { renderItemByName } from "@app/ui/feed/items";
import { instance, instance as api } from "@app/config/api/api";
import { concat, get } from "lodash";
import { useHasAppRole } from "@app/user/hooks/user";
import { useQuery, useQueryClient } from "react-query";
import { Helmet } from "react-helmet";

const Page = (props) => {
  const path = "/podcast/" + props.route?.params?.id;
  const canEdit = useHasAppRole("admin");
  const [headerComponent, setHeaderComponent] = useState(undefined);

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route?.params?.title || "",
      headerLeft: () => (
        <HeaderButtonGroup>
          <BackButton navigation={props.navigation} />
        </HeaderButtonGroup>
      ),
      headerRight: () => (
        <HeaderButtonGroup>
          {canEdit && (
            <BackButton
              symbol={"dots-solid"}
              onPress={() => {
                props.navigation.push("PodcastEditor", {
                  id: props.route.params.id,
                });
              }}
            />
          )}
        </HeaderButtonGroup>
      ),
    });
  }, [props.navigation]);

  // const usePodcast = (id, path) => {
  //   return useQuery(
  //     ["podcast", id],
  //     async () => {
  //       const { data } = await instance.get(path);
  //
  //       return data.data;
  //     },
  //     { enabled: true }
  //   );
  // };
  //
  // const {
  //   status,
  //   data,
  //   isError,
  //   isLoading,
  //   isFetching,
  //   refetch,
  //   error,
  // } = usePodcast(props.route?.params?.id, path);
  //
  // if (isLoading) {
  //   return (
  //     <View style={{ flex: 1 }}>
  //       <Spinner />
  //     </View>
  //   );
  // }

  // return (
  //   <Feed
  //     data={data.sections}
  //     error={isError}
  //     loading={isLoading}
  //     refreshControl={true}
  //     getItems={refetch}
  //   />
  // );

  return (
    <>
      <Helmet>
        <title>Podcast Title</title>
        {/*<link rel="canonical" href="http://mysite.com/example" />*/}
      </Helmet>
      <FeedApi
        queryKey={["podcast", props.route?.params?.id]}
        navigation={props.navigation}
        path={path}
        responsePath={"data.sections"}
        header={"data.header"}
        navigationHeaderFake={true}
      />
    </>
  );
};

export default Page;
