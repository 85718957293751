import * as React from "react";

import {
  Platform,
  View,
  TouchableOpacity,
  Pressable,
  FlatList,
  Image,
  Dimensions,
} from "react-native";
import { Theme, Text, ScaledButton, ImageBox } from "@app/ui";
import { useNavigation } from "@react-navigation/native";

export default (props) => {
  const navigation = props.navigation || useNavigation();
  const renderItem = ({ item }) => {
    return (
      <ScaledButton
        key={"live-item-" + item.id}
        style={{ marginHorizontal: 4 }}
        onPress={() =>
          navigation.push("StreamPlayer", {
            title: item.name,
            playback_id: item.playback_id,
          })
        }
      >
        <View style={{ width: 120 }}>
          <Image
            width={120}
            source={{
              uri:
                "https://image.mux.com/" +
                item.playback_id +
                "/thumbnail.jpg?" +
                Math.random(),
            }}
            style={{
              aspectRatio: 1.5,
              backgroundColor: "#222",
              borderRadius: 4,
            }}
            backgroundColor={"#222"}
            borderRadius={4}
          />
        </View>
      </ScaledButton>
    );
  };

  return (
    <FlatList
      data={props.data}
      showsHorizontalScrollIndicator={false}
      horizontal={true}
      renderItem={renderItem}
      keyExtractor={(item, index) => "live-" + item.id}
      contentContainerStyle={{ paddingHorizontal: 16 }}
      style={{ marginBottom: 12 }}
      //style={{ flex: 1 }}
    />
  );
};
