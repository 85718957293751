import * as React from "react";
import {
  Platform,
  View,
  Text,
  TouchableOpacity,
  Pressable,
  Dimensions,
} from "react-native";
import { Navigation, TurboApp } from "@app/core";
import { BackButton, ScaledButton, Theme, TurboSymbol } from "@app/ui";
import { each } from "lodash";
import { enableScreens } from "react-native-screens";
enableScreens(true);

import { HomeScreen, NotificationsListScreen } from "@app/activity";
import { NewsStoriesDetailScreen, NewsStoriesListScreen } from "@app/news";
import {
  GamesListScreen,
  GameDetailScreen,
  GamePlatformsListScreen,
  GamesCollectionsListScreen,
  GamesCollectionsShow,
} from "@app/games";
import {
  UserProfileScreen,
  UserPublicScreen,
  UserSettingsScreen,
} from "@app/user";
import { PlayerScreenCompat, Screens as MusicScreens } from "@app/music";
import { UploadScreen } from "@app/publisher";
import { ChatConversationScreen, ChatConversationsListScreen } from "@app/chat";
import { BroadcastScreen, StreamPlayerScreen } from "@app/stream";
import { Screens as PagesScreens } from "@app/pages";

import createStack from "./createStack";
import { HeaderWeb, isElectron, isWeb } from "@app/config";
import WebViewScreen from "@app/utils/components/webview";

import CameraCodeScannerScreen from "@app/games/screens/camera";
import { NativeStackNavigationOptions } from "@react-navigation/native-stack";

const allScreens = [
  {
    name: "Home",
    component: HomeScreen,
    options: {
      ...Navigation.screens.options.headerTransparentOnScrollNative,
    },
  },
  { name: "NotificationsList", component: NotificationsListScreen },

  // Users
  {
    name: "UserProfile",
    component: UserProfileScreen,
    options: { title: "" },
  },
  {
    name: "UserSettings",
    component: UserSettingsScreen,
    options: { title: "Settings" },
  },

  // Utils
  { name: "WebView", component: WebViewScreen, options: { title: "" } },
  {
    name: "CameraCodeScanner",
    component: CameraCodeScannerScreen,
    options: { title: "", headerShown: false },
  },
  {
    name: "StreamBroadcast",
    component: BroadcastScreen,
    options: {
      title: "",
      headerShown: false,
      stackPresentation: "formSheet",
      animationEnabled: true,
    },
  },
  {
    name: "StreamPlayer",
    component: StreamPlayerScreen,
    options: {
      title: "",
      headerShown: false,
      stackPresentation: "fullScreenModal",
      animationEnabled: true,
    },
  },
];

if (isWeb) {
  const iconFonts = {
    turbosymbols: require("@assets/fonts/TurboSymbols.ttf"),
    FontAwesome5_Pro_Solid: require("@assets/fonts/FontAwesome5_Pro_Solid.ttf"),
    FontAwesome5_Pro_Light: require("@assets/fonts/FontAwesome5_Pro_Light.ttf"),
    "Rubik-Light": require("@assets/fonts/Rubik-Light.ttf"),
    "Rubik-Regular": require("@assets/fonts/Rubik-Regular.ttf"),
    "Rubik-Medium": require("@assets/fonts/Rubik-Medium.ttf"),

    "Gilroy-Medium": require("@assets/fonts/Gilroy-Medium.ttf"),
    "Gilroy-Bold": require("@assets/fonts/Gilroy-Bold.ttf"),
    "Gilroy-SemiBold": require("@assets/fonts/Gilroy-SemiBold.ttf"),
    "Gilroy-Regular": require("@assets/fonts/Gilroy-Regular.ttf"),

    "Inter-Medium": require("@assets/fonts/Inter-Medium.ttf"),
    "Inter-Bold": require("@assets/fonts/Inter-Bold.ttf"),
    "Inter-SemiBold": require("@assets/fonts/Inter-SemiBold.ttf"),
    "Inter-Regular": require("@assets/fonts/Inter-Regular.ttf"),

    // "EuclidSquare-Light": require("@assets/fonts/EuclidSquare-Light.ttf"),
    // "EuclidSquare-Regular": require("@assets/fonts/EuclidSquare-Regular.ttf"),
    // "EuclidSquare-Medium": require("@assets/fonts/EuclidSquare-Medium.ttf"),
  };

  each(iconFonts, (value, key) => {
    const fontSrc = isElectron ? value.default : value;
    // Generate required css
    const iconFontStyles = `@font-face {
  src: url(${fontSrc});
  font-family: ${key};
}`;

    // Create stylesheet
    const style = document.createElement("style");
    style.type = "text/css";
    if (style.styleSheet) {
      style.styleSheet.cssText = iconFontStyles;
    } else {
      style.appendChild(document.createTextNode(iconFontStyles));
    }

    // Inject stylesheet
    document.head.appendChild(style);
  });
}

export const defaultScreenOptions: NativeStackNavigationOptions = {
  fullScreenGestureEnabled: true,
  transitionDuration: 200,
  //headerStyle: { backgroundColor: Theme.colors.background },
  headerTitleStyle: {
    fontFamily: Theme.fonts.titles.medium,
    //fontSize: 17,bac
  },
  headerBackTitleStyle: {
    fontFamily: Theme.fonts.titles.medium,
    //fontSize: 17,
  },
  headerLargeTitle: false,
  headerLargeTitleStyle: {
    fontFamily: Theme.fonts.titles.medium,
  },
  headerShadowVisible: false,
  headerTransparent: false,
  blurEffect: true,
  translucent: true,
  ...Platform.select({
    web: {
      //headerMode: "screen",
      //header: HeaderWeb,
      headerTitleAlign: "center",
      headerStyle: {
        WebkitUserSelect: "none",
        WebkitAppRegion: "drag",
        //backgroundColor: "rgba(0,0,0,1)",
      },
      headerTitleStyle: {
        WebkitUserSelect: "none",
        WebkitAppRegion: "drag",
        fontFamily: Theme.fonts.titles.medium,
      },
    },
    native: {
      headerBackImageSource: require("@assets/icons/icon_back.png"),
    },
  }),
  backButtonInCustomView: false, // () => <BackButton />,
  headerBackTitleVisible: false,
  // headerLeft: ({ canGoBack, onPress }) => {
  //   console.warn("back", canGoBack);
  // },
  headerBackImage: () => <BackButton />,
  //animationEnabled: true,
  animationEnabled: true,
};

const createStackWithScreens = (
  name: string,
  initialRouteName: string,
  mode: string,
  screens: Array<any>,
  path: string = ""
) => {
  const MyStack = createStack();

  return (
    <MyStack.Navigator
      mode={mode}
      initialRouteName={initialRouteName}
      screenOptions={defaultScreenOptions}
      path={path}
    >
      {screens.map((screen) => {
        return (
          <MyStack.Screen
            key={screen.name}
            name={screen.name}
            component={screen.component}
            options={screen.options}
          />
        );
      })}
    </MyStack.Navigator>
  );
};

export const createAllScreens = (MyStack, ExtraScreens = []) => {
  const screens = [...allScreens, ...ExtraScreens];
  return screens.map((screen) => {
    return (
      <MyStack.Screen
        key={screen.name}
        name={screen.name}
        component={screen.component}
        options={screen.options}
      />
    );
  });
};

export const createStackWithAllScreens = (
  name: string,
  initialRouteName: string,
  mode: string = "card",
  path: string = ""
) => {
  const screens = [...allScreens, ...global.turboApp.tabScreens()];
  return createStackWithScreens(name, initialRouteName, mode, screens, path);
};

/** Home Stack */
export function HomeStackScreen({ navigation }) {
  return createStackWithAllScreens("Home", "Home");
}

/** Activity Stack */
export function ActivityStackScreen({ navigation }) {
  return createStackWithAllScreens("Activity", "ChatConversationsList");
}

/** News Stack */
export function NewsStackScreen({ navigation }) {
  return createStackWithAllScreens("News", "NewsList");
}

/** Games Stack */
export function GamesStackScreen({ navigation }) {
  return createStackWithAllScreens("Games", "GamePlatformList");
}

/** Collection Stack */
export function CollectionsStackScreen({ navigation }) {
  return createStackWithAllScreens("Collections", "GamesCollectionsList");
}

/** Music Stack */
export function MusicStackScreen({ navigation }) {
  return createStackWithAllScreens("Music", "MusicHome");
}

/** Manage Stack */
export function ManageStackScreen({ navigation }) {
  return createStackWithAllScreens("Manage", "PublishUpload", "card", "manage");
}

/** Users Stack */
export function UsersStackScreen({ navigation }) {
  return createStackWithAllScreens("Users", "UserProfile");
}

/** Chat Stack */
export function ChatStackScreen({ navigation }) {
  return createStackWithAllScreens("Chat", "ChatConversation");
}

/** Stream Stack */
export function StreamStackScreen({ navigation }) {
  return createStackWithAllScreens("Stream", "StreamBroadcast");
}
