import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { Button, ImageBox, Text, Theme } from "@app/ui";
import { useAuth } from "@app/user/hooks/user";
import { useNavigation } from "@react-navigation/native";

interface Props {}

export default (props: Props) => {
  const navigation = props.navigation || useNavigation();
  return (
    <View
      style={{
        backgroundColor: Theme.colors.primary,
        marginHorizontal: 20,
        //marginVertical: 8,
      }}
    >
      <Button
        onPress={() =>
          navigation.push("PodcastEditor", {
            id: 4,
          })
        }
        text={"Edit podcast"}
      ></Button>
    </View>
  );
};
