import React, { useEffect, useState } from "react";
import { RectButton, TouchableHighlight } from "react-native-gesture-handler";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import {
  Alert,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { ImageBox, Theme, TurboSymbol } from "@app/ui";
import MusicProgressLine from "@app/music/components/progress_line";
import { useIsLargeScreen } from "@app/utils/hooks/screen_dimensions";
import * as playerActions from "@app/music/actions/player.action";
import { useDispatch } from "react-redux";
import { usePlayerStatus } from "@app/user/hooks/user";
import { Track } from "@app/music/models/track";

interface Props {
  navigation?: any;
  modalRef?: any;
}

const MusicMiniPlayer = ({ navigation, modalRef }: Props) => {
  const dispatch = useDispatch();

  const isLargeScreen = useIsLargeScreen();
  const radius = isLargeScreen ? 0 : 8;
  const [height, setHeight] = useState<number>(0);
  const playerStatus = usePlayerStatus();

  const backgroundColor = useSharedValue(
    playerStatus.appearance.colors.primary
  );
  const backgroundAnimatedStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: withTiming(backgroundColor.value, { duration: 300 }),
    };
  });

  useEffect(() => {
    backgroundColor.value = playerStatus.appearance.colors.primary + "FF";
  }, [playerStatus.appearance]);

  useEffect(() => {
    const height = playerStatus.playback.currentTrack ? 64 : 64;
    setHeight(height);
  }, [playerStatus.playback.currentTrack]);

  const renderSongTitles = (song?: Track) => {
    const artworkSize = 44;
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          paddingHorizontal: 2,
          alignItems: "center",
        }}
      >
        <View style={{ marginLeft: 4, marginRight: 16 }}>
          <ImageBox
            width={artworkSize}
            aspectRatio={1}
            source={{ uri: song?.artwork }}
            borderRadius={3}
            borderWidth={0}
            containerStyle={{
              shadowColor: "#000",
              marginTop: -2,
              shadowOffset: {
                width: 0,
                height: 4,
              },
              shadowOpacity: 0.2,
              shadowRadius: 4,

              elevation: 9,
            }}
            //borderWidth={0}
          />
        </View>
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Text
            style={[
              styles.songTitleSmall,
              { color: playerStatus.appearance.colors.text },
            ]}
            numberOfLines={1}
          >
            {song?.title}
          </Text>
          <Text
            style={[
              styles.songArtistSmall,
              { color: playerStatus.appearance.colors.text },
            ]}
            numberOfLines={1}
          >
            {song?.artist}
          </Text>
        </View>
      </View>
    );
  };

  const renderContent = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          height: height,
          justifyContent: "center",
          overflow: height ? "visible" : "hidden",
        }}
      >
        <View style={styles.songContainerSmall}>
          {renderSongTitles(playerStatus.playback.currentTrack)}
        </View>
        <View
          style={{
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <TouchableHighlight
            onPress={() =>
              playerStatus.playback.isPlaying
                ? TrackPlayer.pause()
                : TrackPlayer.play()
            }
            style={[
              styles.headerActionButtonCircle,
              { backgroundColor: playerStatus.appearance?.colors?.tertiary },
            ]}
            activeOpacity={0.6}
            underlayColor={"rgba(255,255,255,.3)"}
          >
            {/*<FontAwesome5Pro*/}
            {/*  name={isPlaying ? "pause" : "play"}*/}
            {/*  size={16}*/}
            {/*  color={Theme.colors.disabled}*/}
            {/*  solid*/}
            {/*  style={isPlaying ? {} : { marginLeft: 3 }}*/}
            {/*/>*/}
            <TurboSymbol
              name={
                playerStatus.playback.isPlaying ? "pause-solid" : "play-solid"
              }
              color={playerStatus.appearance?.text?.primary}
              size={16}
              style={
                playerStatus.playback.isPlaying
                  ? { marginLeft: 1 }
                  : { marginLeft: 3 }
              }
            />
          </TouchableHighlight>
          {/*{!isPlaying && (*/}
          {/*  <RectButton*/}
          {/*    onPress={() => TrackPlayer.play()}*/}
          {/*    style={styles.headerActionButton}*/}
          {/*  >*/}
          {/*    <FontAwesome5Pro*/}
          {/*      name="play-circle"*/}
          {/*      size={46}*/}
          {/*      color={Theme.colors.text}*/}
          {/*      solid*/}
          {/*    />*/}
          {/*  </RectButton>*/}
          {/*)}*/}
          {/*{isPlaying && (*/}
          {/*  <RectButton*/}
          {/*    onPress={() => TrackPlayer.pause()}*/}
          {/*    style={styles.headerActionButton}*/}
          {/*    underlayColor={Theme.colors.text}*/}
          {/*  >*/}
          {/*    <FontAwesome5Pro*/}
          {/*      name="pause-circle"*/}
          {/*      size={46}*/}
          {/*      color={Theme.colors.text}*/}
          {/*      solid*/}
          {/*    />*/}
          {/*  </RectButton>*/}
          {/*)}*/}
          <RectButton
            onPress={async () => {
              const position = await TrackPlayer.getPosition();
              await TrackPlayer.seekTo(position + 30);
            }}
            style={[
              styles.headerActionButton,
              { marginRight: 4, borderRadius: 24 },
            ]}
            //enabled={nextSong !== undefined}
            underlayColor={Theme.colors.text}
          >
            <TurboSymbol
              name={"forward2-solid"}
              color={playerStatus.appearance?.text?.primary}
              size={18}
              style={{}}
              scaleSize={0.9}
            />
          </RectButton>
        </View>
      </View>
    );
  };

  return (
    <Animated.View
      style={[
        styles.headerContainer,
        backgroundAnimatedStyle,
        {
          height: height,
          borderTopLeftRadius: radius,
          borderTopRightRadius: radius,
        },
      ]}
    >
      <RectButton
        onPress={() => {
          //navigation.navigate("MusicPlayer", {});
          modalRef.current?.open();
        }}
        rippleColor={"#444444"}
        underlayColor={"#ccc"}
        style={[
          styles.headerContainerButton,
          //{ borderTopLeftRadius: radius, borderTopRightRadius: radius },
        ]}
      >
        {/*<View style={styles.headerTopBorder} />*/}
        <SafeAreaView>{renderContent()}</SafeAreaView>
      </RectButton>
      <MusicProgressLine
        opacity={1}
        color={playerStatus.appearance.colors.secondary}
        style={{
          marginTop: isLargeScreen ? 1 : height - 1,
          position: "absolute",
        }}
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  // Header
  headerContainer: {
    //backgroundColor: Theme.colors.chrome,
    borderBottomWidth: 0,
    borderBottomColor: Theme.colors.separator,
    // shadowColor: "#000000", //Theme.colors.background,
    // shadowOffset: {
    //   width: 0,
    //   height: -2,
    // },
    // shadowOpacity: 0.6,
    // shadowRadius: 16,

    //    elevation: -7,
    overflow: "visible",
  },

  headerContainerButton: {},

  headerBackground: {
    ...StyleSheet.absoluteFillObject,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    overflow: "hidden",
    backgroundColor: Theme.colors.card,
    //marginBottom: 10,
  },

  headerContentContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Theme.colors.card,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    padding: 8,
    //    paddingLeft: 20 + 300 + 20,
  },

  headerTopBorder: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    opacity: 0,
    height: 0.25,
    backgroundColor: Theme.colors.border,
  },

  headerActionButtonCircle: {
    height: 48,
    width: 48,
    backgroundColor: "#fff",
    borderRadius: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerActionButton: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: 50,
    minWidth: 50,
  },

  // Handler
  handlerContainer: {
    position: "absolute",
    alignSelf: "center",
    top: 10,
    height: 20,
    width: 20,
  },

  handlerBar: {
    position: "absolute",
    backgroundColor: "#D1D1D6",
    top: 5,
    borderRadius: 3,
    height: 5,
    width: 20,
  },

  // Song
  songCoverContainer: {
    position: "absolute",
    top: 10,
    left: -20,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: 0.25,
    shadowRadius: 15.0,
  },

  songTitleLarge: {
    marginTop: 10,
    textAlign: "center",
    color: Theme.colors.text,
    fontWeight: "bold",
    fontSize: 30,
    lineHeight: 30,
  },
  songContainerSmall: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    padding: 6,
    //marginTop: 10,
  },
  songTitleSmall: {
    color: Theme.colors.text,
    fontFamily: Theme.fonts.titles.medium,
    fontSize: 14,
    lineHeight: 16,
    marginTop: 2,
  },
  songArtistSmall: {
    color: Theme.colors.text,
    opacity: 0.5,
    fontFamily: Theme.fonts.titles.regular,
    fontSize: 14,
    lineHeight: 16,
    marginTop: 3,
  },
  songInfoText: {
    textAlign: "center",
    color: "#FE2D55",
    fontSize: 24,
    fontFamily: Theme.font.medium,
    lineHeight: 28,
  },

  songCoverImage: {
    width: "100%",
    height: "100%",
    borderRadius: 2,
    backgroundColor: "#333",
  },

  // Song List Item
  songListItemContainer: {
    flexDirection: "row",
  },
});

export default MusicMiniPlayer;
