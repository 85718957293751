import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Platform, View } from "react-native";
import { Text } from "@app/ui";

interface Props {
  children: React.ReactNode;
}

export default ({ ...props }: Props) => {
  return (
    <View
      style={{
        flexDirection: "row",
        ...Platform.select({
          web: {
            marginHorizontal: 10,
          },
        }),
      }}
    >
      {props.children}
    </View>
  );
};
