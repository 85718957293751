import { Navigation } from "@app/core";

import PageScreen from "./screens/page";
import PageCreateScreen from "./screens/create";
import PageHeader from "./components/header";
import PagePost from "./components/post";
import WelcomeHeader from "./components/welcome_header";
import DebugShortcuts from "./components/debug_shortcuts";
import PageEditorScreen from "./screens/page_editor";
import PageDetailsEditorScreen from "./screens/page_details_editor";

import PagesMine from "./screens/my_pages";
import { TurboProvider } from "@app/core/providers/ModuleProvider";

const Screens = [
  {
    name: "PageCreate",
    component: PageCreateScreen,
    options: {
      title: "Create Page",
      presentation: "modal",
    },
    path: "pages/create",
  },
  {
    name: "PageScreen",
    component: PageScreen,
    options: {
      ...Navigation.screens.options.headerTransparentOnScroll,
    },
    path: "page/:slug",
  },
  {
    name: "PageEditor",
    component: PageEditorScreen,
    path: "page/:slug/edit",
  },
  {
    name: "PageDetailsEditor",
    component: PageDetailsEditorScreen,
  },
  {
    name: "PagesMine",
    component: PagesMine,
    options: {
      title: "My Pages",
    },
    path: "me/pages",
  },
];

const PagesProvider: TurboProvider = {
  screens: {
    screens: Screens,
  },
  key: "pages",
};

export {
  Screens,
  PageHeader,
  PagePost,
  WelcomeHeader,
  DebugShortcuts,
  PagesProvider,
};
