import * as React from "react";
import { useEffect, useState } from "react";
import { Alert, Platform, StatusBar, View } from "react-native";
import { Provider } from "react-redux";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";

import { enableScreens } from "react-native-screens";
import store from "@app/config/redux/store";
import { Environment } from "@app/config";

import { setupFirebase } from "@app/config/firebase/firebase";
import { MasterLayout, Splash } from "@app/layout";
import { useFonts } from "expo-font";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createAsyncStoragePersistor } from "react-query/createAsyncStoragePersistor-experimental";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as SystemUI from "expo-system-ui";

// import codePush from "react-native-code-push";
// import CodePush, { CodePushOptions } from "react-native-code-push";
//import * as Sentry from "sentry-expo";
import { configure as configureApi } from "@app/config/api/api";
import AuthManager from "@app/auth/services/auth.service";
//import { configureEcho } from "@app/config/socket/socket";
import { Providers as UIProviders, Theme } from "@app/ui";
import { AppContextProvider } from "@app/config/context/AppContext";
import { NativeBaseProvider, Box } from "native-base";
import ExtendedTheme from "@app/ui/components/theme/ExtendedTheme";
import { PortalProvider } from "@gorhom/portal";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { DebugProvider } from "@app/debug/playground";
import { TurboApp } from "@app/core";
import { MusicProvider } from "@app/music";
import { PagesProvider } from "@app/pages";
import { ChatProvider } from "@app/chat";
import { NewsProvider } from "@app/news";
import { GamesProvider } from "@app/games";

// let codePushOptions: CodePushOptions = {
//   checkFrequency: codePush.CheckFrequency.ON_APP_RESUME,
//   installMode: codePush.InstallMode.IMMEDIATE,
// };

// Sentry.init({
//   dsn:
//     "https://44deb23abf534de786d0a9eef17fdd03@o67870.ingest.sentry.io/1198251",
//   enableInExpoDevelopment: false,
//   debug: Environment.environment === "production",
//   enableNative: false,
// });

SystemUI.setBackgroundColorAsync(Theme.colors.background);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 * 5, // 5 days
    },
  },
});

const asyncStoragePersistor = createAsyncStoragePersistor({
  storage: AsyncStorage,
});

persistQueryClient({
  queryClient,
  persistor: asyncStoragePersistor,
});

// if (__DEV__) {
//   // @ts-ignore
//   import("react-query-native-devtools").then(({ addPlugin }) => {
//     addPlugin({ queryClient });
//   });
// }

//const echo = configureEcho();

if (Platform.OS !== "web") {
  const LogBox = require("react-native").LogBox;
  LogBox.ignoreLogs([
    "Virtualized",
    "Constants.manifest",
    "Require cycles are allow",
    "Possible Unhandled Promise Rejection",
    "Setting a timer",
    "Animated.event now requires a second",
    "Module RCTKeyCommandConstants",
  ]);
}

if (Platform.OS === "android") {
  StatusBar.setBackgroundColor("transparent");
  StatusBar.setTranslucent(true);
}

if (Environment.features.music.enabled) {
  const setupPlayer = require("@app/music/services/player").setupPlayer;

  setupPlayer();
}

enableScreens(true);

let providers = [
  NewsProvider,
  GamesProvider,
  ChatProvider,
  PagesProvider,
  MusicProvider,
];
if (__DEV__) {
  providers.push(DebugProvider);
}
TurboApp.registerProviders(providers).bootstrap();
global.turboApp = TurboApp;

const App = () => {
  const [symbolsLoaded] = useFonts({
    TurboSymbols: require("./assets/fonts/TurboSymbols.ttf"),
  });

  const [firebaseLoaded, setFirebaseLoaded] = useState(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [initialized, setInitialized] = useState(true);

  useEffect(() => {
    setupFirebase();
    setTimeout(() => setFirebaseLoaded(true), 100);
  }, []);
  //
  useEffect(() => {
    if (!isReady && symbolsLoaded && firebaseLoaded) {
      setTimeout(() => {
        setIsReady(true);
      }, 300);
    }
  }, [symbolsLoaded, firebaseLoaded]);

  useEffect(() => {
    AuthManager.getInstance()
      .getAuthenticationToken()
      .then((accessToken) => {
        if (accessToken) {
          configureApi(accessToken);
        }
        setInitialized(true);
        //        console.warn("ready");
      });
  }, []);

  // useEffect(() => {
  //   // console.warn("Running echo");
  //   // const echo = configureEcho();

  //   echo.channel("test").listen(".welcome", (e) => {
  //     //Alert.alert("holaxx");
  //     console.warn("Message", e);
  //   });

  //   return () => {
  //     //echo.leaveChannel("test");
  //     echo.disconnect();
  //   };
  // }, []);

  const assetsLoaded = symbolsLoaded && firebaseLoaded;

  if (!assetsLoaded || !initialized) {
    return <Splash isReady={isReady} />;
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <SafeAreaProvider>
        <NativeBaseProvider theme={ExtendedTheme}>
          <PortalProvider>
            <QueryClientProvider client={queryClient}>
              <UIProviders.ToastProvider>
                <Provider store={store}>
                  <AppContextProvider store={store} turboApp={TurboApp}>
                    <Splash isReady={isReady} />
                    <MasterLayout turboApp={TurboApp} />
                  </AppContextProvider>
                </Provider>
              </UIProviders.ToastProvider>
            </QueryClientProvider>
          </PortalProvider>
        </NativeBaseProvider>
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );
};

//codePush(codePushOptions)(App);

export default App;
