import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ScrollView, View } from "react-native";
import {
  BadgePill,
  BoxResponsive,
  CellGroup,
  CellItem,
  Spinner,
  StatePlaceholder,
  Text,
} from "@app/ui";
import { useApiRequest } from "@app/config/api/api";
import { useNavigation } from "@react-navigation/native";
import { find } from "lodash";

interface Props {}

export default (props: Props) => {
  const navigation = useNavigation();
  const [{ data: pages, loading, error }, fetchMyPages] = useApiRequest(
    {
      url: "me/pages",
      method: "GET",
    }
    // { manual: true }
  );

  if (loading) {
    return <Spinner />;
  }

  if (pages?.data?.length) {
    return (
      <BoxResponsive>
        <CellGroup title="Pages" key="pages">
          {pages?.data?.map((page) => {
            const cover = find(page.media, { type: "cover" });
            return (
              <CellItem
                title={page.name}
                disclosure={true}
                //image={avatar?.thumb_url}
                onPress={() =>
                  navigation.push("PageEditor", {
                    id: page.id,
                  })
                }
                accessory={() => (
                  <BadgePill name={page.status} slug={page.status} />
                )}
                image={cover?.thumb_url}
              />
            );
          })}
        </CellGroup>
      </BoxResponsive>
    );
  } else {
    return <StatePlaceholder />;
  }
};
