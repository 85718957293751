import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { Button, ImageBox, StyleSheet, Text, Theme } from "@app/ui";
import { Controller, useForm } from "react-hook-form";
import { TextInput } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";
import useAxios from "axios-hooks";
import { useApiRequest } from "@app/config/api/api";
import { Environment } from "@app/config";

interface Props {
  onDataReady: any;
}

interface YoutubePreview {
  id: string;
  title: string;
  description?: string;
  tags?: string[];
  thumbnails?: object[];
  cover?: object;
}

export default (props: Props) => {
  const navigation = props.navigation || useNavigation();
  const [youtubeId, setYoutubeId] = useState<string | null>(); //i-btAmGAYek
  const [youtubeData, setYoutubeData] = useState<YoutubePreview>();

  const [{ data: item, loading, error }, fetchYoutubePreview] = useApiRequest(
    {
      url: "music/studio/youtube/preview",
      method: "POST",
      //data: { youtube_id: youtubeId },
    },
    { manual: true }
  );

  useEffect(() => {
    setYoutubeData(item);
  }, [item]);

  if (error) {
    //console.error("error", error);
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setYoutubeId(data.youtube_id);
    fetchYoutubePreview({ data: { youtube_id: data.youtube_id } });
  };

  const renderInput = () => {
    return (
      <>
        <Controller
          style={{ margin: 12 }}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              placeholder="URL or Youtube ID"
              style={{
                color: "#fff",
                backgroundColor: Theme.colors.chrome,
                borderWidth: 1,
                borderColor: Theme.colors.chromeHigh,
                borderRadius: 4,
                paddingHorizontal: 12,
                paddingVertical: 8,
              }}
              onBlur={onBlur}
              onChangeText={(value) => onChange(value)}
              value={value}
            />
          )}
          name="youtube_id"
          rules={{ required: true }}
          defaultValue="i-btAmGAYek"
        />
        <Button
          onPress={handleSubmit(onSubmit)}
          // navigation.push("PodcastEditorImportYoutube", {
          //   id: props.id,
          //   youtubeId: props.youtube,
          // })
          //}
          text={"Preview"}
          isLoading={loading}
        />
      </>
    );
  };

  const clear = () => {
    setYoutubeId(null);
    setYoutubeData(null);
  };

  const renderItem = (item: YoutubePreview) => {
    return (
      <View>
        <Text>{item.title}</Text>
        <Text>{item.description}</Text>
        {item.cover?.url && (
          <ImageBox
            source={{ uri: item.cover.url }}
            height={100}
            aspectRatio={1}
          />
        )}
        <Button
          onPress={() => {
            props.onDataReady &&
              props.onDataReady({
                name: item.title,
                description: item.description,
                //cover_url: item.covers,
                source: {
                  type: "youtube",
                  data: item.id,
                },
              });
          }}
          // navigation.push("PodcastEditorImportYoutube", {
          //   id: props.id,
          //   youtubeId: props.youtube,
          // })
          //}
          text={"Continue"}
          isLoading={loading}
        />
      </View>
    );
  };

  return (
    <View style={style.box}>
      <Text>Import Youtube</Text>

      {youtubeData ? renderItem(youtubeData) : renderInput()}
    </View>
  );
};

const style = StyleSheet.create({
  box: {
    margin: 20,
    padding: 20,
    backgroundColor: Theme.colors.chrome,
    borderRadius: 12,
  },
});
