"use strict";
import React, { Component, useEffect, useState } from "react";
import {
  ActivityIndicator,
  Text,
  View,
  FlatList,
  RefreshControl,
  Linking,
  Button,
  Alert,
  Pressable,
  Modal,
} from "react-native";
import {
  TouchableOpacity,
  BorderlessButton,
  RectButton,
  BaseButton,
} from "react-native-gesture-handler";
import {
  StyleSheet,
  BoxResponsive,
  Theme,
  HeaderButton,
  Image,
  ImageBox,
  Actionable,
} from "@app/ui";
//import { Navigation } from 'react-native-navigation';
import { getNews } from "@app/news/services/data";
import { find, concat, get } from "lodash";
import { formatRelative, subDays } from "date-fns";
import { Platform } from "react-native";
import { createStackWithAllScreens } from "@app/config/navigation/screens";
import {
  useContentCompactWidth,
  useContentWidth,
  useNumberOfColumnsForFullScreen,
} from "@app/utils/hooks/screen_dimensions";

interface Props {
  componentId: string;
  query?: Object;
}

interface State {
  loading: boolean;
  loadMore: boolean;
  page: number;
  news: Array<any>;
}

const NewsScreen = ({ navigation, query }) => {
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const contentWidth = useContentCompactWidth();
  const [openBarCodeScanner, setOpenBarCodeScanner] = useState(false);
  const numberOfColumns = useNumberOfColumnsForFullScreen(
    useContentCompactWidth()
  );

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: "News",
      headerRight: () => (
        <HeaderButton
          onPress={() => setOpenBarCodeScanner(true)}
          icon={require("@assets/icons/button_barcode.png")}
        />
      ),
      //headerLeft: () => null,
    });
  }, [navigation]);

  // constructor(props) {
  //   super(props);
  //   // Navigation.events().bindComponent(this);
  //   // Navigation.mergeOptions(this.props.componentId, {
  //   //   topBar: {
  //   //     noBorder: false,
  //   //     title: {
  //   //       text: "Home",
  //   //       component: {
  //   //         name: "retrollector.brand.title",
  //   //         alignment: Platform.OS === "ios" ? "center" : "fill",
  //   //         passProps: {
  //   //           //url: item,
  //   //         },
  //   //       },
  //   //     },
  //   //   },
  //   //   // bottomTabs: {
  //   //   //   backgroundColor: StyleSheet.value('$chromeColor'),
  //   //   // },
  //   // });
  //
  //   this.state = {
  //     loading: false,
  //     page: 1,
  //     news: [],
  //     loadMore: false,
  //   };
  //
  //   //this.renderButtons();
  // }

  const renderButtons = () => {
    let buttons = {
      leftButtons: [
        {
          id: "scan",
          icon: require("@assets/icons/button_barcode.png"),
          //color: '#111111',
        },
      ],
      rightButtons: [
        {
          id: "settings",
          icon: require("@assets/icons/icon_layout.png"),
          //color: '#111111',
        },
      ],
    };

    // if (this.props.user && _.includes(this.props.user.features, 'admin')) {
    //   buttons.rightButtons.push({
    //     id: 'admin',
    //     icon: require('@assets/icons/icon_dashboard.png'),
    //     //color: '#111111',
    //   });
    // }

    // Navigation.mergeOptions(this.props.componentId, {
    //   topBar: {
    //     ...buttons,
    //   },
    // });
  };

  // navigationButtonPressed({ buttonId }) {
  //   if (buttonId === "scan") {
  //     Navigation.showModal({
  //       stack: {
  //         children: [
  //           {
  //             component: {
  //               name: "retrollector.camera.scan",
  //               passProps: {},
  //               options: {
  //                 modalPresentationStyle: "pageSheet",
  //                 topBar: {
  //                   visible: false,
  //                   background: {
  //                     color: "transparent",
  //                   },
  //                   topBar: {
  //                     leftButtons: [
  //                       {
  //                         id: "close",
  //                         icon: require("@assets/icons/button_close.png"),
  //                         color: "#ffffff",
  //                       },
  //                     ],
  //                     rightButtons: [
  //                       {
  //                         id: "history",
  //                         icon: require("@assets/icons/button_history.png"),
  //                         color: "#ffffff",
  //                       },
  //                     ],
  //                   },
  //                 },
  //                 modal: {
  //                   swipeToDismiss: true,
  //                 },
  //               },
  //             },
  //           },
  //         ],
  //       },
  //     });
  //   } else if (buttonId === "admin") {
  //     openAdministration(this.props.componentId);
  //   }
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.user !== this.props.user) {
  //     //this.renderButtons();
  //   }
  // }
  //
  // componentDidMount() {
  //   this.fetchNews(this.state.page, this.props.query);
  // }

  const fetchNews = (page: number, query?: Object = {}) => {
    setLoading(true);

    getNews(page, query).then((response) => {
      const { data } = response;

      let mergedNews = page === 1 ? data.data : concat(news, data.data);

      setCurrentPage(page);
      setLoading(false);
      setNews(mergedNews);
      setLoadMore(data.links.next ? true : false);

      // this.setState({
      //   loading: false,
      //   loadMore: data.links.next ? true : false,
      //   news: news,
      // });
      // `data` is of type ServerData, correctly inferred
    });
  };

  const handleLoadMore = () => {
    if (!loading && loadMore) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderSeparator = () => {
    return (
      <View
        style={{
          height: 2,
          width: "100%",
          //backgroundColor: "#fff",
          borderBottomColor: Theme.colors.border, // StyleSheet.value("$lineSeparator"),
          borderBottomWidth: 0,
        }}
      />
    );
  };

  const renderFooter = () => {
    //it will show indicator at the bottom of the list when data is loading otherwise it returns null
    if (loading && news.length) return <ActivityIndicator />;
    else return null;
  };

  const renderNewStoryV1 = ({ item }) => {
    return (
      <RectButton
        rippleColor={Theme.colors.text}
        underlayColor={Theme.colors.text}
        onPress={() => openNewStory(this.props.componentId, item)}
      >
        <View
          style={{
            //flexDirection: 'row',
            padding: 15,
            alignItems: "center",
          }}
        >
          {find(item.media, { type: "cover" }) && (
            <Image
              source={{
                uri: find(item.media, {
                  type: "cover",
                }).url.replace("http:", "https:"),
              }}
              style={{
                //height: 96,
                borderRadius: 8,
                marginBottom: 8,
                width: "100%",
                aspectRatio: find(item.media, {
                  type: "cover",
                }).aspect_ratio,
              }}
            />
          )}
          <View style={{ flex: 1 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text>Site Name</Text>
              <Text style={{ fontSize: 12, textAlign: "center" }}>
                {item.published_at}
              </Text>
            </View>
            <Text
              style={{
                fontSize: 24,
                lineHeight: 26,
                letterSpacing: -1,
                //fontWeight: 'bold',
                fontFamily: Theme.font.medium,
                color: "#000",
              }}
            >
              {item.title}
            </Text>
            <Text
              style={{
                fontSize: 15,
                alignItems: "center",
                marginTop: 4,
              }}
              numberOfLines={4}
            >
              {item.description}
            </Text>
          </View>
        </View>
      </RectButton>
    );
  };

  const renderNewStoryV2 = ({ item }) => {
    const cover = find(item.media, { type: "cover" });
    const sourceLogo =
      find(item.site.media, { type: "logo" }) ||
      find(item.site.media, { type: "cover" });
    const itemId = "image" + item.id;

    return (
      <Actionable
        style={{
          flex: 1 / numberOfColumns,
          alignItems: "space-between",
        }}
        onPress={() =>
          navigation.push("NewsStory", {
            id: item.id,
            ...Platform.select({
              native: { story: item },
            }),
          })
        }
      >
        <BoxResponsive
          style={styles.newsItemContainer}
          contentWidth={contentWidth}
        >
          <View style={styles.newItemImageContainer}>
            <ImageBox
              containerStyle={styles.newsItemSourceImage}
              source={{
                uri: get(sourceLogo, "url"),
              }}
              width={24}
              height={24}
            />
          </View>
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1 }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingBottom: 4,
                  paddingRight: 8,
                  opacity: 0.5,
                }}
              >
                <Text style={styles.newsItemSourceTitleText} numberOfLines={1}>
                  {item.site.name}
                </Text>
                <Text style={styles.newsItemDateText} numberOfLines={1}>
                  {formatRelative(new Date(item.published_at), new Date())}
                </Text>
              </View>
              <Text style={styles.newsItemTitleText}>{item.title}</Text>
              {cover && (
                <ImageBox
                  source={{
                    uri: find(item.media, {
                      type: "cover",
                    }).url.replace("http:", "https:"),
                  }}
                  sourceThumb={{
                    uri: find(item.media, { type: "cover" }).thumb_url,
                  }}
                  width={contentWidth - 60}
                  aspectRatio={find(item.media, { type: "cover" }).aspect_ratio}
                  borderRadius={8}
                  backgroundColor={
                    find(item.media, { type: "cover" })?.colors?.primary
                  }
                  containerStyle={
                    styles.newsImage
                    // {
                    //   aspectRatio: find(item.media, {
                    //     type: "cover",
                    //   }).aspect_ratio,
                    // },
                  }
                />
              )}
              <Text style={styles.newsItemDescriptionText} numberOfLines={4}>
                {item.description}
              </Text>
            </View>
          </View>
        </BoxResponsive>
      </Actionable>
    );
  };

  if (loading && !news) {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {/* <ActivityIndicator style={{ color: "#000" }} /> */}
      </View>
    );
  }

  useEffect(() => {
    //if (!loading) {
    fetchNews(currentPage);
    //}
  }, [currentPage]);

  const ModalTest = createStackWithAllScreens("xx", "CameraCodeScanner", null, {
    stackPresentation: "modal",
    gestureEnabled: false,
  });

  return (
    <View style={styles.container}>
      <Modal
        visible={openBarCodeScanner}
        animationType="slide"
        presentationStyle={"pageSheet"}
        hardwareAccelerated={true}
        onDismiss={() => setOpenBarCodeScanner(true)}
        onRequestClose={() => setOpenBarCodeScanner(true)}
      >
        {ModalTest}
      </Modal>
      {Platform.OS === "web" && (
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-3251327102100022"
          data-ad-slot="3483008927"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      )}
      <FlatList
        style={{
          width: "100%",
        }}
        data={news}
        //Data={this.state}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={() => fetchNews(1)} />
        }
        key={"news-" + numberOfColumns}
        renderItem={renderNewStoryV2}
        keyExtractor={(item, index) => index.toString()}
        ItemSeparatorComponent={renderSeparator}
        ListFooterComponent={renderFooter}
        onEndReachedThreshold={0.4}
        onEndReached={handleLoadMore}
        numColumns={numberOfColumns}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: Theme.colors.background, //"$backgroundColor",
  },
  newsItemContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    padding: 16,
    borderBottomColor: Theme.colors.separator, // "$lineSeparator",
    //borderBottomWidth: 0.5,
  },
  newItemImageContainer: {
    width: 24 + 8,
  },
  newsItemSourceImage: {
    marginTop: Platform.select({
      default: 19,
      ios: 18,
      android: 20,
    }),
  },
  newsItemSourceTitleText: {
    textTransform: "uppercase",
    fontSize: 12,
    flex: 1,
    marginRight: 8,
    color: Theme.colors.text, //"$textColor",
    fontFamily: Theme.font.regular,
  },
  newsItemTitleText: {
    fontSize: 24,
    lineHeight: 26,
    letterSpacing: -1,
    //fontWeight: 'bold',
    fontFamily: Theme.font.medium,

    paddingRight: 8,
    color: Theme.colors.text, // "$textColor",
  },
  newsItemDescriptionText: {
    fontSize: 15,
    lineHeight: 20,
    alignItems: "center",
    fontFamily: Theme.font.regular,
    marginTop: 2,
    paddingRight: 8,
    color: Theme.colors.text, // "$textColor",
  },
  newsItemDateText: {
    fontSize: 12,
    textAlign: "center",
    color: Theme.colors.text, //"$textColor",
  },
  newsImage: {
    //height: 96,
    //borderRadius: 8,
    marginVertical: 8,
    margin: -4,
    //width: "100%",
    //borderWidth: 0.5,
    //borderColor: Theme.colors.separator,
  },
});

export default NewsScreen;
