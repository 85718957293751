import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Theme } from "@app/ui";

interface Props {
  height?: Number;
  marginTop?: Number;
  colors?: string[];
  color?: string;
  style?: any;
  overflow?: boolean;
  fixed?: boolean;
  mirror?: boolean;
}

export default ({
  height = 302,
  marginTop,
  colors,
  color,
  style,
  overflow = true,
  fixed = true,
  mirror = true,
}: Props) => {
  const finalColors: string[] = colors || [color, Theme.colors.background];
  return (
    <>
      {mirror && (
        <View
          style={{
            backgroundColor: finalColors[0],
            height: height,
            position: fixed ? "absolute" : "relative",
            marginTop: -height,
          }}
        />
      )}
      <LinearGradient
        style={{
          height: height,
          width: "100%",
          position: fixed ? "absolute" : "relative",
          zIndex: -10,
          marginTop: marginTop,
          ...style,
        }}
        colors={finalColors}
      />
    </>
  );
};
