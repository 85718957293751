import * as React from "react";
import {
  Text,
  ScrollView,
  View,
  Platform,
  Image,
  Pressable,
  SafeAreaView,
  Alert,
} from "react-native";
import {
  Avatar,
  SidebarButton,
  SidebarHeader,
  Theme,
  TurboSymbol,
} from "@app/ui";
import { useEffect, useState } from "react";
import { navigationRef } from "@app/config/navigation/navigate";
import { useAccessToken, useAuthenticatedUser } from "@app/user";
import { Environment } from "@app/config";
import {
  useHasAppFeature,
  useHasAppRole,
  useIsAdmin,
  useNavigationStateRedux,
} from "@app/user/hooks/user";
import tailwind from "tailwind-rn";
import {useAppContext} from "@app/config/context/AppContext";

interface Props {
  size?: SidebarSize;
}

enum SidebarSize {
  Compact = 0,
  Extended = 1,
}

export default () => {
  const appContext = useAppContext();
  const [selectedTab, setSelectedTab] = useState("Home");
  const authenticatedUser = useAuthenticatedUser();
  const isAdmin = useIsAdmin();
  const [size, setSize] = useState(SidebarSize.Compact);
  const [isCompact, setIsCompact] = useState(size === SidebarSize.Compact);
  const navigationState = useNavigationStateRedux();
  const avatarUrl =
    authenticatedUser?.profile?.avatar ||
    "https://dsc.cloud/9b3e32/yyEkeJ6otZ5wc3UrL89acTUDGfTcSsUYq2MmQzsS6tfyTcxxYSY1HftqKMsFbnPAxkuFkYL4YR5zHztwuQDT8n3uAV40wL0QhOTp.png";
  const canDebug = useHasAppFeature("debug");
  // useEffect(() => {
  //   console.warn("STATE", navigationState);
  // }, [navigationState]);

  let tabs = [];

  const SidebarContainer = (props) => {
    return (
      <View
        {...props}
        style={[{ marginVertical: 12 }, isCompact && { alignItems: "center" }]}
      >
        {props.children}
      </View>
    );
  };

  if (Environment.features.music.enabled) {
    tabs.push(
      {
        text: "Home",
        iconName: "home-lg",
        symbol: "home",
        selected: false,
        tab: "HomeTab",
      },
      // {
      //   text: "Explore",
      //   icon: require("@assets/icons/tabs/icon_tab_search.png"),
      //   iconName: "search",
      //   selected: false,
      //   tab: "HomeTab",
      // },
      {
        text: "Music",
        iconName: "album-collection",
        symbol: "music-collection",
        selected: false,
        tab: "MusicTab",
      }
    );
  }

  if (Environment.features.games.enabled) {
    tabs.push(
      {
        text: "News",
        iconName: "newspaper",
        symbol: "news",
        selected: false,
        tab: "NewsTab",
      },
      {
        text: "Games",
        iconName: "alien-monster",
        symbol: "pacman",
        selected: false,
        tab: "GamesTab",
      },
      {
        text: "Collections",
        iconName: "box",
        symbol: "collections",
        selected: false,
        tab: "CollectionsTab",
      }
    );
  }

  if (__DEV__ && authenticatedUser?.authenticated) {
    tabs.push({
      text: "Messages",
      iconName: "comment",
      symbol: "messenger",
      selected: false,
      tab: "ActivityTab",
      badge: __DEV__ ? 1 : undefined,
    });
  }

  if (isAdmin) {
    tabs.push({
      text: "Manage",
      iconName: "solar-panel",
      selected: false,
      tab: "ManageTab",
      symbol: "manager",
    });
  }

  // tabs.push({
  //   text: authenticatedUser.authenticated
  //     ? authenticatedUser.profile.name
  //     : "Me",
  //   iconName: "user-circle",
  //   symbol: Environment.id,
  //   selected: false,
  //   tab: "MeTab",
  // });

  // if (__DEV__) {
  //   tabs.push({
  //     text: "Debug",
  //     symbol: "debug",
  //     selected: false,
  //     tab: "DebugTab",
  //     onPress: () => {
  //       navigationRef.current.navigate("DebugList");
  //     },
  //   });
  // }

  const renderLogo = () => {
    const resizeMode = "contain";
    const style =
      size === SidebarSize.Compact
        ? {
            marginHorizontal: 4,
          }
        : {
            marginHorizontal: 20,
          };
    const height = 96;

    return (
      <Pressable
        onPress={() =>
          setSize(
            size === SidebarSize.Compact
              ? SidebarSize.Extended
              : SidebarSize.Compact
          )
        }
        style={{
          display: "flex",
          flex: 1,
          ...style,
          marginVertical: 24,
          paddingBottom: 16,
        }}
      >
        {Environment.id === "retrollector" && (
          <Image
            source={require("@assets/brand/retrollector_sidebar_2.png")}
            style={{ maxWidth: "100%", resizeMode, height: height }}
          />
        )}
        {Environment.id === "groovr" && (
          <Image
            source={require("@assets/brand/groovr_sidebar_2.png")}
            style={{ maxWidth: "100%", resizeMode, height: height }}
          />
        )}
      </Pressable>
    );
  };

  const [width, setWidth] = useState(SidebarSize.Compact ? 76 : 220);

  useEffect(() => {
    setWidth(size === SidebarSize.Compact ? 88 : 220);
    setIsCompact(size === SidebarSize.Compact);
  }, [size]);

  return (
    <SafeAreaView
      style={[
        {
          width: width,
          backgroundColor: "#000000", //Theme.colors.background,
          borderRightWidth: 0.5,
          borderRightColor: Theme.colors.chrome,
        },
        tailwind("h-full"),
      ]}
    >
      <ScrollView
        style={{
          paddingVertical: 16,
          alignContent: "center",
          //paddingHorizontal: 12,
          paddingTop: 24,
          ...Platform.select({
            web: {
              WebkitUserSelect: "none",
              WebkitAppRegion: "drag",
            },
          }),
        }}
      >
        {/*<SidebarHeader text="Music" size="big" />*/}
        {renderLogo()}
        <SidebarContainer>
          {tabs.map((button) => {
            const isSelected = navigationState?.tab?.name === button.tab;
            return (
              <SidebarButton
                key={button.tab}
                text={button.text}
                compact={isCompact}
                selected={isSelected}
                iconName={button.iconName}
                symbol={
                  button.symbol +
                  (button.text === selectedTab ? "-solid" : "-solid")
                }
                badge={button.badge}
                onPress={() => {
                  setSelectedTab(button.text);
                  if (button.onPress) {
                    return button.onPress();
                  }
                  navigationRef.current?.navigate("Root", {
                    screen: button.tab,
                  });
                }}
              />
            );
          })}
        </SidebarContainer>
        {__DEV__ && !isCompact && (
          <SidebarContainer>
            {/*<SidebarHeader text="Biblioteca" size="normal" />*/}
          </SidebarContainer>
        )}
      </ScrollView>
      {isCompact && (
        <View
          style={{
            alignItems: "center",
            paddingVertical: 16,
          }}
        >
          <SidebarContainer>
            {canDebug && __DEV__ && (
              <SidebarButton
                text="Debug"
                compact={isCompact}
                selected={false}
                symbol="debug-solid"
                onPress={() => {
                  navigationRef.current?.navigate("DebugList");
                }}
              />
            )}
            {/*{isAdmin && (*/}
            {/*  <SidebarButton*/}
            {/*    text="Admin"*/}
            {/*    compact={isCompact}*/}
            {/*    selected={false}*/}
            {/*    symbol="manager-solid"*/}
            {/*    onPress={() => {*/}
            {/*      navigationRef.current?.navigate("Admin");*/}
            {/*    }}*/}
            {/*  />*/}
            {/*)}*/}
            {__DEV__ && (
              <SidebarButton
                key={"show-activity"}
                text={"Activity"}
                compact={isCompact}
                selected={appContext.navigation.rightMenu}
                symbol="music-style-jumper"
                badge={null}
                onPress={() => {
                  appContext.navigation.setRightMenu(!appContext.navigation.rightMenu)
                }}
              />
            )}
          </SidebarContainer>
          <Avatar
            image={avatarUrl}
            onPress={() => navigationRef?.current?.navigate("UserProfile")}
            size={52}
          />
        </View>
      )}
      {__DEV__ && !isCompact && (
        <View
          style={{
            height: 60,
            paddingHorizontal: 16,
            opacity: 0.3,
          }}
        >
          <Avatar
            image={avatarUrl}
            onPress={() => navigation.push("UserSettings")}
            size={52}
          />
          {/*<Text style={{ color: "#fff" }}>*/}
          {/*  {accessToken ? "Has access token" : "NOT AT"}*/}
          {/*</Text>*/}
          {/*<Text style={{ color: "#fff" }}>*/}
          {/*  {authenticatedUser ? "Logged IN" : "NOT Logged In"}*/}
          {/*</Text>*/}
          {/*{authenticatedUser && (*/}
          {/*  <Text style={{ color: "#fff" }}>*/}
          {/*    {authenticatedUser.profile.name}*/}
          {/*  </Text>*/}
          {/*)}*/}
          {/*<Text style={{ color: "#fff" }}>{Environment.name}</Text>*/}
        </View>
      )}
    </SafeAreaView>
  );
};
