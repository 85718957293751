import {
  CommonActions,
  useNavigation,
  useNavigationState,
  useRoute,
} from "@react-navigation/native";
import { ScaledButton, TurboSymbol, Text, Theme } from "@app/ui";
import * as React from "react";
import { BlurView } from "expo-blur";
import { Platform, View } from "react-native";
import { useNavigationStateRedux } from "@app/user/hooks/user";

interface Props {
  navigation?: any;
  backgroundColor?: string;
  arrowColor?: string;
  symbol?: string;
  onPress?: any;
  title?: string;
  direction?: "left" | "right";
}

const BackButton = (props: Props) => {
  const navigation = props.navigation || useNavigation();
  const backgroundColor = props.backgroundColor || "#444"; // "rgba(255,255,255,.9)";
  const arrowColor = props.arrowColor || Theme.colors.text; // "rgba(0,0,0,.9)";
  const symbol = props.symbol || "back-full-line";
  const size = 34;
  const iconSize = symbol == "back-line" ? size : 18;
  const currentNavigation = useNavigationStateRedux();

  const defaultOnPress = () => {
    return currentNavigation
      ? navigation.goBack()
      : navigation.dispatch(
          CommonActions.reset({ index: 0, routes: [{ name: "Root" }] })
        );
  };
  const onPress = props.onPress || defaultOnPress;
  const direction =
    props.direction || symbol === "back-full-line" ? "left" : "right";

  const shadow = {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  };

  const renderText = () => {
    return props.title ? (
      <Text
        style={{
          marginRight: 8,
          marginLeft: 4,
          fontFamily: Theme.fonts.titles.medium,
          fontSize: 13,
          marginTop: 1,
        }}
      >
        {props.title}
      </Text>
    ) : (
      <></>
    );
  };

  const renderSymbol = () => {
    return (
      <>
        {renderText()}
        <TurboSymbol name={symbol} size={iconSize} color={arrowColor} />
      </>
    );
  };

  return (
    <ScaledButton
      onPress={onPress}
      style={{
        minWidth: size,
        borderRadius: size / 2,
        height: size,
        //backgroundColor,
        ...shadow,
        overflow: "hidden",
        marginLeft: direction === "right" ? 10 : 0,
        marginRight: direction === "right" ? 0 : 10,
      }}
      pointerMode="lift"
    >
      {Platform.OS === "ios" ? (
        <BlurView
          tint={Theme.dark ? "dark" : "light"}
          intensity={100}
          style={{
            minWidth: size,
            height: size,
            borderRadius: size / 2,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            paddingHorizontal: props.title ? 10 : 0,
          }}
        >
          {renderSymbol()}
        </BlurView>
      ) : (
        <View
          style={{
            backgroundColor: "rgba(0,0,0,.5)",
            minWidth: size,
            height: size,
            borderRadius: size / 2,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            paddingHorizontal: props.title ? 10 : 0,
          }}
        >
          {renderSymbol()}
        </View>
      )}
    </ScaledButton>
  );
};

export default BackButton;
