import { Platform } from "react-native";
import DeviceInfo from "react-native-device-info";

const bundleId =
  Platform.OS === "web"
    ? process.env.ELECTRON_WEBPACK_APP_PACKAGE
    : DeviceInfo.getBundleId();

if (__DEV__) {
  console.log("Bundle " + bundleId);
  //Constants?.manifest?.extra?.package ||
}

const endpointApiDev = "https://turbo-api.eu.ngrok.io/api/";
const endpointApiProd = "https://app.thefunbots.com/api/";
const endpointSocketDev = "";
const endpointSocketProd = "wss://ws-app.thefunbots.com/";

interface AppFeature {
  enabled: boolean;
}

interface FeatureMusic extends AppFeature {}
interface FeatureGames extends AppFeature {}

interface EnvironmentFeatures {
  music: FeatureMusic;
  games: FeatureGames;
  chat: boolean;
}

interface UIConfig {
  fontFamily: "rubik" | "hind";
}

interface Service {
  // appId: string;
  // scopes?: [string?];
  [key: string]: any;
}

interface ServicesList {
  [key: string]: Service;
}

interface EnvironmentConfig {
  environment: string;
  host: string;
  socketUrl: string;
  apiUrl: string;
  dashboardUrl: string;
  linking?: string[];
  features: EnvironmentFeatures;
  name: string;
  id: string;
  ui: UIConfig;
  services?: ServicesList;
}

interface EnvironmentList {
  [key: string]: EnvironmentConfig;
}

const machinepopFeatures: EnvironmentFeatures = {
  music: {
    enabled: true,
  },
  games: {
    enabled: false,
  },
  chat: true,
};

const retrollectorFeatures: EnvironmentFeatures = {
  music: {
    enabled: false,
  },
  games: {
    enabled: true,
  },
  chat: true,
};

const petitsFeatures: EnvironmentFeatures = {
  music: {
    enabled: false,
  },
  games: {
    enabled: false,
  },
  chat: true,
};

const environments: EnvironmentList = {
  // Dev / Staging / Beta
  "com.retrollector.mobile.development": {
    environment: "development",
    host: "retrodev.thefunbots.com",
    socketUrl: endpointSocketDev,
    apiUrl: endpointApiDev,
    dashboardUrl: "https://retrodev.thefunbots.com/dashboard",
    features: retrollectorFeatures,
    name: "RetroDEV",
    id: "retrollector",
    ui: {
      fontFamily: "rubik",
    },
    linking: ["retrodev://"],
    services: {
      facebook: {
        appId: "2037453569717196",
        scopes: ["email"],
      },
      firebase: {
        apiKey: "AIzaSyAfj4dx2NpEaWuGflX9eT5JfOTqRmyCwBc",
        authDomain: "game-collector-b46eb.firebaseapp.com",
        databaseURL: "https://game-collector-b46eb.firebaseio.com",
        projectId: "game-collector-b46eb",
        storageBucket: "game-collector-b46eb.appspot.com",
        messagingSenderId: "610358682414",
        appId: "1:610358682414:web:d2e751680840ee439ad0bf",
        vapid:
          "BIHXdNzoJBv6kR6BbTlnpLjPetn9rJ31rR_hrSfN815g5YXOy9cDODMHxAtS1MqUXlyVMJ6ET2-o2TqL7wkIvx4",
        measurementId: "test",
      },
      pusher: {
        key: "ed881ab57671a9f0be56",
      },
    },
  },
  "com.machinepop.mobile.development": {
    environment: "development",
    host: "dev.machinepop.io",
    socketUrl: endpointSocketDev,
    apiUrl: endpointApiDev,
    dashboardUrl: "https://dev.machinepop.io/dashboard",
    features: machinepopFeatures,
    name: "MachineDEV",
    id: "groovr",
    ui: {
      fontFamily: "rubik",
    },
    linking: ["machinedev://"],
    services: {
      facebook: {
        appId: "10155033217166407",
        scopes: ["public_profile", "email"],
      },
      firebase: {
        apiKey: "AIzaSyDBegtaCOz-jK31a8_sUO5ijXUCpEj8iLU",
        authDomain: "makinaio-dev.firebaseapp.com",
        databaseURL: "https://makinaio-dev.firebaseio.com",
        projectId: "makinaio-dev",
        storageBucket: "makinaio-dev.appspot.com",
        messagingSenderId: "725290548784",
        appId: "1:725290548784:web:9435b5b9ed147d4836f7f5",
        vapid:
          "BHJV8zLyRhpyUtT8XhFXC7T8FkwcmSL7BUFUNsE-GSXdK8vndYKYdVYIB9A0ToJpehdG3azEAOPvVGeapH8phvs",
        measurementId: "test",
      },
      pusher: {
        key: "ed881ab57671a9f0be56",
      },
      stripe: {
        key:
          "pk_test_51IICE9JzezH80661TR8A53frYJ7CdvndaDcEO3SQujGlL0G5J9p7KJj7zwexkxopLeQxFEJrVlv50F4A9Mche66b00fVjzN82w",
      },
    },
  },
  "com.elspetits.mobile.development": {
    environment: "development",
    host: "petitsdev.thefunbots.com",
    socketUrl: endpointSocketDev,
    apiUrl: endpointApiDev,
    dashboardUrl: "https://petitsdev.thefunbots.com/dashboard",
    features: petitsFeatures,
    name: "PetitsDEV",
    id: "elspetitsdev",
    ui: {
      fontFamily: "rubik",
    },
    services: {
      facebook: {
        appId: "206030421171314",
        scopes: ["public_profile"],
      },
      firebase: {},
    },
  },

  // Production
  "com.retrollector.mobile": {
    environment: "production",
    host: "retrollector.com",
    socketUrl: endpointSocketProd,
    apiUrl: endpointApiProd,
    dashboardUrl: "https://retrollector.com/dashboard",
    features: retrollectorFeatures,
    name: "Retrollector",
    id: "retrollector",
    ui: {
      fontFamily: "rubik",
    },
    services: {
      facebook: {
        appId: "397483464311981",
        scopes: [],
      },
      firebase: {
        apiKey: "AIzaSyD9HnKEAQ4aPi989FEuPCrMClo3lfoGOPs",
        authDomain: "retrollector-42abd.firebaseapp.com",
        databaseURL: "https://retrollector-42abd.firebaseio.com",
        projectId: "retrollector-42abd",
        storageBucket: "retrollector-42abd.appspot.com",
        messagingSenderId: "165823178983",
        appId: "1:165823178983:web:50b5bbce478c9ad28a9a42",
        measurementId: "G-HV89L510VD",
        vapid:
          "BCEWjnN--Casjhkr7z9A0c0jlowivt5c6NOErmCpTXf1cVu6JWeSt9bUwYNCwzV14fJArFpucL2EqG00sQaZ5yc",
      },
    },
  },
  "com.groovr.mobile": {
    environment: "production",
    host: "groovr.live",
    socketUrl: endpointSocketProd,
    apiUrl: endpointApiProd,
    dashboardUrl: "https://groovr.live/dashboard",
    features: machinepopFeatures,
    name: "Groovr",
    id: "groovr",
    ui: {
      fontFamily: "rubik",
    },
    linking: ["groovr://", "machinepop://"],
    services: {
      facebook: {
        appId: "65947386406",
        scopes: ["public_profile", "email"],
      },
      firebase: {
        apiKey: "AIzaSyB8G1F56tCvifuarkAUHUIivlKiCscKTww",
        authDomain: "machinepop.firebaseapp.com",
        databaseURL: "https://machinepop.firebaseio.com",
        projectId: "machinepop",
        storageBucket: "machinepop.appspot.com",
        messagingSenderId: "516095985464",
        appId: "1:516095985464:web:dd04f3bff1090e4e9ae3cd",
        measurementId: "G-FKCR52V1BX",
        vapid:
          "BPp944Xe-DFoUPpGmsEu03HeOLp3OBpzkNSX_o-0NoJij_O3E9MWW7-2uzdahS-kKPbNoBP-Wk8RNbRF_wA4kr0",
      },
      pusher: {
        key: "fea3b9e37616f58017ed",
      },
    },
  },
  "com.elspetits.mobile": {
    environment: "production",
    host: "petits.app",
    apiUrl: endpointApiProd,
    socketUrl: endpointSocketProd,
    dashboardUrl: "https://petits.app/dashboard",
    features: petitsFeatures,
    name: "Petits",
    id: "elspetits",
    ui: {
      fontFamily: "rubik",
    },
    services: {
      facebook: {
        appId: "431398251327201",
        scopes: ["public_profile"],
      },
      firebase: {
        apiKey: "AIzaSyCJd7qPkwXMbXoff-RlwjRQjmzNI_LhX0Y",
        authDomain: "petits-sssvo.firebaseapp.com",
        databaseURL: "https://petits-sssvo.firebaseio.com",
        projectId: "petits-sssvo",
        storageBucket: "petits-sssvo.appspot.com",
        messagingSenderId: "399570510016",
        appId: "1:399570510016:web:b1583356f4ce9d4bfa0d25",
        measurementId: "G-9ZKGBS3LCR",
      },
    },
  },
};

const env: EnvironmentConfig = environments[bundleId];

export default env;
