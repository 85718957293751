import createIconSet from "react-native-vector-icons/lib/create-icon-set";

const glyphMap = require("./symbol.json");
const iconSet = createIconSet(glyphMap, "TurboSymbols", "TurboSymbols.ttf");

export default iconSet;
export const {
  Button,
  TabBarItem,
  TabBarItemIOS,
  getImageSource,
  getImageSourceSync,
} = iconSet;
