"use strict";
import React, { Component } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  StyleSheet,
  Pressable,
  Platform,
} from "react-native";
import axios from "axios";
import { concat, find } from "lodash";
import {
  TouchableOpacity,
  BorderlessButton,
  RectButton,
  BaseButton,
} from "react-native-gesture-handler";
import { ImageBox, Theme } from "@app/ui";
import { getCollection } from "@app/games/services/data";

interface Props {
  componentId: string;
  query?: Object;
  id: number;
}

interface State {
  loading: boolean;
  loadMore: boolean;
  page: number;
  items: Array<any>;
}

class CollectionScreen extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 1,
      items: [],
      loadMore: false,
    };

    this.getItems = this.getItems.bind(this);
  }

  static options(passProps) {
    return {
      topBar: {},
    };
  }

  componentDidMount() {
    this.getItems(this.state.page, this.props.query);
  }

  // openGame(game: object) {
  //   Navigation.push(this.props.componentId, {
  //     component: {
  //       name: 'retrollector.game.detail',
  //       passProps: {
  //         id: game.id,
  //         game: game,
  //       },
  //       options: {
  //         topBar: {
  //           visible: false,
  //           drawBehind: false,
  //           animate: true, // Controls whether TopBar visibility changes should be animated
  //           //hideOnScroll: true,
  //           background: {
  //             color: 'transparent',
  //           },
  //         },
  //       },
  //       // options: {
  //       //   statusBar: {
  //       //     visible: false
  //       //   },
  //       //   topBar: {
  //       //     visible: false
  //       //   }
  //       // }
  //     },
  //   });
  // }

  getItems(page: number, query?: Object = {}) {
    if (page > 1) {
      this.setState({
        loading: true,
      });
    }

    getCollection(this.props.route.params.id, page, query).then((response) => {
      const { data } = response;

      let items =
        page === 1
          ? data.data.items
          : concat(this.state.items, data.data.items);

      this.setState({
        loading: false,
        //loadMore: data.links.next ? true : false,
        items,
      });
      // `data` is of type ServerData, correctly inferred
    });
  }

  handleLoadMore = () => {
    if (!this.state.loading && this.state.loadMore) {
      this.setState({
        page: this.state.page + 1,
      });
      this.getItems(this.state.page); // method for API call
    }
  };

  renderSeparator = () => {
    return (
      <View
        style={{
          height: 2,
          width: "100%",
          //backgroundColor: "#fff",
          borderBottomColor: "#CED0CE",
          borderBottomWidth: 0,
        }}
      />
    );
  };

  renderFooter = () => {
    //it will show indicator at the bottom of the list when data is loading otherwise it returns null
    if (!this.state.loading) return null;
    return <ActivityIndicator size="large" />;
  };

  openGame = (item) => {
    this.props.navigation.push("GameDetail", {
      id: item.id,
      ...Platform.select({ native: { gameOriginal: item } }),
    });
  };

  render() {
    if (this.state.loading && !this.state.items) {
      return (
        <View
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {/* <ActivityIndicator style={{ color: "#000" }} /> */}
        </View>
      );
    }

    return (
      <View style={{ width: "100%", height: "100%" }}>
        <FlatList
          data={this.state.items}
          extraData={this.state}
          refreshControl={
            <RefreshControl
              refreshing={this.state.loading}
              onRefresh={() => this.getItems(1)}
            />
          }
          renderItem={({ item }) => {
            const game = item.item;
            return (
              <Pressable onPress={() => this.openGame(game)}>
                <View
                  style={{
                    flexDirection: "row",
                    paddingHorizontal: 16,
                    paddingVertical: 8,
                    alignItems: "center",
                  }}
                >
                  {find(game.media, { type: "cover" }) && (
                    <ImageBox
                      source={{
                        uri: find(game.media, { type: "cover" }).thumb_url,
                      }}
                      style={{
                        borderRadius: 4,
                        height: 72,
                        width: 72,
                        marginRight: 16,
                        borderWidth: 0.5,
                        borderColor: "rgba(255,255,255,.3)",
                      }}
                      aspectRatio={
                        find(game.media, { type: "cover" }).aspect_ratio
                      }
                    />
                  )}
                  <View style={{ flex: 1 }}>
                    <Text style={styles.gamesTitleText}>{game.name}</Text>
                    <Text style={styles.gamesDescriptionText} numberOfLines={3}>
                      {game.summary}
                    </Text>
                  </View>
                </View>
              </Pressable>
            );
          }}
          keyExtractor={(item, index) => index.toString()}
          ItemSeparatorComponent={this.renderSeparator}
          ListFooterComponent={this.renderFooter.bind(this)}
          onEndReachedThreshold={0.4}
          onEndReached={this.handleLoadMore.bind(this)}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  gamesTitleText: {
    fontSize: 17,
    //fontWeight: 'bold',
    fontFamily: Theme.font.regular,
    alignItems: "center",
    color: Theme.colors.text,
    letterSpacing: -0.7,
  },
  gamesDescriptionText: {
    fontSize: 14,
    alignItems: "center",
    marginTop: 4,
    color: Theme.colors.text,
    fontFamily: Theme.font.regular,
  },
});

export default CollectionScreen;

//AppRegistry.registerComponent('BadInstagramCloneApp', () => BadInstagramCloneApp);
