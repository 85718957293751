import * as React from "react";
import {
  ScrollView,
  View,
  RefreshControl,
  FlatList,
  Pressable,
  SectionList,
  Alert,
  Modal,
  Platform,
  Dimensions,
} from "react-native";

import { Audio, Video } from "expo-av";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { getGames } from "@app/games/services/data";
import { concat } from "lodash";

import { instance as api } from "@app/config/api/api";
import {
  Actionable,
  BackButton,
  BackgroundGradient,
  Button,
  ContextMenu,
  Feed,
  FeedApi,
  HeaderBar,
  HeaderButton,
  HeaderButtonGroup,
  ImageBox,
  ScaledButton,
  Text,
  Theme,
  TurboSymbol,
} from "@app/ui";
import { Track } from "@app/music/models/track";
import { addTrack } from "@app/music/services/player";
import {
  useContentWidth,
  useIsLargeScreen,
  useNumberOfColumnsForFullScreen,
} from "@app/utils/hooks/screen_dimensions";
import { useAuth, useHasAppFeature, useHasAppRole } from "@app/user/hooks/user";
import { useNavigation } from "@react-navigation/native";

export default (props) => {
  const auth = useAuth();
  const navigation = props.navigation || useNavigation();
  const canStream = useHasAppFeature("stream");
  const canDebug = useHasAppFeature("debug");
  const canUsePoints = useHasAppFeature("points");
  const canAdmin = useHasAppRole("admin");

  const points = auth?.user?.points?.amount || 0;
  const pointsAmount = points;

  const items = [
    {
      id: "stream",
      title: "Stream",
      image: "broadcast-solid",
      onPress: () => navigation.navigate("StreamBroadcast"),
    },
    {
      id: "create_page",
      title: "Create Page",
      onPress: () => navigation.navigate("PageCreate"),
    },
    {
      id: "create_podcast",
      title: "Create Podcast",
      onPress: () => navigation.navigate("PodcastCreate"),
    },
  ];

  if (canDebug && __DEV__) {
    items.push({
      id: "debug",
      title: "Debug",
      onPress: () => navigation.push("DebugList", { demo: true }),
    });
  }

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route?.params?.title || " ",
      headerRight: () => (
        <HeaderButtonGroup>
          {/*{canDebug && __DEV__ && (*/}
          {/*  <BackButton*/}
          {/*    symbol={"debug-solid"}*/}
          {/*    onPress={() => navigation.push("DebugList", {})}*/}
          {/*  />*/}
          {/*)}*/}

          {canAdmin && (
            <ContextMenu items={items}>
              <BackButton
                symbol={"game-crosshair-solid"}
                onPress={() => null}
              />
            </ContextMenu>
          )}
          {/*{canStream && (*/}
          {/*  <BackButton*/}
          {/*    onPress={() => navigation.push("StreamBroadcast", {})}*/}
          {/*    symbol={"broadcast-solid"}*/}
          {/*    title={"Stream"}*/}
          {/*  />*/}
          {/*)}*/}
          {canUsePoints && (
            <BackButton
              onPress={() => navigation.push("Wallet", { demo: true })}
              title={pointsAmount}
              symbol={"groovr-solid"}
            />
          )}
          {/*<BackButton*/}
          {/*  onPress={() =>*/}
          {/*    navigation.push("Modal", {*/}
          {/*      screen: "StreamBroadcast",*/}
          {/*      stackPresentation: "formSheet",*/}
          {/*    })*/}
          {/*  }*/}
          {/*  symbol={"dots-solid"}*/}
          {/*/>*/}
        </HeaderButtonGroup>
      ),
      //headerLeft: () => null,
    });
  }, [props.navigation]);

  const debugShortcuts = () => {
    return (
      <View
        style={{
          backgroundColor: Theme.colors.primary,
          marginHorizontal: 20,
          marginVertical: 8,
        }}
      >
        <Button
          onPress={() =>
            props.navigation.push("PodcastEditor", {
              id: 4,
            })
          }
          text={"Edit podcast"}
        ></Button>
      </View>
    );
  };

  //console.warn("HOME RENDER");
  return (
    <FeedApi path={"feed"} queryKey={"feed"} params={props.route?.params} />
  );

  return (
    <>
      <BackgroundGradient color={"#444"} height={600} marginTop={-120} />
      <HeaderBar
        statusBar={true}
        navigationBar={true}
        safeArea={true}
        navigation={navigation}
      />
      <FeedApi path={"feed"} queryKey={"feed"} params={props.route?.params} />
    </>
  );
};
