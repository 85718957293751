import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { Button, StyleSheet, Text, Theme } from "@app/ui";
import { Controller, useForm } from "react-hook-form";
import { TextInput } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";
import { useApiRequest } from "@app/config/api/api";
import * as DocumentPicker from "expo-document-picker";
interface Props {}

interface FileInfo {
  uri: any;
  name: string;
  size: Number;
}

export default (props: Props) => {
  const navigation = props.navigation || useNavigation();
  const [fileInfo, setFileInfo] = useState<FileInfo>();

  // const [{ data: item, loading, error }, fetchYoutubePreview] = useApiRequest(
  //   {
  //     url: "music/studio/youtube/preview",
  //     method: "POST",
  //     data: { youtube_id: youtubeId },
  //   },
  //   { manual: true }
  // );
  //
  // if (error) {
  //   console.error("error", error);
  // }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const renderInput = () => {
    return (
      <>
        <Button
          onPress={
            () =>
              DocumentPicker.getDocumentAsync({
                type: "audio/*",
                copyToCacheDirectory: false,
                multiple: false,
              }).then(({ type, uri, name, size }) => {
                if (type === "success") {
                  setFileInfo({ uri, name, size });
                }
              })
            // navigation.push("PodcastEditorImportYoutube", {
            //   id: props.id,
            //   youtubeId: props.youtube,
            // })
          }
          text={"Upload"}
        />
      </>
    );
  };

  const renderItem = (item: FileInfo) => {
    return (
      <View>
        <Text>{item.name}</Text>
        <Text>{item.size}</Text>
        <Button onPress={() => null} text={"Upload"} />
      </View>
    );
  };

  return (
    <View style={style.box}>
      <Text>File Upload</Text>

      {fileInfo ? renderItem(fileInfo) : renderInput()}
    </View>
  );
};

const style = StyleSheet.create({
  box: {
    margin: 20,
    padding: 20,
    backgroundColor: Theme.colors.chrome,
    borderRadius: 12,
  },
});
