"use strict";
import React, { Component, useEffect, useMemo, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Linking,
  Dimensions,
  SafeAreaView,
  Pressable,
} from "react-native";
import { Text, Image, Theme, Avatar } from "@app/ui";
import { useNavigation } from "@react-navigation/native";
import { ConversationLastMessage } from "@app/chat/components/conversations-list";
import { formatRelative } from "date-fns";

interface Props {
  id: string;
  title: string;
  lastMessage?: ConversationLastMessage;
  avatar: string;
  unread: Number;
}

const ConversationRow = (props: Props) => {
  const navigation = props.navigation || useNavigation();
  const openConversation = (id) => {
    //navigation.navigate("Modal", { id: props.id, screen: "ChatConversation" });
    navigation.push("ChatConversation", { id: props.id });
  };
  const date = useMemo(() => {
    return formatRelative(new Date(props.lastMessage?.date), new Date(), {
      addSuffix: true,
    });
  }, [props.lastMessage?.date]);

  return (
    <Pressable
      style={{ flex: 1, minHeight: 60 }}
      onPress={() => openConversation(props.id)}
    >
      <View
        style={{
          flex: 1,
          minHeight: 60,
          flexDirection: "row",
          marginHorizontal: 20,
          marginVertical: 8,
        }}
      >
        <View style={{ justifyContent: "center" }}>
          <Avatar image={props.avatar} size={56} />
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            marginLeft: 20,
            justifyContent: "center",
          }}
        >
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <Text
              style={{
                fontFamily: Theme.fonts.titles.medium,
                fontSize: 15,
                lineHeight: 20,
                flexGrow: 1,
              }}
            >
              {props.title}
            </Text>
            <Text
              style={{
                fontFamily: Theme.fonts.titles.regular,
                fontSize: 13,
                opacity: 0.5,
                lineHeight: 20,
                textAlign: "right",
              }}
            >
              {date}
            </Text>
          </View>
          <Text
            style={{
              fontFamily: Theme.fonts.titles.regular,
              fontSize: 14,
              lineHeight: 20,
              opacity: 0.75,
            }}
            numberOfLines={2}
          >
            {props.lastMessage?.text}
          </Text>
          {/*<Text*/}
          {/*  style={{*/}
          {/*    fontFamily: Theme.fonts.titles.regular,*/}
          {/*    fontSize: 13,*/}
          {/*    opacity: 0.5,*/}
          {/*    lineHeight: 20,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {formatRelative(new Date(props.lastMessage?.date), new Date(), {*/}
          {/*    addSuffix: true,*/}
          {/*  })}*/}
          {/*</Text>*/}
        </View>
        {props.unread > 0 && (
          <View style={{ justifyContent: "center", marginLeft: 16 }}>
            <Text
              style={{
                backgroundColor: "red",
                textAlign: "center",
                minWidth: 20,
                borderRadius: 10,
                overflow: "hidden",
                lineHeight: 20,
                fontFamily: Theme.fonts.titles.medium,
              }}
            >
              {props.unread}
            </Text>
          </View>
        )}
      </View>
    </Pressable>
  );
};

export default ConversationRow;
