import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { instance } from "@app/config/api/api";

export const useIsLiked = (type: string, id: string, fetch: boolean = true) => {
  const fn = async ({}) => {
    const path = "me/social/like/" + type + "/" + id;
    return instance.get(path);
  };

  return useQuery(["like", type, id], fn, {
    enabled: fetch,
    select: (data) => {
      return data?.data?.data || data;
    },
  });
};

export const useLikeMutation = (type: string, id: any) => {
  const queryClient = useQueryClient();
  const queryKey = ["like", type, id];

  const fn = ({ like }) => {
    const path = "me/social/like/" + type + "/" + id;
    return like ? instance.post(path) : instance.delete(path);
  };

  return useMutation(fn, {
    //mutationKey: queryKey,
    // onSuccess: (data, variables, context) => {
    //   //console.warn("k", ["following", variables.type, variables.id]);]
    //   // console.warn("qk", queryKey);
    //   // console.warn("data", data?.data?.data);
    //
    //   queryClient.setQueryData(queryKey, data?.data?.data);
    // },
    // onMutate: (variables) => {
    //   console.warn("v", variables);
    //   queryClient.setQueryData(queryKey, (previous) => {
    //     return { test: 1 };
    //   });
    //
    //   return { is_following: variables.follow };
    // },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(queryKey, data);
    },
    onError: (error, variables, context) => {
      console.error("e", error);
    },
  });
};
