import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { ScaledButton, TurboSymbol } from "@app/ui";
import { Track } from "@app/music/models/track";
import { addTrack } from "@app/music/services/player";

import TrackPlayer, {
  STATE_BUFFERING,
  STATE_PLAYING,
  TrackPlayerEvents,
  useTrackPlayerEvents,
} from "react-native-track-player";
import { usePlayerStatus } from "@app/user/hooks/user";
import { BlurView } from "expo-blur";

interface Props {
  track: Track;
  color?: string;
}

export const addSongToQueue = (track: Track) => {
  const generatedID = track.id + "_" + Date.now();
  const finalTrack = {
    ...track,
    sid: track.id,
    //id: generatedID
  };

  return addTrack(finalTrack, null, true);
};

export default ({ track, color = "#20D0A2" }: Props) => {
  const playerStatus = usePlayerStatus();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const isTrackPlaying =
      playerStatus.playback.isPlaying &&
      track.uuid === playerStatus.playback.currentTrack.uuid;

    if (isTrackPlaying !== isPlaying) {
      setIsPlaying(isTrackPlaying);
    }
  }, [playerStatus.playback.currentTrack, playerStatus.playback.isPlaying]);

  // const eventHandler = async (state) => {
  //   const currentTrackId = await TrackPlayer.getCurrentTrack();
  //   const currentTrack = await TrackPlayer.getTrack(currentTrackId);
  //
  //   const eventIsPlaying =
  //     state === STATE_PLAYING ||
  //     state === STATE_BUFFERING ||
  //     state === "loading" ||
  //     state === "ready";
  //
  //   console.log("track", { track: track.uuid, current: currentTrack.uuid });
  //
  //   const isTrackPlaying = eventIsPlaying && track.uuid === currentTrack.uuid;
  //
  //   if (isTrackPlaying !== isPlaying) {
  //     setIsPlaying(isTrackPlaying);
  //   }
  // };
  //
  // useEffect(() => {
  //   (async () => {
  //     const state = await TrackPlayer.getState();
  //     await eventHandler(state);
  //   })();
  // }, []);

  // useTrackPlayerEvents(events, async (event) => {
  //   await eventHandler(event.state);
  // });

  const renderSuperPlay = (track: Track) => {
    const buttonSize = 38;

    const icon = isPlaying ? "pause-solid" : "play-solid";

    return (
      <ScaledButton
        onPress={() => addSongToQueue(track)}
        style={{
          width: buttonSize,
          height: buttonSize,
          borderRadius: buttonSize / 2,
          overflow: "hidden",
          //backgroundColor: color,
        }}
      >
        <BlurView
          tint={"dark"}
          intensity={100}
          style={{
            width: buttonSize,
            height: buttonSize,
            borderRadius: buttonSize / 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TurboSymbol
            name={icon}
            color="#fff"
            size={15}
            style={{ marginLeft: 2 }}
          />
        </BlurView>
      </ScaledButton>
    );
  };

  return renderSuperPlay(track);
};
