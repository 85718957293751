"use strict";
import { useState, createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "@app/user/actions/auth.action";
import AuthManager from "@app/auth/services/auth.service";
import { includes } from "lodash";
import {
  AuthState,
  AuthUserState,
  PlayerState,
  RootState,
} from "@app/config/redux/initialState";
import { useQuery } from "react-query";
import { instance } from "@app/config/api/api";

const AuthenticatedUserContext = createContext<AuthUserState>(null);

enum UserAppFeatures {
  Chat = "chat",
  Stream = "stream",
}

enum UserAppRoles {
  Admin = "admin",
  Moderator = "moderator",
}

const useMe = (fetch = false) => {
  return useQuery(
    "me",
    async () => {
      const { data } = await instance.get("me");
      return data.data;
    },
    { enabled: fetch }
  );
};

const useNavigationStateRedux = () => {
  return useSelector((state: RootState) => state.navigation.state);
};

const canGoBack = () => {
  const canGoBack = useNavigationStateRedux() !== undefined;
};

const useAccessToken = () => {
  // const [accessToken, setAccessToken] = useState(null);
  //
  // AuthManager.getInstance()
  //   .getAuthenticationToken()
  //   .then((pass) => setAccessToken(pass));
  //
  // return accessToken;

  return useSelector((state: RootState) => state.auth.access_token);
};

const useAuthenticatedUser = (): AuthUserState => {
  return useContext(AuthenticatedUserContext);
  //return useSelector((state: RootState) => state.auth.user);
};

const useAuth = () => {
  const user = useAuthenticatedUser();
  const accessToken = useAccessToken();

  return {
    user,
    accessToken,
  };
};

const fetchAuthenticatedUser = () => {
  const dispatch = useDispatch();

  dispatch(authActions.getMe());
};

const useAppFeatures = () => {
  const appFeatures: UserAppFeatures[] = useAuthenticatedUser()?.features;

  return appFeatures;
};

const useHasAppFeature = (feature: UserAppFeatures) => {
  return includes(useAppFeatures(), feature);
};

const useAppRoles = () => {
  const appRoles: UserAppRoles[] = useAuthenticatedUser()?.roles;

  return appRoles;
};

const useHasAppRole = (role: UserAppRoles) => {
  return includes(useAppRoles(), role);
};

const useHasCredentials = () => {};

const useIsAdmin = () => {
  return useHasAppRole("admin");
};

const usePlayerStatus = (): PlayerState => {
  return useSelector((state: RootState) => state.player);
};

export {
  useAccessToken,
  useAuthenticatedUser,
  fetchAuthenticatedUser,
  useHasAppFeature,
  useAppFeatures,
  useAppRoles,
  useHasAppRole,
  useIsAdmin,
  useAuth,
  useNavigationStateRedux,
  usePlayerStatus,
  useMe,
  AuthenticatedUserContext,
};
