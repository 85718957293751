import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import {
  Pressable,
  Platform,
  View,
  ImageSourcePropType,
  ImageStyle,
  NativeEventEmitter,
  StyleSheet,
  ViewStyle,
  StyleProp,
} from "react-native";
import { Theme } from "@app/ui";

const Image =
  Platform.OS === "web"
    ? require("react-native").Image
    : require("react-native-fast-image");

interface Props {
  text?: string;
  backgroundColor?: string;
  borderWidth?: number;
  borderRadius?: number;
  badge?: string | number;
  width?: number;
  height?: number;
  aspectRatio?: number;
  source: ImageSourcePropType;
  sourceThumb?: ImageSourcePropType;
  style?: ViewStyle;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: any;
}

interface ImageBoxSize {
  width: number;
  height: number;
  aspectRatio?: number;
}

export default ({
  text,
  backgroundColor = "transparent",
  borderWidth = 0.5,
  borderRadius = 2,
  badge,
  width,
  height,
  aspectRatio = 1,
  source,
  sourceThumb,
  onPress,
  style,
  containerStyle,
}: Props) => {
  let newHeight = height;
  let newWidth = width;

  if (width && height) {
    //console.log("WH");
    aspectRatio = width / height;
  } else if (width && !height) {
    //console.log("W", { newWidth, newHeight });
    //newHeight = newWidth / aspectRatio;
  } else if (!width && height) {
    //console.log("H");
  }

  const renderView = () => {
    const sharedStyle = {
      zIndex: 1,
      ...containerStyle,
      ...style,
      borderWidth,
      borderColor: "rgba(255,255,255,0.25)",
      backgroundColor: backgroundColor,
      borderRadius: borderRadius,
      ...Platform.select({
        web: {
          //          width: layout.width,
          //height: layout.height,
          width: newWidth,
          height: newHeight,
          aspectRatio: aspectRatio,

          //paddingTop: 100 / aspectRatio + "%",
        },
        native: {
          width: width,
          height: height,
          aspectRatio: aspectRatio,
          //margin: 0.1,
        },
      }),
    };

    return <Image source={source} style={[sharedStyle]} />;
  };

  const renderPressableWithView = () => {
    return <Pressable onPress={onPress}>{renderView}</Pressable>;
  };

  if (onPress) {
    return renderPressableWithView();
  } else {
    return renderView();
  }
};
