import * as React from "react";
import {
  ScrollView,
  View,
  RefreshControl,
  FlatList,
  Pressable,
  SectionList,
  Alert,
  Modal,
  Platform,
  Dimensions,
} from "react-native";

import { Audio, Video } from "expo-av";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { getGames } from "@app/games/services/data";
import { concat, toPairs } from "lodash";

import { instance as api } from "@app/config/api/api";
import {
  Actionable,
  Feed,
  FeedApi,
  HeaderButton,
  ImageBox,
  ScaledButton,
  Text,
  Theme,
  TurboSymbol,
} from "@app/ui";

export default (props) => {
  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route?.params?.title || "Discover",
      // headerRight: () => (
      //   <HeaderButton
      //     onPress={() =>
      //       props.navigation.push("Modal", {
      //         screen: "StreamBroadcast",
      //         stackPresentation: "formSheet",
      //       })
      //     }
      //     symbol={"broadcast-solid"}
      //   />
      // ),
      //headerLeft: () => null,
    });
  }, [props.navigation]);

  let queryKey = ["music", "feed"];
  const params = toPairs(props.route?.params).flat();

  return (
    <FeedApi
      queryKey={concat(queryKey, params)}
      path={"music/feed"}
      params={props.route?.params}
    />
  );
};
