"use strict";
import React, { Component, ReactNode } from "react";
import { View, Text, StyleSheet } from "react-native";
import { Theme } from "@app/ui";
//import EStyleSheet from 'react-native-extended-stylesheet';

interface Props {
  children?: ReactNode;
  key?: string;
  title?: string;
  collapse?: boolean;
  size: "normal" | "compact";
  margin?: boolean;
  accessory?: any;
}

interface State {}

class CellGroup extends Component<Props, State> {
  static defaultProps: Props = {
    collapse: false,
    size: "normal",
    margin: true,
  };

  constructor(props: Props) {
    super(props);
  }

  renderTitle() {
    return <Text style={styles.title}>{this.props.title}</Text>;
  }

  renderChildren() {
    const childCount = React.Children.count(this.props.children);

    if (!Array.isArray(this.props.children)) {
      return (
        <View style={[styles.contentContainer]}>
          {React.cloneElement(this.props.children, {
            isFirstItem: true,
            isLastItem: true,
          })}
        </View>
      );
    }

    // @ts-ignore
    const items = this.props.children
      // @ts-ignore
      .filter((item: any) => React.isValidElement(item))
      .map((item: any, i: Number) => {
        return React.cloneElement(item, {
          isFirstItem: i === 0,
          isLastItem: i === childCount - 1,
        });
      });

    return <View style={[styles.contentContainer]}>{items}</View>;
  }

  renderTitleAndAccessory = () => {
    if (!this.props.title && !this.props.accessory) {
      return;
    }

    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginHorizontal: 12,
        }}
      >
        {this.props.title && this.renderTitle()}
        {this.props.accessory && this.props.accessory()}
      </View>
    );
  };

  render() {
    const margin = this.props.margin ? 16 : 0;
    return (
      <View key={this.props.key} style={[styles.container, { margin }]}>
        {this.renderTitleAndAccessory()}
        {this.props.children && this.renderChildren()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  contentContainer: {
    borderRadius: 12,
    overflow: "hidden",
  },
  title: {
    height: 48,
    lineHeight: 48,
    fontSize: 17,
    fontFamily: Theme.fonts.titles.medium,
    //fontFamily: Theme.font.regular,
    color: Theme.colors.text, //"$textColor",
    flexGrow: 1,
  },
});

export default CellGroup;
