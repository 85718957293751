import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import { Alert, FlatList, Platform, Pressable, View } from "react-native";
import {
  Actionable,
  ImageBox,
  ScaledButton,
  Text,
  Theme,
  TurboSymbol,
} from "@app/ui";
import PodcastChapterItem from "./podcast_chapter_item";

export default (props) => {
  return (
    <FlatList
      data={props.data}
      horizontal={false}
      renderItem={({ item }) => <PodcastChapterItem item={item} />}
      keyExtractor={(item, index) => "chapter-item-" + item.id}
      ItemSeparatorComponent={() => (
        <View
          style={{
            ...Platform.select({
              ios: {
                height: 0.5,
                opacity: 0.5,
              },
            }),
            marginTop: 0.5,
            backgroundColor: Theme.colors.separator,
            marginHorizontal: 20,
          }}
        />
      )}
      style={{ flex: 1 }}
    />
  );
};
