import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Platform,
  Pressable,
  TextInput,
  TextInputProps,
  UIManager,
  View,
} from "react-native";
import { CellGroup, CellItem, Text, TextError, Theme } from "@app/ui";
import { FormikProps, FormikValues, useField } from "formik";

if (
  Platform.OS === "android" &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

interface Props extends TextInputProps {
  //inputProps?: any;
  name: string;
  formik?: FormikProps<FormikValues>;
}

export default (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [useBigPlaceholder, setUseBigPlaceholder] = useState(false);
  const inputName = props.name;
  const textRef = useRef();
  // const [field, meta, helpers] = useField(props.name);
  // helpers.setValue(12);

  const textStyle = {
    color: "#fff",
    borderWidth: 0,
    borderColor: Theme.colors.chromeHigh,
    paddingHorizontal: 12,
    paddingVertical: 8,
  };

  const containerStyle = {
    backgroundColor: Theme.colors.chrome,
    borderWidth: 0.5,
    borderColor: isFocused ? Theme.colors.primary : "transparent",
    borderRadius: 5,
    fontFamily: Theme.fonts.titles.regular,
  };

  const placeholderStyle = {
    fontFamily: Theme.fonts.titles.regular,
    fontSize: 12,
    marginHorizontal: 12,
    marginTop: 8,
    color: isFocused ? Theme.colors.primary : Theme.colors.text,
  };

  return (
    <>
      <Pressable onPress={() => textRef?.current?.focus()}>
        <View style={[containerStyle]}>
          <Text style={[placeholderStyle]}>{props.placeholder}</Text>
          <TextInput
            {...props}
            ref={textRef}
            onChangeText={(e) => {
              props.formik?.handleChange &&
                props.formik.handleChange(inputName)(e);
            }}
            onFocus={(e) => {
              setIsFocused(true);
              props.onFocus && props.onFocus(e);
              //  props.formik?.handleF && props.formik.onFocus(this.props.name)(e);
            }}
            onBlur={(e) => {
              setIsFocused(false);
              props.onBlur && props.onBlur(e);
              props.formik?.handleBlur && props.formik.handleBlur(inputName)(e);
            }}
            style={[{ fontFamily: Theme.fonts.titles.regular }, textStyle]}
          />
        </View>
      </Pressable>
      {props.formik?.errors[inputName] && (
        <TextError>{props.formik?.errors[inputName]}</TextError>
      )}
    </>
  );
};
