"use strict";
import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Alert,
  Text,
  View,
  FlatList,
  RefreshControl,
  Image,
  Pressable,
} from "react-native";
import {
  Actionable,
  BoxResponsive,
  HeaderButton,
  StyleSheet,
  Theme,
} from "@app/ui";
import { getPlatforms } from "@app/games/services/data";
import { concat, find } from "lodash";

interface Props {
  navigation: any;
}
interface State {
  loading: boolean;
  loadMore: boolean;
  page: number;
  platforms: Array<any>;
}

const PlatformScreen = (props: Props) => {
  // constructor(props) {
  //   super(props);
  //   Navigation.events().bindComponent(this);
  //
  //   this.state = {
  //     loading: false,
  //     page: 1,
  //     platforms: [],
  //     loadMore: false,
  //   };
  // }
  //
  // componentDidMount() {
  //   this.navigationEventListener = Navigation.events().bindComponent(this);
  //   this.getPlatforms(this.state.page);
  // }

  // componentWillUnmount() {
  //   // Not mandatory
  //   if (this.navigationEventListener) {
  //     //this.navigationEventListener.remove();
  //   }
  // }

  // static options(passProps) {
  //   return {
  //     topBar: {
  //       leftButtons: [
  //         {
  //           id: 'scan',
  //           icon: require('@assets/icons/button_barcode.png'),
  //           color: 'blue',
  //         },
  //       ],
  //       rightButtons: [],
  //     },
  //   };
  // }
  const { navigation } = props;
  const title = props.route.title || "Platforms";

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: title,
      headerLeft: () => null,
      headerRight: () => (
        <HeaderButton onPress={() => navigation.push("GamesList")} text="All" />
      ),
    });
  }, [navigation]);

  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getMyPlatforms = (page: number) => {
    setLoading(true);

    getPlatforms(page).then((response) => {
      const { data } = response;

      let platformsMerged =
        page === 1 ? data.data : concat(platforms, data.data);

      setPlatforms(platformsMerged);
      setLoading(false);
      setLoadMore(data.links.next ? true : false);

      // `data` is of type ServerData, correctly inferred
    });
  };

  const handleLoadMore = () => {
    if (loading && loadMore) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderSeparator = () => {
    return (
      <View
        style={{
          height: 0.5,
          width: "100%",
          //backgroundColor: "#CED0CE",
          borderBottomColor: "#CED0CE",
          borderBottomWidth: 0,
        }}
      />
    );
  };

  const renderFooter = () => {
    //it will show indicator at the bottom of the list when data is loading otherwise it returns null
    if (!loading) return null;
    return <ActivityIndicator />;
  };

  if (loading && !platforms) {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {/* <ActivityIndicator style={{ color: "#000" }} /> */}
      </View>
    );
  }

  useEffect(() => {
    if (!loading) {
      getMyPlatforms(1);
    }
  }, [currentPage]);

  return (
    <BoxResponsive style={{ flex: 1 }}>
      <FlatList
        data={platforms}
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={() => getMyPlatforms(1)}
          />
        }
        renderItem={({ item, index }) => (
          <Actionable
            onPress={() =>
              navigation.push("GamesList", {
                query: { platform_id: item.id },
                title: item.name,
              })
            }
          >
            <View
              style={{
                flexDirection: "row",
                padding: 15,
                alignItems: "center",
              }}
            >
              <Image
                source={{
                  uri: find(item.media, { type: "logo" }).thumb_url,
                }}
                style={[styles.platformImage, { tintColor: Theme.colors.text }]}
                tintColor={Theme.colors.text}
              />
              <View>
                <Text style={styles.platformTitleText}>{item.name}</Text>
              </View>
            </View>
          </Actionable>
        )}
        keyExtractor={(item, index) => index.toString()}
        ItemSeparatorComponent={renderSeparator}
        ListFooterComponent={renderFooter()}
        onEndReachedThreshold={0.4}
        onEndReached={handleLoadMore}
      />
    </BoxResponsive>
  );
};

const styles = StyleSheet.create({
  platformImage: {
    height: 24,
    width: 24,
    marginRight: 10,
    tintColor: Theme.colors.text,
  },
  platformTitleText: {
    fontSize: 18,
    //fontWeight: 'bold',
    alignItems: "center",
    color: Theme.colors.text,
    fontFamily: Theme.font.medium,
  },
});

export default PlatformScreen;
