import { TurboProvider, TurboScreen } from "@app/core/providers/ModuleProvider";

interface AppScreens {
  [key: string]: TurboScreen;
}

type TurboApp = {
  providers: TurboProvider[];
  screens: AppScreens;
  bootstrap: Function;
  registerProviders: Function;
  registerProvider: Function;
  screensWithDeeplink: Function;
  allScreens: Function;
  tabScreens: Function;
  modalScreens: Function;
};

export const App: TurboApp = {
  providers: [],
  screens: {},

  bootstrap() {
    this.providers.forEach((provider: TurboProvider) => {
      //provider.bootstrap();
      provider.screens.screens.forEach((screen: TurboScreen) => {
        this.screens[screen.name] = screen;
      });
    });

    return this;
  },

  registerProviders(providers: TurboProvider[]) {
    providers.forEach((provider: TurboProvider) => {
      this.registerProvider(provider);
    });

    return this;
  },

  registerProvider(provider: TurboProvider) {
    if (this.providers.indexOf(provider) === -1) {
      this.providers.push(provider);
    }

    return this;
  },

  screensWithDeeplink() {
    const screens = this.allScreens().filter(
      (screen: TurboScreen) => "path" in screen
    );
    const screens2 = this.allScreens().filter(
      (screen: TurboScreen) => "path" in screen
    );
    const screens3 = [...screens, ...screens2];
    let final = {};
    screens3.forEach((screen: TurboScreen) => {
      final[screen.name] = {
        //initialRouteName: screen.initialRouteName,
        path: screen.path,
        //ex: screen.name,
      };
    });
    return final;
  },

  allScreens() {
    return Object.values(this.screens);
  },
  tabScreens() {
    return this.allScreens().filter(
      (screen: TurboScreen) => screen?.modal !== true
    );
  },
  modalScreens() {
    return this.allScreens().filter(
      (screen: TurboScreen) => screen?.modal === true
    );
  },
};
