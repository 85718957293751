import React, { useEffect, useState } from "react";
import { usePlayerStatus } from "@app/user/hooks/user";
import { instance } from "@app/config/api/api";
import { Track } from "@app/music/models/track";

interface TrackPosition {
  track?: Track;
  position?: number;
}

export default ({}) => {
  return <></>;
};
