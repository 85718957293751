import React, { useEffect, useState } from "react";
import {
  Alert,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
interface Props {}

const MusicMiniPlayer = ({ height, navigation }: Props) => {
  return <View></View>;
};

export default MusicMiniPlayer;
