import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Platform,
  Pressable,
  TextInput,
  TextInputProps,
  UIManager,
  View,
} from "react-native";
import { CellGroup, CellItem, Modal, Text, TextError, Theme } from "@app/ui";
import { FormikProps, FormikValues, useField } from "formik";
import { find } from "lodash";
import { MenuAction, MenuView } from "@react-native-menu/menu";

if (
  Platform.OS === "android" &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export type SelectInputItem = {
  id: string | number;
  name: string;
  subtitle?: string;
  symbol?: string;
};

export enum SelectInputMode {
  Cell = 0,
  List = 1,
  Box = 2,
}

interface Props extends TextInputProps {
  name: string;
  formik?: FormikProps<FormikValues>;
  items: SelectInputItem[];
  mode?: SelectInputMode;
  multiple?: boolean;
}

export default ({
  mode = SelectInputMode.Cell,
  multiple = false,
  items = [],
  ...props
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [useBigPlaceholder, setUseBigPlaceholder] = useState(false);
  const inputName = props.name;
  const [field, meta, helpers] = useField(inputName);
  const { value } = meta;
  const { setValue } = helpers;
  const [selected, setSelected] = useState<SelectInputItem>(
    find(items, { id: props.value })
  );
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setSelected(find(items, { id: props.value }));
  }, [props.value]);

  const textStyle = {
    color: "#fff",
    borderWidth: 0,
    borderColor: Theme.colors.chromeHigh,
    paddingHorizontal: 12,
    paddingVertical: 8,
  };

  const containerStyle = {
    backgroundColor: Theme.colors.chrome,
    borderWidth: 0.5,
    borderColor: isFocused ? Theme.colors.primary : "transparent",
    borderRadius: 5,
    fontFamily: Theme.fonts.titles.regular,
  };

  const placeholderStyle = {
    fontFamily: Theme.fonts.titles.regular,
    fontSize: 12,
    marginHorizontal: 12,
    marginTop: 8,
    color: isFocused ? Theme.colors.primary : Theme.colors.text,
  };

  const renderItem = (item: SelectInputItem, selected: boolean) => {
    return <View>{item.name}</View>;
  };

  const renderCellNative = () => {
    const itemsMap: MenuAction[] = items.map((item: SelectInputItem) => {
      return {
        id: item.id,
        title: item.name,
        subtitle: item.subtitle,
        symbol: item.symbol,
        state: item.id === selected?.id ? "on" : null,
      };
    });

    // [
    //   {
    //     id: "share",
    //     title: "Share Action",
    //     titleColor: "#46F289",
    //     subtitle: "Share action on SNS",
    //     image: Platform.select({
    //       ios: "square.and.arrow.up",
    //       android: "ic_menu_share",
    //     }),
    //     imageColor: "#46F289",
    //     state: "on",
    //   },
    // ]

    return (
      <MenuView
        actions={itemsMap}
        onPressAction={({ nativeEvent }) => {
          setSelected(find(items, { id: nativeEvent.event }));
        }}
      >
        {renderCellContent()}
      </MenuView>
    );
  };

  const renderCellDefault = () => {};

  const renderCell = () => {
    return (
      <>
        {Platform.select({
          ios: renderCellNative(),
          android: renderCellNative(),
          default: renderCellOld(),
        })}
        {renderError()}
      </>
    );
  };

  const renderCellContent = () => {
    return (
      <View style={[containerStyle]}>
        {props.placeholder && (
          <Text style={[placeholderStyle]}>{props.placeholder}</Text>
        )}
        <Text style={[textStyle]}>{selected?.name}</Text>
      </View>
    );
  };

  const renderCellOld = () => {
    return (
      <>
        <Pressable onPress={() => setModalVisible(true)}>
          <View style={[containerStyle]}>
            {props.placeholder && (
              <Text style={[placeholderStyle]}>{props.placeholder}</Text>
            )}
            <Text style={[textStyle]}>{selected?.name}</Text>
          </View>
        </Pressable>
        {renderError()}
      </>
    );
  };

  const renderBox = () => {
    return (
      <View>
        <Text style={textStyle}>Box</Text>
      </View>
    );
  };

  const renderError = () => {
    return (
      props.formik?.errors[inputName] && (
        <TextError>{props.formik?.errors[inputName]}</TextError>
      )
    );
  };

  if (mode == SelectInputMode.Cell) {
    return renderCell();
  } else if (mode == SelectInputMode.Box) {
    return renderBox();
  } else if (mode == SelectInputMode.List) {
    return renderBox();
  }

  return (
    <View>
      <Text>{mode}</Text>
      {renderError()}
    </View>
  );
};
