import {
  Animated,
  Button,
  Image,
  Linking,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import * as React from "react";
import { NavigationContainer, useTheme } from "@react-navigation/native";
import { Theme, TurboSymbol, createStackNavigator, GlassView } from "@app/ui";
import {
  createBottomTabNavigator,
  BottomTabBar,
} from "@react-navigation/bottom-tabs";
import { Host, Portal } from "react-native-portalize";
import { navigationRef } from "./navigate";
import {
  PlaybackPositionSync,
  PlayerMiniCompat,
  PlayerScreen,
  PlayerScreenCompat,
  PodcastScreen,
} from "@app/music";
import {
  HomeStackScreen,
  createAllScreens,
  defaultScreenOptions,
} from "@app/config/navigation/screens";
import { Environment, isElectron, NavigationLinking } from "@app/config";
import * as Analytics from "expo-firebase-analytics";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "@app/user/actions/auth.action";
import { useContext, useEffect, useRef, useState } from "react";
import {
  AuthenticatedUserContext,
  useAccessToken,
  useAuthenticatedUser,
  useHasAppFeature,
  usePlayerStatus,
} from "@app/user/hooks/user";
import FontAwesome5Pro from "react-native-vector-icons/FontAwesome5Pro";
import { getPushToken } from "@app/config/firebase/firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Modalize } from "react-native-modalize";
import { UserProfileScreen } from "@app/user";
import MyTabBar from "./tabBar";
import { TurboApp } from "@app/core";

// Gets the current screen from navigation state
const getActiveRouteName = (state) => {
  const route = state?.routes[state.index];

  if (route?.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route?.name;
};

interface Props {
  leftMenu: boolean;
  leftMenuComponent?: any;
  musicPlayer: boolean;
  user?: any;
  turboApp: typeof TurboApp;
}

const PERSISTENCE_KEY = "NAVIGATION_STATE";

const RootStack = createStackNavigator();

const AppNavigation = ({
  leftMenu,
  leftMenuComponent,
  musicPlayer,
  screenProperties,
  user,
  turboApp,
}: Props) => {
  const routeNameRef = React.useRef();
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const authenticatedUser = useAuthenticatedUser();
  const [isReady, setIsReady] = React.useState(__DEV__ ? false : true);

  const isLoggedIn = false; // user?.authenticated;

  const [initialState, setInitialState] = React.useState();

  React.useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();

        if (Platform.OS !== "web" && initialUrl == null) {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
          const state = savedStateString
            ? JSON.parse(savedStateString)
            : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  // @ts-ignore
  React.useEffect(() => {
    if (accessToken) {
      dispatch(authActions.setLoggedIn(accessToken));
    }
  }, [accessToken]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(authActions.getMe());
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    const state = navigationRef?.current?.getRootState();
    // Save the initial route name
    routeNameRef.current = getActiveRouteName(state);
  }, []);

  useEffect(() => {
    if (authenticatedUser?.id) {
      Analytics.setUserId(authenticatedUser.id.toString());
    } else {
      Analytics.setUserId(null);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    (async () => {
      const pushToken = await getPushToken();
      if (authenticatedUser && pushToken) {
        dispatch(authActions.savePushToken(pushToken));
      }
    })();
  }, [authenticatedUser]);

  const onStateChange = (state) => {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = getActiveRouteName(state);

    if (previousRouteName !== currentRouteName) {
      // The line below uses the expo-firebase-analytics tracker
      // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
      // Change this line to use another Mobile analytics SDK
      //if (Platform.OS !== "web") {
      Analytics.logEvent("screen_view", {
        screen_name: currentRouteName,
      });
      //}
      //console.warn("Screen " + currentRouteName);
    }

    // Save the current route name for later comparision
    routeNameRef.current = currentRouteName;

    //Persist state for DEV
    AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state));

    //Save to redux
    dispatch(authActions.setNavigationState(state));
  };

  const currentRoute = null;

  const testTabs = () => {
    return (
      <Tab.Navigator>
        <Tab.Screen name="HomeTab" component={HomeStackScreen} />
        <Tab.Screen name="ProfileTab" component={UserProfileScreen} />
      </Tab.Navigator>
    );
  };

  if (!isReady) {
    return <View style={{ backgroundColor: "#000", flex: 1 }} />;
  }

  let finalLinking = {
    ...NavigationLinking,
  };
  finalLinking.config.screens = {
    ...finalLinking.config.screens,
    ...turboApp.screensWithDeeplink(),
  };

  // @ts-ignore
  return (
    <NavigationContainer
      //initialState={initialState}
      theme={{
        ...Theme,
        colors: { ...Theme.colors, card: Theme.colors.background },
      }}
      ref={navigationRef}
      onStateChange={onStateChange}
      linking={isElectron ? null : finalLinking}
      fallback={<Text>Loading...</Text>}
      path=""
    >
      <RootStack.Navigator>
        <RootStack.Screen
          name="Root"
          component={MyTabBar}
          options={{ headerShown: false }}
        />
        <RootStack.Group screenOptions={defaultScreenOptions}>
          {createAllScreens(RootStack, TurboApp.allScreens())}
        </RootStack.Group>
      </RootStack.Navigator>
    </NavigationContainer>
  );
};

export default AppNavigation;
