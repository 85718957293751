import * as React from "react";
import { TurboApp } from "@app/core";
import {Alert} from "native-base";

interface Props {
  children: React.ReactNode;
  store?: any;
  turboApp: typeof TurboApp;
}

interface AppContextState {
  navigation: {
    leftMenu: boolean;
    rightMenu: boolean;
    setRightMenu: Function;
    musicPlayer: boolean;
  };
}

const defaultContext: AppContextState = {
  navigation: {
    leftMenu: false,
    rightMenu: false,
    musicPlayer: false
  }
};

const AppContext = React.createContext<AppContextState>(defaultContext);

const AppContextProvider = ({ children, store, turboApp }: Props) => {
  const [test, setTest] = React.useState(false);
  const [rightMenu, setRightMenu] = React.useState(false);

  const defaultContext: AppContextState = {
    navigation: {
      leftMenu: false,
      rightMenu,
      setRightMenu,
      musicPlayer: false
    },
  };

  return <AppContext.Provider value={defaultContext}>{children}</AppContext.Provider>;
};

const useAppContext = (): AppContextState => React.useContext(AppContext);

export { useAppContext, AppContext, AppContextProvider };
