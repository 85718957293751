import React, { Component } from "react";
import {
  Animated,
  ActivityIndicator,
  AppRegistry,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  FlatList,
  RefreshControl,
  Dimensions,
  Platform,
  SafeAreaView,
  TouchableWithoutFeedback,
  Alert,
  LayoutAnimation,
  Image as ReactImage,
} from "react-native";
import {
  StyleSheet,
  Modal,
  Card,
  Button,
  Spinner,
  BoxResponsive,
  Theme,
} from "@app/ui";
// import {
//   getUserHasGameInCollection,
//   addGameToUserCollection,
// } from "@app/games/";
import {
  getUserHasGameInCollection,
  addGameToUserCollection,
} from "../services/data";

interface Props {
  gameID: number;
  game?: object;
  isVisible: boolean;
  onClose?: Function;
}

interface State {
  loading: boolean;
  //game?: GameModel;
  //appearance: GameAppearance;
  isVisible: boolean;
  itemsCount: number;
}

class GameInCollectionModal extends Component<Props, State> {
  defaultProps: {
    isVisible: false;
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isVisible: false,
      itemsCount: 0,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.isVisible !== state.isVisible) {
      //setTimeout(() => this.setState({ loading: false }).bind, 3000);
      return {
        isVisible: props.isVisible,
        loading: true,
      };
    }

    return null;
  }

  onOpen() {
    this.setState({
      loading: true,
    });

    getUserHasGameInCollection(this.props.gameID).then((response) => {
      this.setState({
        loading: false,
        itemsCount: response.data.count,
      });
    });
  }

  addToCollection() {
    this.setState({
      loading: true,
    });

    addGameToUserCollection(this.props.gameID).then((response) => {
      this.setState({
        loading: false,
        itemsCount: response.data.count,
      });
    });
  }

  render() {
    return (
      <Modal
        isVisible={this.state.isVisible}
        onOpen={this.onOpen.bind(this)}
        onClose={() => {
          this.setState({ isVisible: false });
          this.props.onClose && this.props.onClose();
        }}
      >
        <Card
          title={"Add game with ID " + this.props.gameID}
          isLoading={this.state.loading}
        >
          {this.state.itemsCount === 0 && (
            <View>
              <Text
                style={{
                  padding: 32,
                  textAlign: "center",
                  color: Theme.colors.text,
                }}
              >
                Aun no lo tienes!
              </Text>
              <Button
                text="Add to my Collection"
                onPress={this.addToCollection.bind(this)}
              />
            </View>
          )}
          {this.state.itemsCount > 0 && (
            <View>
              <Text
                style={{
                  padding: 32,
                  textAlign: "center",
                  color: Theme.colors.text,
                }}
              >
                Ya lo tienes!
              </Text>
            </View>
          )}
        </Card>
      </Modal>
    );
  }
}

export default GameInCollectionModal;
