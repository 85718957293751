import React, { Component, useEffect, useState } from "react";
import { SafeAreaView, Alert, Pressable, View, FlatList } from "react-native";
import { BoxResponsive, Button, HeaderButton, ImageBox, Text } from "@app/ui";
import ContextMenu from "react-native-context-menu-view";
import { Track } from "@app/music/models/track";
import { addTrack } from "@app/music/services/player";
import { useDispatch } from "react-redux";

enum MediaType {
  audio = "audio",
  video = "video",
}

interface SuperTrack extends Track {
  mediaType: MediaType;
}

export const songs = [
  {
    id: "ml-1",
    mediaType: MediaType.audio,
    url:
      "https://cdn.thefunbots.com/file/thefunbots-cdn/tests/music/ml_records_001.mp3",
    artist: "Skudero",
    album: "Spirid Bird",
    title: "Spirid Bird",
    artwork:
      "https://i.ytimg.com/vi/KCOjVruBfTI/hq720.jpg?sqp=-oaymwEXCNAFEJQDSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLBhUgQRGpzdGuXz04ncVorpCQOEZA",
  },
  {
    id: "ml-2",
    mediaType: MediaType.audio,
    url:
      "https://cdn.thefunbots.com/file/thefunbots-cdn/tests/music/ml_records_002.mp3",
    artist: "Xavi Metralla",
    album: "Fight Song",
    title: "Fight Song",
    artwork:
      "https://i.ytimg.com/vi/7Glf1SmN_cw/hq720.jpg?sqp=-oaymwEXCNAFEJQDSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLD64qz7RxJcXx7pfY43C_mIiPolLg",
  },
  {
    id: "1",
    mediaType: MediaType.audio,
    url:
      "https://cdn.thefunbots.com/file/thefunbots-cdn/tests/music/chrome.mp3",
    artist: "Chromosome",
    album: "Chrome",
    title: "Chrome",
    artwork:
      "https://img.discogs.com/4tBtdze96fYk9JCDioUVp8ucxwU=/fit-in/600x600/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-374867-1566302950-3071.jpeg.jpg",
  },
  {
    id: "2",
    mediaType: MediaType.audio,
    url:
      "https://cdn.thefunbots.com/file/thefunbots-cdn/tests/music/maniacs.mp3?1",
    artist: "Pastis & Buenri",
    album: "XQue vol. 4",
    title: "Maniacs",
    artwork:
      "https://img.discogs.com/0GyALvkGpzxbrNH_7QfZipiRIlg=/fit-in/600x597/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-976075-1179840588.jpeg.jpg",
  },
  {
    id: "3",
    mediaType: MediaType.audio,
    url:
      "https://cdn.thefunbots.com/file/thefunbots-cdn/tests/music/diabolica.mp3",
    artist: "Xavi Metralla",
    album: "Diabolica",
    title: "Diabolica",
    artwork:
      "https://img.discogs.com/KnU4ruCET3VXOB4-GgCgb72ge_Q=/fit-in/337x334/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-362939-1199797874.jpeg.jpg",
  },
  {
    id: "4",
    mediaType: MediaType.audio,
    url:
      "https://cdn.thefunbots.com/file/thefunbots-cdn/tests/music/session_panic.mp3",
    artist: "Santi Ripper",
    album: "Live @ Panic",
    title: "Live & Panic",
    artwork:
      "https://img.discogs.com/KnU4ruCET3VXOB4-GgCgb72ge_Q=/fit-in/337x334/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-362939-1199797874.jpeg.jpg",
  },
  {
    id: "5",
    mediaType: MediaType.audio,
    url:
      "https://cdn.thefunbots.com/file/thefunbots-cdn/tests/music/session_chasis.mp3",
    artist: "Santi Ripper",
    album: "Live @ Chasis",
    title: "Live & Chasis",
    artwork:
      "https://img.discogs.com/KnU4ruCET3VXOB4-GgCgb72ge_Q=/fit-in/337x334/filters:strip_icc():format(jpeg):mode_rgb():quality(90)/discogs-images/R-362939-1199797874.jpeg.jpg",
  },
] as SuperTrack[];

const MusicDemoScreen = ({ navigation }) => {
  const dispatch = useDispatch();
  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: "Demo",
      headerRight: () => (
        <HeaderButton
          onPress={() => Alert.alert("hola")}
          icon={require("@assets/icons/button_barcode.png")}
        />
      ),
    });
  }, [navigation]);

  const addSongToQueue = (track: Track) => {
    const generatedID = track.id + "_" + Date.now();
    const finalTrack = { ...track, id: generatedID };

    return addTrack(finalTrack);
  };

  const actions = [
    { id: "play", title: "Play" },
    { id: "addToQueue", title: "Add to queue" },
  ];

  const renderCell = ({ item }) => {
    return (
      <Pressable onPress={() => addSongToQueue(item)} style={{ margin: 16 }}>
        <ImageBox width={120} source={{ uri: item.artwork }} />
        <Text style={{ color: "#fff" }}>{item.title}</Text>
      </Pressable>
    );
  };

  return (
    <SafeAreaView>
      <FlatList
        data={songs}
        numColumns={3}
        keyExtractor={(item, index) => item.id}
        renderItem={renderCell}
        columnWrapperStyle={{
          flex: 1,
          justifyContent: "space-around",
        }}
      />
    </SafeAreaView>
  );
};

export default MusicDemoScreen;
