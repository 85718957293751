import * as React from "react";
import { Text, StyleSheet } from "react-native";
import { Theme } from "@app/ui";

interface Props {}

export default (props) => {
  return (
    <Text {...props} style={[styles.text, props.style]}>
      {props.children}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    color: Theme.colors.text,
    fontFamily: Theme.fonts.titles.regular,
    fontSize: 14,
  },
});
