"use strict";
import React, { Component, useEffect, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Linking,
  Dimensions,
  SafeAreaView,
} from "react-native";
import ConversationRow from "@app/chat/components/conversation-row";
import { BoxResponsive } from "@app/ui";
import { getConversations } from "@app/chat/services/data";

interface Props {
  user?: Object;
}

export interface ConversationLastMessage {
  id: any;
  text: string;
  date: any;
}

export interface ConversationItem {
  id: any;
  title: string;
  lastMessage?: ConversationLastMessage;
  unread: Number;
  avatar: string;
}

const ConversationsList = (props: Props) => {
  const [conversations, setConversations] = useState<ConversationItem[]>([
    {
      id: 1,
      title: "Groovr",
      lastMessage: {
        id: 1,
        text: "Santi, welcome to Groovr",
        date: Date.now(),
      },
      unread: 1,
      avatar: "https://placeimg.com/140/140/any",
    },
    {
      id: 2,
      title: "Another user",
      lastMessage: {
        id: 1,
        text: "Yes, lets do the deal",
        date: Date.now(),
      },
      unread: 0,
      avatar: "https://placeimg.com/140/140/any",
    },
    {
      id: 2,
      title: "Guest user",
      lastMessage: {
        id: 3,
        text:
          "Ok this is an example of a very large text message to test the line breaks and the design and more and more haha how cool is that",
        date: Date.now(),
      },
      unread: 4,
      avatar: "https://placeimg.com/140/140/any",
    },
  ]);

  useEffect(() => {
    (async () => {
      const convs = await getConversations();
      setConversations(convs.data);
    })();
  }, []);

  const renderItem = ({ item, index, section }) => {
    return (
      <ConversationRow
        key={"chat-row-" + item.id}
        id={item.id}
        title={item.title}
        lastMessage={item.lastMessage}
        avatar={item.avatar}
        unread={item.unread}
      />
    );
  };

  return (
    <FlatList
      data={conversations}
      renderItem={renderItem}
      style={{ flex: 1 }}
    />
  );
};

export default ConversationsList;
