import { instance, configure, clear } from "@app/config/api/api";

export function getNews(page: number, params: Object = {}): any {
  const data = { ...{ page }, ...params };
  return instance
    .request<ServerData>({
      url: "news",
      params: data,
    })
    .catch((e) => console.error(e));
}

export function getNewsStory(id: number): any {
  return instance
    .request<ServerData>({
      url: "news/story/" + id,
    })
    .catch((e) => console.error(e));
}
