// withFetch.js
import React, { Component, useEffect, useState } from "react";
import { Text } from "react-native";
import { instance as api } from "@app/config/api/api";
import { View } from "react-native";

interface Props {
  children: any;
  path: string;
  params?: Object;
}

function apiData(props: Props) {
  const WithFetch = (WrappedComponent, { path, params }: Props) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
      if (path) fetchData(path, params);
    }, []);

    const fetchData = async (path: string, params?: Object) => {
      setIsLoading(true);
      setIsError(false);

      try {
        const response = await api.request({ url: path, params });
        if (response.data) {
          const data = response.data;

          setIsLoading(false);
          setData(data);
        } else {
          throw new Error("Fetch request error");
        }
      } catch (err) {
        setIsLoading(false);
        setIsError(err);
      }
    };

    return <Text style={{ color: "red" }}>asds</Text>;

    // @ts-ignore
    return (
      <WrappedComponent
        data={data}
        isLoading={isLoading}
        isError={isError}
        {...props}
        getData={(requestUrl: string) => fetchData(requestUrl)}
      />
    );
  };

  const xxx = React.cloneElement(props.children, {});

  return (
    <View>
      <xxx />
    </View>
  );

  //return <View></View>;

  return WithFetch(props.children, props);
}

export default apiData;
