"use strict";
import React, { Component } from "react";
import {
  AppRegistry,
  StyleSheet,
  View,
  SafeAreaView,
  Alert,
  Image,
  Platform,
  LayoutAnimation,
  UIManager,
  TouchableHighlight,
} from "react-native";
//import { Camera } from "expo-camera";
//import { getProductCode } from "@app/services/api";
import { find, map } from "lodash";
import { getProductCode } from "@app/games/services/data";
import { Text } from "@app/ui";

export default function() {
  return <View></View>
};

// const CustomLayoutSpring = {
//   duration: 250,
//   create: {
//     type: LayoutAnimation.Types.easeInEaseOut,
//     property: LayoutAnimation.Properties.scaleXY,
//   },
//   update: {
//     type: LayoutAnimation.Types.easeInEaseOut,
//     property: LayoutAnimation.Properties.scaleXY,
//   },
//   delete: {
//     type: LayoutAnimation.Types.easeInEaseOut,
//     property: LayoutAnimation.Properties.scaleXY,
//   },
// };

// interface Props {
//   componentId: string;
// }

// enum GameScanItemStatus {
//   Searching,
//   Found,
//   NotFound,
// }

// interface State {
//   items: GameScanItem[];
//   found: object[];
// }

// interface GameScanItem {
//   status: GameScanItemStatus;
//   game?: object;
// }

// class CameraCodeScannerScreen extends Component<Props, State> {
//   constructor(props) {
//     super(props);

//     if (Platform.OS == "android") {
//       UIManager.setLayoutAnimationEnabledExperimental &&
//         UIManager.setLayoutAnimationEnabledExperimental(true);
//     }

//     this.state = {
//       items: [],
//       found: [],
//     };
//     this.renderGamesFound = this.renderGamesFound.bind(this);
//     this.renderGame = this.renderGame.bind(this);
//   }

//   static options(passProps) {
//     return {
//       statusBar: {
//         style: "light",
//       },
//     };
//   }

//   findCode(code) {
//     if (!this.state[code]) {
//       this.setState({
//         [code]: {
//           status: GameScanItemStatus.Searching,
//         },
//       });

//       //console.warn('Searching Code ' + code + ' is on progress');
//       getProductCode(code)
//         .then((response) => {
//           //console.warn(response);
//           const items = response.data.data;
//           if (items.length) {
//             items.forEach((element) => {
//               this.setCodeFound(code, element);
//             });
//           } else {
//             this.setCodeNotFound(code);
//           }
//         })
//         .catch(() => {
//           this.setCodeNotFound(code);
//         });
//     } else {
//       //console.warn('Code ' + code + ' is on progress');
//     }
//   }

//   setCodeFound(code, data) {
//     //console.warn('Code ' + code + ' Found', { data });
//     this.setState({
//       found: [data, ...this.state.found],
//     });
//     this.setState({
//       [code]: {
//         status: GameScanItemStatus.Found,
//         game: data,
//       },
//     });
//   }

//   setCodeNotFound(code) {
//     console.warn("Code " + code + " NOT found");
//     Alert.alert("Code " + code + " NOT Found");
//     this.setState({
//       [code]: {
//         status: GameScanItemStatus.NotFound,
//       },
//     });
//   }

//   renderDragPane() {
//     return (
//       <View style={{ padding: 14, alignItems: "center", flex: 1 }}>
//         <View
//           style={{
//             height: 5,
//             width: 36,
//             backgroundColor: "#C4C4C6",
//             borderRadius: 5,
//           }}
//         ></View>
//       </View>
//     );
//   }

//   renderExplanation() {
//     return (
//       <View style={{ padding: 16, flexDirection: "row", width: "100%" }}>
//         <Image
//           source={require("@assets/icons/button_barcode.png")}
//           style={{ tintColor: "#ffffff", width: 24 }}
//         />
//         <View style={{ marginLeft: 8, flex: 1 }}>
//           <Text
//             style={{
//               color: "#fff",
//               //fontWeight: "bold",
//               fontSize: 17,
//               marginTop: -1,
//             }}
//           >
//             Scan Barcodes and QR
//           </Text>
//           <Text
//             style={{
//               color: "#fff",
//               opacity: 0.75,
//               fontSize: 14,
//               marginTop: 4,
//             }}
//           >
//             Place the camera in the barcodes, QR, or similars to find the
//             product
//           </Text>
//         </View>
//       </View>
//     );
//   }

//   renderGame(game) {
//     let cover = find(game.media, { type: "cover" });
//     LayoutAnimation.configureNext(CustomLayoutSpring);

//     return (
//       <RectButton onPress={() => openGame(undefined, game)}>
//         <View
//           style={{
//             width: 196,
//             height: 196,
//             backgroundColor: "rgba(255,255,255,.8)",
//             borderRadius: 8,
//             padding: 8,
//             elevation: 4,
//             marginRight: 8,
//           }}
//         >
//           {cover && (
//             <Image
//               source={{
//                 uri: cover.thumb_url,
//               }}
//               style={{
//                 flex: 1,
//                 borderRadius: 4,
//                 height: 96,
//                 width: 96,
//                 marginRight: 10,
//                 aspectRatio: cover.aspect_ratio,
//                 alignSelf: "center",
//                 margin: 8,
//               }}
//             />
//           )}
//           <Text style={{ textAlign: "center", fontWeight: "bold" }}>
//             {game.name}
//           </Text>
//         </View>
//       </RectButton>
//     );
//   }

//   renderGamesFound() {
//     return (
//       <View
//         style={{
//           //backgroundColor: 'rgba(255,255,255,.8)',
//           height: 196,
//           margin: 16,
//           flexDirection: "row",
//         }}
//       >
//         {map(this.state.found, this.renderGame)}
//       </View>
//     );
//   }

//   render() {
//     return (
//       <View style={styles.container}>
//         <SafeAreaView
//           style={{
//             //marginBottom: 16,
//             //backgroundColor: "red",
//             zIndex: 10,
//             flex: 1,
//             justifyContent: "flex-end",
//           }}
//         >
//           {Platform.OS === "ios" && this.renderDragPane()}
//           {this.state.found && this.state.found.length
//             ? this.renderGamesFound()
//             : this.renderExplanation()}
//         </SafeAreaView>

//         <Camera
//           ref={(ref) => {
//             this.camera = ref;
//           }}
//           autoFocus={Camera.Constants.AutoFocus.on}
//           focusDepth={0.5}
//           //captureAudio={false}
//           style={styles.preview}
//           type={Camera.Constants.Type.back}
//           flashMode={Camera.Constants.FlashMode.on}
//           // androidCameraPermissionOptions={{
//           //   title: "Permission to use camera",
//           //   message: "We need your permission to use your camera",
//           //   buttonPositive: "Ok",
//           //   buttonNegative: "Cancel",
//           // }}
//           //defaultVideoQuality={CameraConstants.VideoQuality["480p"]}
//           // googleVisionBarcodeMode={'alternate'}
//           //onCameraReady={() => Alert.alert("ready")}
//           //   androidRecordAudioPermissionOptions={{
//           //     title: 'Permission to use audio recording',
//           //     message: 'We need your permission to use your audio',
//           //     buttonPositive: 'Ok',
//           //     buttonNegative: 'Cancel',
//           //   }}
//           onBarCodeScanned={(result) => {
//             if (result.data) {
//               this.findCode(result.data);
//               //console.warn(data);
//             }
//           }}
//           // onGoogleVisionBarcodesDetected={({ barcodes }) => {
//           //   if (barcodes.length) {
//           //     console.warn(barcodes);
//           //     //console.warn(barcodes);
//           //     barcodes.forEach((code) => {
//           //       this.findCode(code.data);
//           //     });
//           //   }
//           // }}
//         />
//       </View>
//     );
//   }

//   takePicture = async function () {
//     if (this.camera) {
//       const options = { quality: 0.5, base64: true };
//       const data = await this.camera.takePictureAsync(options);
//       console.log(data.uri);
//     }
//   };
// }

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     flexDirection: "column",
//     backgroundColor: "black",
//     borderColor: "red",
//     borderWidth: 0,
//   },
//   preview: {
//     //flex: 1,
//     position: "absolute",
//     left: 0,
//     right: 0,
//     top: 0,
//     bottom: 0,
//     //justifyContent: "flex-end",
//     //alignItems: "center",
//     borderColor: "blue",
//     borderWidth: 0,
//   },
//   capture: {
//     flex: 0,
//     backgroundColor: "#fff",
//     borderRadius: 5,
//     padding: 15,
//     paddingHorizontal: 20,
//     alignSelf: "center",
//     margin: 20,
//   },
// });

// export default CameraCodeScannerScreen;

// //AppRegistry.registerComponent('BadInstagramCloneApp', () => BadInstagramCloneApp);
