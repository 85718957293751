import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";
import { useQueryClient } from "react-query";

const StoreKey = "auth-token";

export enum AuthManagerError {
  NoCredentialsStored = "No credentials stored on device",
  NoPermissions = "No permissions or lack of entitlements",
}

export interface KeychainData {
  service: string;
  username: string;
  password: string;
}

export default class AuthManager {
  private static instance: AuthManager;
  static getInstance() {
    if (!AuthManager.instance) {
      AuthManager.instance = new AuthManager();
    }

    return AuthManager.instance;
  }

  async logout() {
    try {
      useQueryClient().invalidateQueries();
    } catch (e) {}

    if (Platform.OS === "web") {
      return window.localStorage.removeItem(StoreKey);
    } else {
      return SecureStore.deleteItemAsync(StoreKey);
    }
  }

  async saveCredentials(token: string) {
    //console.warn("Saving token " + token);

    if (Platform.OS === "web") {
      window.localStorage.setItem(StoreKey, token);
    } else {
      return SecureStore.setItemAsync(StoreKey, token);
    }
  }

  async getAuthenticationToken() {
    if (Platform.OS === "web") {
      return window.localStorage.getItem(StoreKey);
    } else {
      const password = await SecureStore.getItemAsync(StoreKey);
      //console.warn("Getting token " + password);
      return password;
    }
  }
}
