import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Platform, View } from "react-native";
import { BlurTint, BlurView } from "expo-blur";

interface Props {
  tint?: "dark" | "light" | "auto";
  intensity?: number;
  children?: any;
  style?: any;
}

export default ({ tint = "auto", intensity = 100, children, style }: Props) => {
  const finalTint: BlurTint = tint === "auto" ? "dark" : tint;
  if (Platform.OS === "ios") {
    return (
      <BlurView tint={finalTint} intensity={intensity} style={style}>
        {children}
      </BlurView>
    );
  } else {
    return <View style={[style]}>{children}</View>;
  }
};
