import * as React from "react";
import {
  ScrollView,
  View,
  RefreshControl,
  FlatList,
  Pressable,
  SectionList,
  Alert,
  Modal,
  Platform,
  Dimensions,
} from "react-native";
import {
  Text,
  Image,
  Theme,
  Button,
  ScaledButton,
  TurboSymbol,
  ImageBox,
} from "@app/ui";
import { find } from "lodash";
import { useState } from "react";

export default (props) => {
  const imageUrl =
    "https://turbo-killer-dev-assets.s3.eu-central-1.wasabisys.com/assets/podcasts_chapters/B/4/5/D/8/w/2/A/l/G/Y/cover-18.jpg?24a03cdd531f9e972c45a9e31c02c096";

  const renderHeader = () => {
    const styleText = {
      fontSize: 13,
      fontFamily: Theme.fonts.titles.medium,
      lineHeight: 20,
    };

    return (
      <View
        style={{
          height: 40,
          paddingHorizontal: 20,
          //alignItems: "left",
          alignContent: "center",
          flexDirection: "row",
        }}
      >
        <Image
          source={{ uri: imageUrl }}
          style={{ width: 32, height: 32, borderRadius: 16, marginRight: 16 }}
        />
        <Text style={[styleText, { marginTop: 4 }]}>Pastis y Buenri</Text>
      </View>
    );
  };

  const renderPost = () => {
    return (
      <ImageBox
        source={{
          uri: imageUrl,
        }}
        aspectRatio={1}
        borderWidth={0}
      ></ImageBox>
    );
  };

  const renderActions = () => {
    const iconSize = 24;
    return (
      <View
        style={{
          //height: 54,
          flexDirection: "row",
          marginHorizontal: 20,
          marginVertical: 16,
        }}
      >
        <ScaledButton>
          <TurboSymbol
            name={"heart-solid"}
            color={"#fff"}
            size={iconSize}
            style={{ marginRight: 16 }}
          />
        </ScaledButton>
        <ScaledButton>
          <TurboSymbol
            name={"messenger-solid"}
            color={"#fff"}
            size={iconSize}
            style={{ marginRight: 16 }}
          />
        </ScaledButton>
        <ScaledButton>
          <TurboSymbol
            name={"messenger-send-solid"}
            color={"#fff"}
            size={iconSize}
            style={{ marginRight: 16 }}
          />
        </ScaledButton>
      </View>
    );
  };

  const renderDescription = () => {
    return (
      <View style={{ marginHorizontal: 20 }}>
        <Text
          style={{
            fontSize: 13,
            opacity: 0.75,
            fontFamily: Theme.fonts.titles.regular,
            marginVertical: 0,
            lineHeight: 16,
          }}
        >
          Pastis y buenri son los mejores djs de Makina de la historia con sede
          en Barcelona y repercusion en todo España y el sud de Europa.
        </Text>
      </View>
    );
  };

  return (
    <View style={{ marginBottom: 32 }}>
      {renderHeader()}
      {renderPost()}
      {renderActions()}
      {renderDescription()}
    </View>
  );
};
