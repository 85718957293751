"use strict";
import React, { Component, ReactNode } from "react";
import { Text, Platform, Image, ImageSourcePropType } from "react-native";
import { RectButton } from "react-native-gesture-handler";
import { Theme, TurboSymbol } from "@app/ui";

export enum HeaderButtonMode {
  Default = 0,
  Toggle = 1,
}

interface Props {
  children?: ReactNode;
  onPress: any; //Function;
  text?: String;
  isLoading?: boolean;
  backgroundColor?: String;
  icon?: ImageSourcePropType;
  symbol?: String;
  mode?: HeaderButtonMode;
}

const HeaderButton: React.FC = ({
  text,
  icon,
  symbol,
  onPress,
  mode,
}: Props) => {
  const buttonMode = mode || HeaderButtonMode.Default;

  return (
    <RectButton
      onPress={onPress}
      rippleColor={Theme.colors.text}
      underlayColor={Theme.colors.text}
      //hitSlop={}
    >
      {text && !icon && (
        <Text
          style={{
            color: Theme.colors.text,
            fontFamily: Theme.font.regular,
            fontSize: 17,
          }}
        >
          {text}
        </Text>
      )}
      {symbol && <TurboSymbol name={symbol} color={"#fff"} size={24} />}
      {icon && (
        <Image
          source={icon}
          style={{
            tintColor: "#fff",
            resizeMode: "contain",
            margin: 0,
            ...Platform.select({
              ios: {
                height: 24,
              },
              android: {
                height: 24,
              },
              // web: {
              //   height: 24,
              // },
            }),
          }}
        />
      )}
    </RectButton>
  );
};

export default HeaderButton;
