import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import { View } from "react-native";
import {
  DebugShortcuts,
  PageHeader,
  PagePost,
  WelcomeHeader,
} from "@app/pages";
import { PodcastChapterList, PodcastHeader } from "@app/music";
import { StreamItem } from "@app/stream";

const mapping = {
  "debug.shortcuts": DebugShortcuts,
  "welcome.header": WelcomeHeader,
  "page.header": PageHeader,
  "page.post": PagePost,
  "podcast.header": PodcastHeader,
  "podcast.chapter.items": PodcastChapterList,
  "stream.live": StreamItem,
};

const renderItemByName = (name: string, data: Array<any>, moreProps?: any) => {
  const item = mapping[name] || View;

  const component = React.createElement(item, {
    ...data,
    ...moreProps,
  });

  return component;
};

export { renderItemByName };
