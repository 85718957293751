import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Alert, FlatList, Platform, Pressable, View } from "react-native";
import {
  Actionable,
  BoxResponsive,
  ImageBox,
  ScaledButton,
  Text,
  Theme,
  TurboSymbol,
} from "@app/ui";
import { find } from "lodash";
import { formatRelative } from "date-fns";
import { Track } from "@app/music/models/track";
import { addTrack } from "@app/music/services/player";
import { PlayButton } from "@app/music";
import format from "format-duration";
import { addSongToQueue } from "@app/music/components/play_button";
import { usePlayerStatus } from "@app/user/hooks/user";
import { useIsLiked } from "@app/social/hooks/like";
import { useIsLargeScreen } from "@app/utils/hooks/screen_dimensions";

enum PodcastItemLayoutType {
  Compact = 1,
  Right = 2,
  Left = 3,
}

export default ({ item }) => {
  const playerStatus = usePlayerStatus();
  const [layoutType, setLayoutType] = useState<PodcastItemLayoutType>(
    PodcastItemLayoutType.Compact
  );

  const coverImage = find(item?.media, { type: "cover" });
  const stream = item.av_media?.length && item.av_media[0].streams[0];
  const duration = item.av_media[0]?.media?.duration;
  const durationFormatted = useMemo(() => {
    return duration
      ? format(duration * 1000, {
          leading: true,
        })
      : null;
  }, [duration]);
  const dateFormatted = useMemo(() => {
    return formatRelative(new Date(item.published_at), new Date(), {
      addSuffix: true,
    });
  }, [item.published_at]);

  const isLargeScreen = useIsLargeScreen();
  const withDescription = isLargeScreen; //item.description != undefined && item.description != "";

  const isLiked = false;
  useIsLiked("podcasts_chapters", item.id, false);
  // console.warn(
  //   "track " + item.id,
  //   playerStatus.playback?.currentTrack?.entity_id
  // );

  const track: Track = useMemo(() => {
    return stream
      ? {
          //id: stream.uuid,
          uuid: stream.uuid,
          url: stream.content.url,
          artist: item.artist || "Artist",
          album: item.name || "Album",
          title: item.name || "Title",
          artwork: coverImage?.url,
          appearance: { colors: coverImage?.colors },
          duration: duration,
          entity_type: "podcasts_chapters",
          entity_id: item.id,
        }
      : null;
  }, [stream]);

  const isCurrentTrack =
    playerStatus.playback?.currentTrack?.uuid === track?.uuid;

  const textColor = isCurrentTrack
    ? playerStatus.appearance.colors.text
    : Theme.colors.text;

  const buttonPressed = () => {
    addSongToQueue(track);
  };

  const renderContent = () => {
    return (
      <View
        style={{
          paddingVertical: 8,
          paddingHorizontal: 20,
          flexDirection: "row",
          flex: 1,
          flexGrow: 1,
        }}
      >
        <View
          style={{
            marginRight: 8,
            flex: 1,
            flexGrow: 1,
            //backgroundColor: isCurrentTrack ? "red" : null,
          }}
        >
          <View style={{ flexDirection: "row", marginBottom: 0 }}>
            {/*<PlayButton track={track} />*/}
            {layoutType === PodcastItemLayoutType.Compact && (
              <View style={{ marginRight: 16 }}>
                {coverImage ? (
                  <ImageBox
                    source={{ uri: coverImage.url }}
                    width={44}
                    aspectRatio={1}
                    borderRadius={4}
                  />
                ) : (
                  <View
                    style={{
                      width: 44,
                      height: 44,
                      backgroundColor: "#444",
                      borderRadius: 4,
                    }}
                  ></View>
                )}
              </View>
            )}
            <View
              style={{
                justifyContent: "center",
                //flexGrow: 1,
                marginRight: 16,
                flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  lineHeight: 18,
                  fontFamily: Theme.fonts.titles.medium,
                  paddingTop: 2,
                  color: textColor,
                }}
              >
                {isLiked?.data?.is_liked && (
                  <>
                    <TurboSymbol name={"heart-solid"} color={"red"} size={11} />
                    {"  "}
                  </>
                )}
                {item.name || "Episode " + item.number}
              </Text>
              <View style={{ flexDirection: "row" }}>
                {duration && (
                  <>
                    <Text
                      style={{
                        fontSize: 12,
                        fontFamily: Theme.fonts.titles.regular,
                        opacity: 0.5,
                        marginTop: 4,
                        color: textColor,
                      }}
                    >
                      {durationFormatted}
                    </Text>
                  </>
                )}
                {item.published_at && (
                  <>
                    <Text
                      style={{
                        fontFamily: Theme.fonts.titles.medium,
                        lineHeight: 20,
                        color: textColor,
                      }}
                    >
                      ・
                    </Text>
                    <Text
                      style={{
                        fontSize: 12,
                        fontFamily: Theme.fonts.titles.regular,
                        opacity: 0.5,
                        marginTop: 4,
                        color: textColor,
                      }}
                    >
                      {dateFormatted}
                    </Text>
                  </>
                )}
              </View>
            </View>
          </View>
          {withDescription && (
            <Text
              numberOfLines={2}
              style={{
                fontSize: 13,
                lineHeight: 16,
                fontFamily: Theme.fonts.titles.regular,
                opacity: 0.5,
                marginTop: 8,
                marginRight: 8,
                color: textColor,
              }}
            >
              {item.description}
            </Text>
          )}
        </View>
        {layoutType == PodcastItemLayoutType.Right && (
          <ImageBox
            source={{ uri: coverImage?.url }}
            width={64}
            aspectRatio={1}
            borderRadius={4}
          />
        )}
      </View>
    );
  };

  return (
    <Actionable
      onPress={buttonPressed}
      style={[
        isCurrentTrack
          ? { backgroundColor: track?.appearance?.colors?.tertiary || "blue" }
          : null,
        ,
        isLargeScreen ? { borderRadius: 12, marginHorizontal: 12 } : null,
      ]}
    >
      {renderContent()}
    </Actionable>
  );
};
