import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View, Platform } from "react-native";
import { ImageBox, ScaledButton, Text, Theme } from "@app/ui";
import {
  itemSizeForColumns,
  useIsLargeScreen,
  useNumberOfColumnsForFullScreen,
} from "@app/utils/hooks/screen_dimensions";
import { useNavigation } from "@react-navigation/native";
import { usePlayerStatus } from "@app/user/hooks/user";

interface Props {
  item: any;
  itemSize?: number;
  fontSize?: number;
  itemPadding?: number;
}

export default (props: Props) => {
  const navigation = useNavigation();
  const item = props.item;
  const cover = item.media[0];
  const name = item.name || "Podcast";
  const isLargeScreen = useIsLargeScreen();
  const itemSize = itemSizeForColumns(3);
  const preferedSize = isLargeScreen ? 220 : itemSize;

  //const itemSize = props.itemSize || preferedSize;
  const numberOfColumns = useNumberOfColumnsForFullScreen(itemSize);
  const playerStatus = usePlayerStatus();
  // const itemSize2 = itemSizeForColumns(2);
  // const itemSize3 = itemSizeForColumns(3);
  // const itemSize4 = itemSizeForColumns(4);
  const coverRadius = isLargeScreen ? 12 : 8;
  const imageBoxBorderWidth = 0.5;

  return (
    <ScaledButton
      pointerMode={"highlight"}
      key={"podcast-" + item.id}
      containerStyle={{ flex: 1 }}
      style={{
        //display: "flex",
        //flex: 1 / numberOfColumns,
        //flexGrow: 1,
        //
        marginBottom: 3,
        width: itemSize,
        //backgroundColor: "red",
        ...Platform.select({
          native: {
            flexBasis: 100 / numberOfColumns + "%",
            padding: props.itemPadding || 8,
          },
          web: {
            margin: 8,
          },
        }),
      }}
      onPress={() => {
        navigation.push("Podcast", { id: item.id });
      }}
      actions={[
        { title: "Play Now", systemIcon: "play.circle.fill" },
        { title: "Add to queue", systemIcon: "add-queue" },
        { title: "Add to favorites", systemIcon: "star" },
      ]}
    >
      {cover ? (
        <ImageBox
          source={{ uri: cover?.url }}
          backgroundColor={cover?.colors?.primary}
          borderRadius={coverRadius}
          borderWidth={imageBoxBorderWidth}
        />
      ) : (
        <View
          style={{
            aspectRatio: 1,
            backgroundColor: "#444",
            borderRadius: coverRadius,
          }}
        ></View>
      )}
      <View style={{ marginVertical: 8 }}>
        <Text
          style={{
            fontFamily: Theme.fonts.titles.medium,
            fontSize: props.fontSize || 13,
            lineHeight: 16,
          }}
          numberOfLines={2}
        >
          {item.name}
        </Text>
        {item.artist && (
          <Text
            style={{
              fontFamily: Theme.fonts.titles.regular,
              opacity: 0.7,
              marginTop: 3,
              fontSize: props.fontSize || 12,
              lineHeight: 15,
            }}
          >
            {item.artist || "Artist"}
          </Text>
        )}
      </View>
    </ScaledButton>
  );
};
