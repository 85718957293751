import * as React from "react";
import { Text, ScrollView, View, Platform } from "react-native";
import { SidebarButton, SidebarHeader } from "@app/ui";
import { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { NavigationContext } from "@react-navigation/native";
import { navigationRef } from "@app/config/navigation/navigate";
import { useAccessToken, useAuthenticatedUser } from "@app/user";

interface SidebarButtonsTypes {
  text: string;
}

const SidebarContainer = (props) => {
  return (
    <View {...props} style={{ marginVertical: 16 }}>
      {props.children}
    </View>
  );
};

export default () => {
  const [selectedTab, setSelectedTab] = useState("Home");
  const authenticatedUser = useAuthenticatedUser();
  const accessToken = useAccessToken();

  return (
    <>
      <ScrollView
        style={{
          paddingHorizontal: 16,
          ...Platform.select({
            web: {
              WebkitUserSelect: "none",
              WebkitAppRegion: "drag",
            },
          }),
        }}
      >
        <SidebarContainer>
          <SidebarHeader text="Activity" size="normal" />
        </SidebarContainer>
      </ScrollView>
    </>
  );
};
