// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { Dimensions } from "react-native";

export const useDeviceOrientation = () => {
  const [deviceOrientation, setDeviceOrientation] = useState(null);

  useEffect(() => {
    function updateState() {
      const { height, width } = Dimensions.get("window");
      if (height >= width) {
        setDeviceOrientation("portrait");
      } else {
        setDeviceOrientation("landscape");
      }
    }

    updateState(); // for initial render
    Dimensions.addEventListener("change", updateState);
    return () => Dimensions.removeEventListener("change", updateState);
  }, []);

  return deviceOrientation;
};

export const useFullScreenDimensions = () => {
  const [screenData, setScreenData] = useState(Dimensions.get("screen"));

  useEffect(() => {
    const onChange = (result) => {
      if (
        result.window.width != screenData.width ||
        result.window.height != screenData.height
      ) {
        setScreenData(result.window);
      }
    };

    Dimensions.addEventListener("change", onChange);
  });

  return screenData;
};

export const useScreenDimensions = () => {
  const [screenData, setScreenData] = useState(Dimensions.get("window"));

  useEffect(() => {
    const onChange = (result) => {
      if (
        result.window.width != screenData.width ||
        result.window.height != screenData.height
      ) {
        setScreenData(result.window);
      }
    };

    Dimensions.addEventListener("change", onChange);
  });

  return screenData;
};

export const useContentMaxWidth = () => {
  return 620;
};

export const useContentCompactMaxWidth = () => {
  return 480;
};

export const useLeftBarWidth = () => {
  return useIsLargeScreen() ? 220 : 0;
};

export const useContentContainerWidth = (maxWidth?: number) => {
  return useScreenDimensions().width - useLeftBarWidth();
};

export const useContentWidth = (maxWidth?: number) => {
  const maxSize = maxWidth || useContentMaxWidth();
  const [contentSize, setContentSize] = useState(
    Math.min(maxSize, Dimensions.get("window").width)
  );

  useEffect(() => {
    const onChange = (result) => {
      if (result.window.width !== contentSize.width) {
        setContentSize(Math.min(maxSize, result.window.width));
      }
    };

    Dimensions.addEventListener("change", onChange);
  });

  return contentSize;
};

export const useContentCompactWidth = () => {
  return useContentWidth(useContentCompactMaxWidth());
};

export const itemSizeForColumns = (
  columns: number,
  defaultSize: number = 200
): number => {
  const contentWidth: number = useContentWidth();
  const contentWithMargins = contentWidth - 20 * 2;
  const itemSize: number =
    contentWithMargins < 422 ? contentWithMargins / columns : defaultSize;
  return itemSize;
};

export const useNumberOfColumnsForContent = (columnWidth: number) => {
  const cols = Math.floor(useContentWidth() / columnWidth);

  return cols;
};

export const useNumberOfColumnsForFullScreen = (columnWidth?: number) => {
  const width = columnWidth || useContentMaxWidth();

  const cols = Math.floor(useContentContainerWidth() / width);

  return cols;
};

export const useIsMobile = () => {
  return useScreenDimensions().width <= 810;
};

export const useIsLargeScreen = () => {
  return useScreenDimensions().width > 810;
};

export const useIsFullScreen = () => {
  return useScreenDimensions().width > 1200;
};

export const useLeftMenu = () => {
  return useIsLargeScreen();
};

export const useRightMenu = () => {
  return __DEV__ && useScreenDimensions().width > 1000;
};

export interface ScreenProperties {}

export const useScreenProperties: ScreenProperties = () => {
  return {
    screen: {
      full: useIsFullScreen(),
      large: useIsLargeScreen(),
      mobile: useIsMobile(),
    },
    menu: {
      left: useLeftMenu(),
      sideBarWidth: useLeftBarWidth(),
      right: useRightMenu(),
    },
    content: {
      width: useContentWidth(),
      containerWidth: useContentContainerWidth(),
    },
  };
};
