"use strict";
import React, { Component, useCallback, useEffect, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Linking,
  Dimensions,
  SafeAreaView,
} from "react-native";
import ConversationRow from "@app/chat/components/conversation-row";
import {
  Bubble,
  GiftedChat,
  InputToolbar,
  Send,
} from "react-native-gifted-chat";
import { useNavigation } from "@react-navigation/native";
import { BoxResponsive, Theme } from "@app/ui";
import FontAwesome5Pro from "react-native-vector-icons/FontAwesome5Pro";

interface Props {
  user?: Object;
}

const ConversationsListScreen = (props: Props) => {
  const [messages, setMessages] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);

  const navigation = useNavigation();
  //ånavigation.setOptions({ title: "Chat" });

  useEffect(() => {
    setMessages([
      {
        _id: 1,
        text: "Hello developer",
        createdAt: new Date(),
        user: {
          _id: 2,
          name: "React Native",
          avatar: "https://placeimg.com/140/140/any",
        },
      },
    ]);
  }, []);

  const onSend = useCallback((messages = []) => {
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );
  }, []);

  const renderInputToolbar = (props) => {
    return (
      <InputToolbar
        {...props}
        containerStyle={{
          backgroundColor: Theme.colors.background,
          borderTopWidth: 0,
          minHeight: 44,
          //paddingBottom: 4,
          paddingTop: 0.5,
          paddingBottom: 0.5,
        }}
        primaryStyle={{
          //borderWidth: 0.5,
          // borderColor: inputFocused
          //   ? Theme.colors.text
          //   : Theme.colors.chromeHigh,
          margin: 4,
          paddingLeft: 10,
          paddingRight: 2,
          color: Theme.colors.text,
        }}
        accessoryStyle={
          {
            //  backgroundColor: "red",
          }
        }
      />
    );
  };

  const renderSend = (props) => {
    const buttonSize = 42;
    return (
      <Send
        {...props}
        containerStyle={{ backgroundColor: "transparent", padding: 0 }}
      >
        <View
          style={{
            backgroundColor: Theme.colors.primary,
            margin: 0,
            paddingVertical: 10,
            paddingHorizontal: 32,
            height: buttonSize,
            width: buttonSize,
            borderRadius: 4,
            alignItems: "center",
          }}
        >
          <FontAwesome5Pro
            name={"paper-plane"}
            solid
            size={20}
            color={Theme.colors.text}
          />
        </View>
      </Send>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <GiftedChat
        messages={messages}
        onSend={(messages) => onSend(messages)}
        user={{
          _id: 1,
        }}
        renderInputToolbar={renderInputToolbar}
        textInputProps={{
          onFocus: () => setInputFocused(true),
          onBlur: () => setInputFocused(false),
        }}
        textInputStyle={{
          backgroundColor: Theme.colors.chromeHigh,
          color: "#fff",
          fontFamily: Theme.fonts.titles.regular,
          fontSize: 15,
          //fontSize: 17,
          borderRadius: 4,

          lineHeight: 20,
          paddingVertical: 12,
          paddingHorizontal: 16,
        }}
        renderSend={renderSend}
        renderBubble={(props) => {
          return (
            <Bubble
              {...props}
              textStyle={{
                left: { fontFamily: Theme.fonts.titles.regular, fontSize: 15 },
                right: { fontFamily: Theme.fonts.titles.regular, fontSize: 15 },
              }}
            />
          );
        }}
      />
    </View>
  );
};

export default ConversationsListScreen;
