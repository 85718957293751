import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";

const VolumeSlider = ({ width }) => {
  return <View></View>;
};

const styles = StyleSheet.create({
  // Seek Bar
  seekBarContainer: {
    height: 64, //24
    marginTop: 8,
  },

  seekBarThumb: {
    // position: "absolute",
    // backgroundColor: "#8E8E93",
    // top: -2,
    // borderRadius: 6,
    // width: 6,
    // height: 6,
  },

  seekBarTrack: {
    backgroundColor: "#DDDEDD",
    height: 2,
    borderRadius: 4,
  },

  seekBarTimingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: -6,
  },

  seekBarTimingText: {
    marginTop: 5,
    fontSize: 13,
    lineHeight: 13,
    fontWeight: "500",
    color: "#8E8E93",
  },
});

export default VolumeSlider;
