import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Alert, ScrollView, View } from "react-native";
import { instance, useApiRequest } from "@app/config/api/api";
import {
  BoxResponsive,
  Button,
  CellGroup,
  CellItem,
  FormRow,
  SelectInput,
  Text,
  TextInput,
  Theme,
} from "@app/ui";
import { Formik } from "formik";
import * as yup from "yup";
import { SelectInputItem } from "@app/ui/forms/select_input";
import { StackActions, useNavigation } from "@react-navigation/native";

const validationSchema = yup.object().shape({
  name: yup.string().required().label("Name").min(4).max(64).nullable(),
  category: yup.string().required().label("Category").nullable(),
});

interface Props {}

export default (props: Props) => {
  const navigation = useNavigation();

  const pageTypes: SelectInputItem[] = [
    { id: "page", name: "Page" },
    { id: "artist", name: "Artist" },
    { id: "place", name: "Place" },
  ];

  const publishForm = (data, actions) => {
    //console.warn("form", data);
    postCreate({ data })
      .then((response) => {
        actions.setSubmitting(false);
        navigation.dispatch(
          StackActions.replace("PageEditor", {
            id: response.data.data.id,
          })
        );
      })
      .catch((e) => {
        if (e.response.status === 422) {
          actions.setErrors(e.response.data?.errors);
        } else {
          Alert.alert("unknown error");
        }
        actions.setSubmitting(false);
      })
      .finally(() => {});
  };

  const [{ data: item, loading, error }, postCreate] = useApiRequest(
    {
      url: "pages/create",
      method: "POST",
    },
    { manual: true }
  );

  const renderForm = () => {
    const textStyle = {
      color: "#fff",
      backgroundColor: Theme.colors.chrome,
      borderWidth: 1,
      borderColor: Theme.colors.chromeHigh,
      borderRadius: 4,
      paddingHorizontal: 12,
      paddingVertical: 8,
      fontFamily: Theme.fonts.titles.regular,
    };

    return (
      <Formik
        initialValues={{ name: "", category: null }}
        onSubmit={(values, actions) => publishForm(values, actions)}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <View style={{ margin: 20 }}>
            <FormRow>
              <TextInput
                name="name"
                placeholder="Name"
                formik={formik}
                value={formik.values.name}
              />
            </FormRow>
            <FormRow>
              <SelectInput
                name="category"
                placeholder="Category"
                formik={formik}
                items={pageTypes}
                value={formik.values.category}
              />
            </FormRow>
            <FormRow>
              <Button
                text="Create"
                disabled={!formik.isValid}
                onPress={formik.handleSubmit}
                isLoading={formik.isSubmitting}
              />
            </FormRow>
          </View>
        )}
      </Formik>
    );
  };

  return (
    <ScrollView>
      <BoxResponsive>{renderForm()}</BoxResponsive>
    </ScrollView>
  );
};
