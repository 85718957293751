"use strict";
import React, { Component, ReactNode } from "react";
import {
  Platform,
  Text,
  View,
  ActivityIndicator,
  UIManager,
  LayoutAnimation,
} from "react-native";
const SpinKit = Platform.OS !== "web" ? require("react-native-spinkit") : null;

interface Props {
  isVisible?: boolean;
  size?: "small" | "large";
  marginSize?: "none" | "small" | "large";
  type?: string; //SpinnerType;
  color?: string;
  opacity?: number;
  style?: any;
}

interface State {}

class Spinner extends Component<Props, State> {
  static defaultProps: Props = {
    isVisible: true,
    size: Platform.OS === "web" ? "large" : "small",
    marginSize: "none",
    type: "WanderingCubes", //Bounce
    color: "#ffffff",
    opacity: 1,
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    const marginSizes = {
      none: 0,
      small: 32,
      large: 64,
    };

    const sizes = {
      small: 32,
      large: 52,
    };

    return (
      <View
        style={[
          this.props.style,
          {
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
            alignSelf: "center",
            opacity: this.props.opacity,
            margin: marginSizes[this.props.marginSize],
          },
        ]}
      >
        {Platform.OS === "web" ? (
          <ActivityIndicator
            hidesWhenStopped={true}
            animating={this.props.isVisible}
            size={this.props.size}
            color={this.props.color}
          />
        ) : (
          <SpinKit
            isVisible={this.props.isVisible}
            size={sizes[this.props.size]}
            type={this.props.type}
            color={this.props.color}
          />
        )}
      </View>
    );
  }
}

export default Spinner;
