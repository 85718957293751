import GamesListScreen from "./components/games_list";
import GameDetailScreen from "./components/game_detail";
import GamePlatformsListScreen from "./components/games_platforms";
import GamesCollectionsListScreen from "./components/games_collections_list";
import GamesCollectionsShow from "./components/collection_show";
import { TurboProvider, TurboScreen } from "@app/core/providers/ModuleProvider";

const Screens: TurboScreen[] = [
  {
    name: "GamePlatformList",
    component: GamePlatformsListScreen,
    path: "platforms",
  },
  { name: "GamesList", component: GamesListScreen, path: "games" },
  {
    name: "GameDetail",
    component: GameDetailScreen,
    path: "game/:id",
  },
  { name: "GameDetail", component: GameDetailScreen, options: { title: "" } },
  {
    name: "GamesCollectionsList",
    component: GamesCollectionsListScreen,
    options: { title: "Collections" },
    path: "collections",
  },
  {
    name: "GamesCollectionsShow",
    component: GamesCollectionsShow,
    options: { title: "Collection" },
    path: "collection/:id",
  },
];

const GamesProvider: TurboProvider = {
  screens: { screens: Screens },
  key: "games",
};

export {
  GamesListScreen,
  GameDetailScreen,
  GamePlatformsListScreen,
  GamesCollectionsListScreen,
  GamesCollectionsShow,
  GamesProvider,
};
