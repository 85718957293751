"use strict";
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  View,
  StyleSheet,
  Alert,
  SafeAreaView,
  Dimensions,
} from "react-native";
import { Button, HeaderButton, Theme } from "@app/ui";

const { width, height } = Dimensions.get("window");

const config = {
  cameraConfig: {
    cameraId: 1,
    cameraFrontMirror: false,
  },
  videoConfig: {
    preset: 4,
    bitrate: 2000000,
    profile: 2,
    fps: 30,
    videoFrontMirror: true,
  },
  audioConfig: {
    bitrate: 128000,
    profile: 1,
    samplerate: 44100,
  },
};

interface Props {}

const BroadcastScreen = (props: Props) => {
  return <View></View>;
};

const styles = StyleSheet.create({
  body: {
    backgroundColor: "#fff",
    ...StyleSheet.absoluteFill,
  },
  stream: {
    flex: 1,
  },
  footer: {
    backgroundColor: "rgba(0,0,0,.5)",
    position: "absolute",
    zIndex: 10,
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 8,
    paddingBottom: 48,
    paddingHorizontal: 64,
  },
});

export default BroadcastScreen;
