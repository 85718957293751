import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { Text } from "@app/ui";
import {
  useIsFullScreen,
  useIsLargeScreen,
} from "@app/utils/hooks/screen_dimensions";

interface Props {
  children?: React.ReactNode;
  sideComponent?: React.ReactNode;
  extendedContentComponent?: React.ReactNode;
}

export default (props: Props) => {
  const isLargeScreen = useIsLargeScreen();
  const isFullScreen = useIsFullScreen();
  const maxSidebarWidth = isFullScreen ? 480 : 360;

  const renderMini = () => {
    return props.children;
  };

  const renderBigSidebar = () => {
    return (
      <View
        style={{
          width: "30%",
          maxWidth: maxSidebarWidth,
          flex: 1,
          //backgroundColor: "green",
        }}
      >
        {props.sideComponent}
      </View>
    );
  };

  const renderBig = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          //backgroundColor: "blue"
        }}
      >
        {props.sideComponent && renderBigSidebar()}
        <View
          style={{
            flex: 1,
            flexGrow: 1,
            //backgroundColor: "blue"
          }}
        >
          {props.extendedContentComponent || props.children}
        </View>
      </View>
    );
  };

  return isLargeScreen ? renderBig() : renderMini();
};
