import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { Alert, Platform, View } from "react-native";
import * as ScreenOrientation from "expo-screen-orientation";
import {
  Orientation,
  OrientationLock,
  PlatformOrientationInfo,
} from "expo-screen-orientation";
import { useIsLargeScreen } from "@app/utils/hooks/screen_dimensions";
import { BackButton, HeaderBar } from "@app/ui";

const videoPackage = "expo-av";
const useExpoVideo = videoPackage === "expo-av";

let Video, ExpoVideo, AVPlaybackStatus;

if (useExpoVideo) {
  //import { Video, AVPlaybackStatus } from "expo-av";
  Video = require("expo-av").Video;
  AVPlaybackStatus = require("expo-av").AVPlaybackStatus;
} else {
  Video = require("expo-av").Video;
  AVPlaybackStatus = require("expo-av").AVPlaybackStatus;
  // Video = require("react-native-video").default;
}

interface Props {
  playback_id: string;
  demo: boolean;
}

const urlForVideo = (playbackID: string) => {
  return "https://stream.mux.com/" + playbackID + ".m3u8";
};

const posterForVideo = (playbackID: string) => {
  return "https://image.mux.com/" + playbackID + "/thumbnail.jpg";
};

export default ({ playback_id, route, navigation }: Props) => {
  const video = useRef(null);
  const [status, setStatus] = useState<AVPlaybackStatus>({});
  const [videoUrl, setVideoUrl] = useState<string>(
    urlForVideo(route.params.playback_id)
  );
  const isLargeScreen = useIsLargeScreen();
  const [isLive, setIsLive] = useState(true);
  const demo = route.params.demo || false;
  const [screenOrientation, setScreenOrientation] = useState<Orientation>();
  const [screenOrientationLock, setScreenOrientationLock] = useState<
    OrientationLock
  >();

  const [isLandscape, setIsLandscape] = useState<boolean>(false);

  useEffect(() => {
    setIsLandscape(
      (screenOrientation !== Orientation.LANDSCAPE_RIGHT) |
        Orientation.LANDSCAPE_LEFT
    );
  }, [screenOrientation]);

  useEffect(() => {
    ScreenOrientation.getOrientationAsync().then((orientation: Orientation) => {
      console.warn("orientation", orientation);
      setScreenOrientation(orientation);
    });
  }, []);

  useEffect(() => {
    ScreenOrientation.getOrientationLockAsync().then(
      (orientation: OrientationLock) => {
        //console.warn("orientation lock", orientation);
        setScreenOrientationLock(orientation);
      }
    );
  }, []);

  useEffect(() => {
    if (!demo) {
      setVideoUrl(urlForVideo(route.params.playback_id));
    }
  }, [route.params.playback_id]);

  if (demo) {
    const VideoSampleUrl =
      "https://turbo-killer-dev-assets.s3.wasabisys.com/live1.mp4";
    if (videoUrl !== VideoSampleUrl) {
      console.warn("Using demo");
      setVideoUrl(VideoSampleUrl);
    }
  }

  const renderVideo = () => {
    return (
      <Video
        ref={video}
        style={{ flex: 1 }}
        source={{
          uri: "https://turbo-killer-dev-assets.s3.wasabisys.com/live1.mp4",
        }}
        controls={true}
        useNativeControls={true}
        resizeMode="contain"
        isLooping={false}
        //poster={posterForVideo(route.params.playback_id)}
        // onPlaybackStatusUpdate={(status: AVPlaybackStatus) =>
        //   setStatus(() => status)
        // }
        shouldPlay={true}
      />
    );
  };

  const renderVideoWeb = () => {
    return (
      <Video
        ref={video}
        style={{ flex: 1 }}
        source={{
          uri: "https://turbo-killer-dev-assets.s3.wasabisys.com/live1.mp4",
        }}
        useNativeControls={true}
        resizeMode="contain"
        isLooping={false}
        posterSource={{ uri: posterForVideo(route.params.playback_id) }}
        shouldPlay={true}
      />
    );
  };

  const renderComments = () => {
    return <View></View>;
  };

  const renderControls = () => {
    return (
      <View
        style={{
          position: "absolute",
          zIndex: 10,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <HeaderBar
          leftBar={() => {
            return (
              <BackButton
                onPress={() => {
                  navigation.pop();
                }}
              />
            );
          }}
          rightBar={() => {
            return (
              <>
                <BackButton
                  symbol="debug-solid"
                  title="Lock"
                  onPress={() => {
                    ScreenOrientation.lockAsync(OrientationLock.LANDSCAPE)
                      .then(() => {
                        Alert.alert("shit");
                      })
                      .catch((e) => {
                        //console.warn("error orientaton", e);
                      });
                    // ScreenOrientation.lockPlatformAsync(
                    //   PlatformOrientationInfo.screenOrientationArrayIOS
                    // );
                  }}
                />
                <BackButton symbol="debug-solid" />
              </>
            );
          }}
        />
      </View>
    );
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#000" }}>
      {renderControls()}
      {useExpoVideo ? renderVideoWeb() : renderVideo()}
      {renderComments()}
    </View>
  );
};
