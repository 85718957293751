import initialState, {
  defaultAppearance,
  PlayerState,
} from "@app/config/redux/initialState";
import { superAppearance } from "@app/ui";
import { Platform } from "react-native";

export default function (state: PlayerState = initialState.player, action) {
  switch (action.type) {
    case "add_track":
      return {
        ...state,
        queue: [...state.queue, action.track],
      };
    case "current_track":
      return {
        ...state,
        playback: {
          ...state.playback,
          //previousTrack: previousTrack,
          currentTrack: action.track,
          //nextTrack: nextTrack,
        },
        appearance: action.track?.appearance
          ? superAppearance(action.track?.appearance)
          : superAppearance(defaultAppearance),
      };
    case "playback_state_change":
      //console.warn("state " + action.state);

      if (Platform.OS === "ios" && [State.Ready].includes(action.state)) {
        return state;
      }

      return {
        ...state,
        playback: {
          ...state.playback,
          state: action.state,
          isPlaying: false,
        },
      };
    case "queue_change":
      // let currentIndex2 = findIndex(state.queue, {
      //   id: state?.currentTrack?.id,
      // });
      //console.warn("CID ", state.playback.currentTrack?.id);
      // let currentIndex = findIndex(action.queue, {
      //   id: state.playback.currentTrack?.id,
      // });
      const currentIndex = action.index;
      let dataPlayback = {
        previousTrack: null,
        nextTrack: null,
      };
      dataPlayback.previousTrack = action.queue[currentIndex - 1] || null;
      dataPlayback.nextTrack = action.queue[currentIndex + 1] || null;

      // if (currentIndex > 0) {
      //   dataPlayback.previousTrack = action.queue[currentIndex - 1];
      // }
      //
      // if (currentIndex >= 0 && action.queue.length < currentIndex) {
      //   dataPlayback.nextTrack = action.queue[currentIndex + 1];
      // }

      return {
        ...state,
        playback: {
          ...state.playback,
          ...dataPlayback,
        },
        queue: action.queue,
      };
    default:
      return state;
  }
}
