import React, { Component, useState } from "react";
import { StyleSheet, Text, View, Alert } from "react-native";
import { WebView } from "react-native-webview";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
//import URI from "urijs";
import { Environment } from "@app/config";
import { useAccessToken } from "@app/user";
import { Spinner } from "@app/ui";

interface Props {
  url?: string;
  accessToken?: string;
}

interface State {
  url?: string;
  loading: boolean;
  loadingText: string;
  fadeSpeed: number;
}

// ...
const WebViewScreen = (props: Props) => {
  let instance: WebView;

  const [url, setUrl] = useState(props.route.params.url);
  const [loading, setLoading] = useState(true);
  const fadeSpeed = 300;
  const accessToken = useAccessToken();
  // constructor(props: Props) {
  //   super(props);
  //   this.onMessage = this.onMessage.bind(this);
  //
  //   this.state = {
  //     url: props.route.params.url,
  //     loading: true,
  //     loadingText: "Loading",
  //     fadeSpeed: 300,
  //   };
  //
  //   this.headers = this.headers.bind(this);
  // }
  //
  // navigationButtonPressed({ buttonId }) {
  //   if (buttonId === "reload") {
  //     this.instance?.reload();
  //   }
  // }

  // static getDerivedStateFromProps(nextProps) {
  //   if (nextProps.url) {
  //     return { url: nextProps.url };
  //   }
  //   return null;
  // }

  // isInternal(string: url) {
  //   //return url.endsWith('retrollector.com') || url.endsWith('thefunbots.com');
  // }

  const handleUrl = (url, extra, navState) => {
    if (url.indexOf(Environment.host) !== -1) {
      //this.openNew(url, extra);
    } else {
      //Linking.openURL(url).catch(err => console.error('An error occurred', err));
    }
  };

  const headers = (destination, accessToken) => {
    //const url = new URI(destination);

    let headers = {};

    //if (url.host().endsWith(Environment.host)) {
    //if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
    //}
    //}

    return headers;
  };

  // startLoading(text) {
  //   const loadingText = text || '';

  //   this.setState({ loading: true, loadingText }, () => {
  //     if (this.animatedView) {
  //       this.animatedView.transitionTo(
  //         { opacity: 1 },
  //         this.state.fadeSpeed,
  //         LayoutAnimation.Types.easeInEaseOut,
  //       );
  //     }
  //   });
  // }

  // stopLoading(text) {
  //   const loadingText = text || '';

  //   if (!this.state.loading) return;

  //   this.setState({ loading: false, loadingText }, () => {
  //     if (this.animatedView) {
  //       this.animatedView.transitionTo(
  //         { opacity: 0 },
  //         this.state.fadeSpeed,
  //         LayoutAnimation.Types.easeInEaseOut,
  //       );
  //     }
  //   });
  // }

  const onMessage = (event) => {
    const message = JSON.parse(event.nativeEvent.data);

    switch (message.action) {
      case "webview.url":
        setUrl(message.data.url);
        break;
      case "openUrl":
        //Router.navigate(message.data.url, this.props.componentId);
        break;
      case "setTitle":
        // Navigation.mergeOptions(this.props.componentId, {
        //   topBar: {
        //     title: {
        //       text: message.data.title,
        //     },
        //     subtitle: {
        //       text: message.data.subtitle,
        //     },
        //   },
        // });
        break;
      case "loading.start":
        setLoading(true);
        break;
      case "loading.stop":
        setLoading(false);
        break;
      case "navigation.url":
        setUrl(message.data.url);
        break;
      case "navigation.push":
        props.navigation.push("WebView", { url: message.data.url });
        //this.openNew(message.data.url, message.data);
        //Router.navigate(message.data.url, this.props.componentId);
        break;
      case "navigation.pop":
        //Navigation.pop(this.props.componentId);
        break;
      case "navigation.popToRoot":
        //Navigation.popToRoot(this.props.componentId);
        break;
      // case 'auth.loginWithAccessToken':
      //   if (this.props.loginStack !== Login.LOGGING_IN) {
      //     this.props.actions.login(message.data.access_token);
      //   }
      //   break;
      default:
        // alert(`Unkmown action ${message.action}`);
        break;
    }

    // if (this.props.onMessage) {
    //   this.props.onMessage(message);
    // }
  };
  if (accessToken) {
    return (
      <WebView
        ref={(r) => (instance = r)}
        source={{
          uri: url,
          // navigationType: this.state.navigationType,
          headers: headers(url, accessToken),
        }}
        startInLoadingState
        cacheEnabled
        applicationNameForUserAgent="Retrollector/1.0"
        useSharedProcessPool={false}
        style={{ flex: 1 }}
        // style={{ borderWidth: 1, borderColor: 'red' }}
        // renderLoading={this.renderLoading}
        // onLoadStart={this.startLoading}
        // onLoadEnd={this.stopLoading}
        onMessage={onMessage}
        showsHorizontalScrollIndicator={false}
      />
    );
  } else {
    return <Spinner />;
  }
};

export default WebViewScreen;

// function mapStateToProps(state, ownProps) {
//   return {
//     accessToken: state.auth.access_token,
//   };
// }
// function mapDispatchToProps(dispatch) {
//   return {
//     auth: bindActionCreators(authActions, dispatch),
//   };
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(RetroWebView);
