"use strict";
import React, { Component, useState, useEffect } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Platform,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { StyleSheet, CellGroup, CellItem, BoxResponsive, Theme } from "@app/ui";
import axios from "axios";
import { getMyCollections } from "@app/games/services/data";
import { concat } from "lodash";
import {
  TouchableOpacity,
  BorderlessButton,
  RectButton,
  BaseButton,
  ScrollView,
} from "react-native-gesture-handler";

interface Props {
  componentId?: string;
  query?: Object;
  openDefault: false;
}

interface State {
  loading: boolean;
  loadMore: boolean;
  page: number;
  items: Array<any>;
}

const CollectionsScreen = (props: Props) => {
  // static defaultProps: Props = {
  //   query: {},
  //   openDefault: false,
  // };

  // constructor(props: Props) {
  //   super(props);
  //   this.state = {
  //     loading: false,
  //     page: 1,
  //     items: [],
  //     loadMore: false,
  //   };
  // }

  const { navigation } = props;

  const isLoggedIn = useSelector((state) => state.auth.logged_in);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (isLoggedIn && !loading) {
      getCollections(currentPage);
    }
  }, [currentPage, isLoggedIn]);

  // componentDidMount() {
  //   this.getCollections(this.state.page, this.props.query);
  // }

  const openCollection = (id) => {
    navigation.push("GamesCollectionsShow", {
      id: id,
    });
  };

  const getCollections = (page: number, query?: Object = {}) => {
    if (loading) return;

    if (page > 1) {
      setLoading(true);
    }

    getMyCollections(page, query).then((response) => {
      const { data } = response;

      const mergedItems = page === 1 ? data.data : concat(items, data.data);

      setLoading(false);
      setItems(mergedItems);
      setCurrentPage(page);

      if (
        props.openDefault &&
        mergedItems.length &&
        (currentPage === 1 || currentPage === undefined)
      ) {
        openCollection(items[0].id);
      }
    });
  };

  const handleLoadMore = () => {
    if (!loading && loadMore) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderFooter = () => {
    //it will show indicator at the bottom of the list when data is loading otherwise it returns null
    if (!loading) return null;
    return <ActivityIndicator size="large" style={{ margin: 16 }} />;
  };

  const renderCollections = () => {
    return items.map((item, i) => (
      <CellItem
        title={item.name}
        onPress={() => openCollection(item.id)}
        disclosure={true}
      />
    ));
  };

  if (loading && !items) {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {/* <ActivityIndicator style={{ color: "#000" }} /> */}
      </View>
    );
  }

  return (
    <ScrollView style={{ flex: 1 }}>
      {/*<Text style={{ color: '#ffffff', textAlign: 'center', padding: 12 }}>*/}
      {/*  Is Logged: {isLoggedIn ? 'Yes' : 'No'}*/}
      {/*</Text>*/}
      {isLoggedIn ? (
        <BoxResponsive>
          <CellGroup title="My Collections">{renderCollections()}</CellGroup>
        </BoxResponsive>
      ) : (
        <Text style={{ color: "#ffffff", textAlign: "center", padding: 12 }}>
          Please log in
        </Text>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  gamesTitleText: {
    fontSize: 17,
    //fontWeight: 'bold',
    alignItems: "center",

    letterSpacing: -0.7,
  },
  gamesDescriptionText: {
    fontSize: 14,
    alignItems: "center",
    marginTop: 4,
    color: Theme.colors.text,
    fontFamily: Theme.font.regular,
  },
});

export default CollectionsScreen;
