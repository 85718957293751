"use strict";
import React, { Component, useEffect, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Linking,
  Dimensions,
  SafeAreaView,
} from "react-native";
import { BoxResponsive } from "@app/ui";
import { ChatConversations } from "@app/chat";

interface Props {
  user?: Object;
}

const ConversationsListScreen = (props: Props) => {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <BoxResponsive style={{ flex: 1 }}>
        <ChatConversations navigation={props.navigation} />
      </BoxResponsive>
    </SafeAreaView>
  );
};

export default ConversationsListScreen;
