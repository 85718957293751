import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Pressable,
  Platform,
  View,
  ImageSourcePropType,
  ImageStyle,
  NativeEventEmitter,
  StyleSheet,
  ViewStyle,
  StyleProp,
  Text,
  Dimensions,
  ScrollView,
  RefreshControl,
} from "react-native";
import {
  BackButton,
  BoxResponsive,
  Feed,
  FeedApi,
  HeaderButton,
  HeaderButtonGroup,
  Theme,
} from "@app/ui";
import { ApiData, withApi } from "@app/config";
import { HScrollView } from "react-native-head-tab-view";
import { SceneMap, TabBar } from "react-native-tab-view";
import { CollapsibleHeaderTabView } from "react-native-tab-view-collapsible-header";
import { renderItemByName } from "@app/ui/feed/items";
import { instance, instance as api } from "@app/config/api/api";
import { concat, get } from "lodash";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  useDerivedValue,
  interpolate,
  Extrapolate,
} from "react-native-reanimated";
import { Header } from "@react-navigation/stack";
import { useHasAppRole } from "@app/user/hooks/user";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";

//
// const usePageData = (slug) => {
//   return useQuery(
//     ["page", slug],
//     async () => {
//       const { data } = await instance.get("page-slug/" + slug, {
//         //params: props.params,
//       });
//       const dataItems = get(data, "data.sections");
//       const headerItem = get(data, "data.header");
//       return { items: dataItems, header: headerItem };
//     },
//     { enabled: true }
//   );
// };
//
// const Page = (props) => {
//   const [loading, setLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [error, setError] = useState(false);
//   const [loadMore, setLoadMore] = useState(false);
//   const [firstLoad, setFirstLoad] = useState(true);
//   const responsePath = props.responsePath || "data.sections";
//   const [headerComponent, setHeaderComponent] = useState(undefined);
//   const [scrollTrans, setScrollTrans] = useState(useSharedValue(0));
//   const [headerHeight, setHeaderHeight] = useState(88);
//   const canEdit = useHasAppRole("admin");
//
//   const slug = props.route?.params?.slug;
//   const path = "page-slug/" + slug;
//
//   const { data, isLoading, refetch, isError } = usePageData(slug);
//
//   React.useLayoutEffect(() => {
//     props.navigation.setOptions({
//       title: props.route?.params?.title || "",
//       headerLeft: () => <BackButton navigation={props.navigation} />,
//       headerRight: () =>
//         canEdit && (
//           <BackButton
//             symbol={"dots-solid"}
//             onPress={() => {
//               props.navigation.push("PageEditor", {
//                 id: props.route.params.id,
//               });
//             }}
//           />
//         ),
//
//       // headerRight: () => (
//       //   <HeaderButton
//       //     onPress={() =>
//       //       props.navigation.navigate("Modal", {
//       //         screen: "StreamBroadcast",
//       //         stackPresentation: "formSheet",
//       //       })
//       //     }
//       //     symbol={"broadcast-solid"}
//       //   />
//       // ),
//       //headerLeft: () => null,
//     });
//   }, [props.navigation]);
//
//   const [index, setIndex] = useState(0);
//   const [routes] = useState([
//     { key: "activity", title: "Activity" },
//     { key: "music", title: "Music & Video" },
//     { key: "shop", title: "Shop" },
//   ]);
//   const initialLayout = { width: Dimensions.get("window").width };
//
//   const renderBody = () => {
//     return (
//       <View style={{ backgroundColor: "red", flex: 1, minHeight: 100 }}></View>
//     );
//   };
//
//   const renderHeader = () => {
//     return <View style={{ backgroundColor: "blue", minHeight: 100 }}></View>;
//   };
//
//   const FirstRoute = (x) => {
//     return (
//       <HScrollView
//         index={0}
//         style={{ backgroundColor: Theme.colors.background }}
//       >
//         <Feed
//           data={data?.items}
//           //header={headerComponent}
//           loadMore={loadMore}
//           loading={loading}
//           error={error}
//           page={currentPage}
//           refreshControl={true}
//           //getItems={getFeed}
//         />
//       </HScrollView>
//     );
//   };
//
//   const SecondRoute = (x) => {
//     return (
//       <HScrollView style={{}} index={1}>
//         <View style={{ height: 3000 }}></View>
//       </HScrollView>
//     );
//   };
//
//   const ThirdRoute = (x) => {
//     return (
//       <HScrollView style={{}} index={2}>
//         <View style={{ height: 3000 }}></View>
//       </HScrollView>
//     );
//   };
//
//   const renderScene = SceneMap({
//     activity: FirstRoute,
//     music: SecondRoute,
//     shop: ThirdRoute,
//   });
//
//   const renderTabBar = (props) => {
//     return (
//       <TabBar
//         {...props}
//         inactiveColor={"#444444"}
//         activeColor={"#20D0A2"}
//         style={{
//           backgroundColor: Theme.colors.background,
//           paddingLeft: 10,
//         }}
//         tabStyle={{
//           width: "auto",
//         }}
//         indicatorContainerStyle={{
//           //paddingLeft: 20,
//           borderBottomWidth: 0.5,
//           borderColor: Theme.colors.separator,
//           width: "125%",
//         }}
//         // contentContainerStyle={{
//         //   marginHorizontal: 10,
//         // }}
//         renderLabel={({ route, focused, color }) => {
//           return (
//             <>
//               <Text
//                 style={{
//                   color: focused ? Theme.colors.text : "#444444",
//                   fontFamily: Theme.fonts.titles.medium,
//                   fontSize: 12,
//                   textTransform: "uppercase",
//                   textAlign: "left",
//                   letterSpacing: 1.5,
//                 }}
//               >
//                 {route.title}
//               </Text>
//               <View
//                 style={{
//                   height: focused ? 2 : 0,
//                   backgroundColor: "#20D0A2",
//                   width: "50%",
//                   position: "absolute",
//                   marginTop: 28,
//                   zIndex: 4,
//                   //left: props.width,
//                   //top: props.layout.height - 2,
//                 }}
//               />
//             </>
//           );
//         }}
//         renderIndicator={(props) => {
//           //console.warn("indi", props);
//           return (
//             <View
//               style={{
//                 height: 0,
//                 backgroundColor: "#20D0A2",
//                 width: 40,
//                 left: props.width,
//                 top: props.layout.height - 2,
//               }}
//             />
//           );
//         }}
//       />
//     );
//   };
//
//   const titleOpacity = useDerivedValue(() => {
//     return interpolate(
//       scrollTrans.value,
//       [0, 100, headerHeight],
//       [0, 0, 1],
//       Extrapolate.CLAMP
//     );
//   });
//   const titleStyle = useAnimatedStyle(() => {
//     return { opacity: titleOpacity.value };
//   });
//
//   const renderNavigationBar = () => {
//     return (
//       <Animated.View
//         style={[
//           {
//             position: "absolute",
//             height: 88,
//             top: 0,
//             left: 0,
//             right: 0,
//             zIndex: 88,
//             backgroundColor: Theme.colors.background,
//           },
//           titleStyle,
//         ]}
//       >
//         {/*<View style={{ height: 44, backgroundColor: "rgba(0,0,0,.5)" }}></View>*/}
//         {/*<View style={{ height: 44, backgroundColor: "rgba(0,0,0,.5)" }}></View>*/}
//       </Animated.View>
//     );
//   };
//
//   const headerC = data?.header ? (
//     renderItemByName(data.header?.type, data.header?.data)
//   ) : (
//     <View></View>
//   );
//
//   return (
//     <>
//       {renderNavigationBar()}
//       <View style={{ flex: 1 }}>
//         <CollapsibleHeaderTabView
//           renderScrollHeader={() => headerC}
//           navigationState={{ index, routes }}
//           renderScene={renderScene}
//           onIndexChange={setIndex}
//           makeScrollTrans={(scrollTrans: Animated.SharedValue<number>) => {
//             setScrollTrans(scrollTrans);
//           }}
//           lazy={true}
//           renderTabBar={renderTabBar}
//           tabBarHeight={32}
//           frozeTop={88}
//           // renderRefreshControl={
//           //   <RefreshControl
//           //     refreshing={loading}
//           //     tintColor={"#fff"}
//           //     onRefresh={refetch}
//           //   />
//           // }
//           //initialLayout={initialLayout}
//         >
//           {/*<FirstRoute index={0} />*/}
//           {/*/!*<SecondRoute index={1} />*!/*/}
//           {/*/!*<SecondRoute index={2} />*!/*/}
//         </CollapsibleHeaderTabView>
//       </View>
//     </>
//   );
// };

const PageFeed = (props) => {
  const slug = props.route?.params?.slug;
  const queryPath = "page-slug/" + slug;
  const canEdit = useHasAppRole("admin");

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: props.route?.params?.title || "",
      headerLeft: () => (
        <HeaderButtonGroup>
          <BackButton navigation={props.navigation} />
        </HeaderButtonGroup>
      ),
      headerRight: () => (
        <HeaderButtonGroup>
          {canEdit && (
            <BackButton
              symbol={"dots-solid"}
              onPress={() => {
                props.navigation.push("PageEditor", {
                  id: props.route.params.id,
                });
              }}
            />
          )}
        </HeaderButtonGroup>
      ),
    });
  }, [props.navigation]);

  return (
    <>
      <Helmet>
        <title>Page Title</title>
      </Helmet>
      {/*<link rel="canonical" href="http://mysite.com/example" />*/}
      <FeedApi
        path={queryPath}
        queryKey={["page-slug", slug]}
        responsePath="data.sections"
        header={"data.header"}
        navigation={props.navigation}
        navigationHeaderFake={true}
        //params={props.route?.params}
      />
    </>
  );
};

export default PageFeed;
