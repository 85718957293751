import { instance, configure, clear } from "@app/config/api/api";

export function getMe(): any {
  return instance.request<ServerData>({
    url: "me",
    params: {},
  });
}

export function postSocialLogin(
  platform: string,
  accessToken: string,
  state: string
): any {
  let data = { platform: platform, access_token: accessToken };
  if (state) {
    data.state = state;
  }

  return instance.request<ServerData>({
    url: "auth/social-login/",
    data: data,
    method: "post",
  });
}

export function setCredentials(accessToken: string) {
  configure(accessToken);
}

export function clearCredentials() {
  clear();
}

export function postPushToken(pushToken: string): any {
  let data = { push_token: pushToken };

  return instance.request<ServerData>({
    url: "auth/push-token/",
    data: data,
    method: "post",
  });
}
