import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ScrollView, View } from "react-native";
import {
  BoxResponsive,
  Button,
  CellGroup,
  CellItem,
  StyleSheet,
  Text,
  Theme,
} from "@app/ui";
import { useNavigation } from "@react-navigation/native";

import YoutubePreview from "../components/youtube_preview";
import UploadFile from "../components/audio_upload";
import { useApiRequest } from "@app/config/api/api";

interface Props {
  id: any;
}

interface ChapterDataSource {
  source: string;
  data: string;
}

interface CreateChapterData {
  name?: string;
  description?: string;
  cover_url?: string;
  source: ChapterDataSource;
}

export default (props: Props) => {
  const navigation = props.navigation || useNavigation();

  const [
    { data: item, loading, error },
    createPodcastChapterRequest,
  ] = useApiRequest(
    {
      url: `podcast/${props.route.params.id}/chapters`,
      method: "POST",
    },
    { manual: true }
  );

  const createPodcastChapter = (data: CreateChapterData) => {
    createPodcastChapterRequest({ data });
  };

  return (
    <ScrollView style={{ flex: 1 }}>
      <BoxResponsive>
        <YoutubePreview onDataReady={createPodcastChapter} />
        <UploadFile />
      </BoxResponsive>
    </ScrollView>
  );
};

const style = StyleSheet.create({
  box: {
    margin: 20,
    padding: 20,
    backgroundColor: Theme.colors.chrome,
    borderRadius: 12,
  },
});
