import React from "react";
import { NativeBaseProvider, extendTheme } from "native-base";
import DarkTheme from "./theme";

const theme = extendTheme({
  colors: {
    // primary: {
    //     50: '#E3F2F9',
    //     100: '#C5E4F3',
    //     200: '#A2D4EC',
    //     300: '#7AC1E4',
    //     400: '#47A9DA',
    //     500: '#0088CC',
    //     600: '#007AB8',
    //     700: '#006BA1',
    //     800: '#005885',
    //     900: '#003F5E',
    // },
    // Redefinig only one shade, rest of the color will remain same.
    // amber: {
    //     400: '#d97706',
    // },
  },
  fontConfig: {
    body: {
      100: {
        normal: DarkTheme.font.light,
        italic: "Roboto-LightItalic",
      },
      200: {
        normal: DarkTheme.font.light,
        italic: "Roboto-LightItalic",
      },
      300: {
        normal: DarkTheme.font.light,
        italic: "Roboto-LightItalic",
      },
      400: {
        normal: DarkTheme.font.regular,
        italic: "Roboto-Italic",
      },
      500: {
        normal: DarkTheme.font.medium,
      },
      600: {
        normal: DarkTheme.font.medium,
        italic: "Roboto-MediumItalic",
      },
      // Add more variants
      //   700: {
      //     normal: 'Roboto-Bold',
      //   },
      //   800: {
      //     normal: 'Roboto-Bold',
      //     italic: 'Roboto-BoldItalic',
      //   },
      //   900: {
      //     normal: 'Roboto-Bold',
      //     italic: 'Roboto-BoldItalic',
      //   },
    },
  },

  // Make sure values below matches any of the keys in `fontConfig`
  fonts: {
    heading: "body",
    body: "body",
    mono: "Roboto",
  },
  breakpoints: {
    base: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1280,
  },
  config: {
    // Changing initialColorMode to 'dark'
    initialColorMode: "dark",
  },
});

export default theme;
