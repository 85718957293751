import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";

interface Props {}

export default (props: Props) => {
  return <View style={{ marginVertical: 8 }}>{props.children}</View>;
};
