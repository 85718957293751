import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { TextInput, View } from "react-native";
import {
  AvatarUpload,
  BoxResponsive,
  Button,
  CellGroup,
  CellItem,
  Spinner,
  Text,
  Theme,
} from "@app/ui";
import { useNavigation } from "@react-navigation/native";
import { instance, useApiRequest } from "@app/config/api/api";
import { find } from "lodash";
import { Formik } from "formik";

interface Props {
  // id: any;
  // podcast_id: any;
}

export default (props: Props) => {
  const navigation = props.navigation || useNavigation();

  const url = `podcast/${props.route.params.podcast_id}/manager/chapter/${props.route.params.id}`;

  const [{ data, loading, error }, fetch] = useApiRequest(
    {
      url: url,
      method: "GET",
      //data: { youtube_id: youtubeId },
    },
    { manual: false }
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Text>Error</Text>;
  }

  const chapter = data;

  const coverImage = find(chapter?.media, { type: "cover" });

  const publishForm = (data) => {
    const response = instance.post(url, data);
  };

  const renderForm = () => {
    const textStyle = {
      color: "#fff",
      backgroundColor: Theme.colors.chrome,
      borderWidth: 1,
      borderColor: Theme.colors.chromeHigh,
      borderRadius: 4,
      paddingHorizontal: 12,
      paddingVertical: 8,
      fontFamily: Theme.fonts.titles.regular,
    };

    return (
      <Formik
        initialValues={{
          name: data.name,
          description: data.description,
          season: data.season,
          episode: data.episode,
        }}
        onSubmit={(values) => publishForm(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <View>
            <Text>Name</Text>
            <TextInput
              style={[textStyle]}
              onChangeText={handleChange("name")}
              onBlur={handleBlur("name")}
              value={values.name}
            />
            <Text>Description</Text>
            <TextInput
              style={[
                textStyle,
                { height: 120, minHeight: 120, maxHeight: 180 },
              ]}
              onChangeText={handleChange("description")}
              onBlur={handleBlur("description")}
              value={values.description}
              multiline={true}
              numberOfLines={3}
            />

            <Text>Season</Text>
            <TextInput
              style={[textStyle]}
              onChangeText={handleChange("season")}
              onBlur={handleBlur("season")}
              value={values.season}
              keyboardType={"phone-pad"}
            />

            <Text>Episode</Text>
            <TextInput
              style={[textStyle]}
              onChangeText={handleChange("episode")}
              onBlur={handleBlur("episode")}
              value={values.episode}
              keyboardType={"phone-pad"}
            />

            <Button onPress={handleSubmit} text="Save" />
          </View>
        )}
      </Formik>
    );
  };

  return (
    <BoxResponsive>
      <AvatarUpload
        image={coverImage?.url}
        uploadUrl={url}
        attributeName={"cover"}
        width={120}
      />
      <Text>Chapter {props.route.params.id}</Text>
      {renderForm()}
    </BoxResponsive>
  );
};
