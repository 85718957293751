import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { Text, Theme, TurboSymbol } from "@app/ui";

export enum StateViewType {}

interface Props {
  symbol?: string;
  text?: string;
  iconSize?: number;
}

export default ({ symbol = "chat-solid", iconSize = 128, ...props }: Props) => {
  const color = Theme.colors.separator;

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        marginTop: -iconSize / 2,
      }}
    >
      {symbol && (
        <TurboSymbol
          name={symbol}
          size={iconSize}
          color={color}
          style={{ margin: 16 }}
        />
      )}
      <Text style={{ color }}>
        {props.text || "There are no items here sorry."}
      </Text>
    </View>
  );
};
