import { Platform } from "react-native";
import { App as TurboApp } from "./TurboApp";

const Navigation = {
  screens: {
    options: {
      headerTransparentOnScroll: {
        ...Platform.select({
          native: {
            headerTransparent: true,
            headerStyle: {
              backgroundColor: "transparent",
            },
            headerBackVisible: false,
          },
        }),
      },
      headerTransparentOnScrollNative: {
        ...Platform.select({
          ios: {
            headerTransparent: true,
            headerStyle: {
              backgroundColor: "rgba(9,9,9,.8)",
            },
            headerBlurEffect: "dark",
          },
          android: {
            // headerTransparent: true,
            // headerStyle: {
            //   backgroundColor: "rgba(9,9,9,.9)",
            // },
            // headerBlurEffect: "systemMaterialDark",
          },
        }),
      },
    },
  },
};

export { Navigation, TurboApp };
