import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Platform, View } from "react-native";
import { CellGroup, CellItem, Modal, Text } from "@app/ui";
import { MenuAction, MenuView } from "@react-native-menu/menu";
import { find } from "lodash";

interface Props {
  children: React.ReactNode;
  items: MenuAction[];
  onPress?: any;
}

export default (props: Props) => {
  const renderWeb = () => {
    return (
      <Modal isVisible={false} onClose={() => {}} blur={true}>
        <CellGroup>
          {props.items.map((item: MenuAction, index) => (
            <CellItem
              key={item.id}
              title={item.title}
              // onPress={() => {
              //     onClose();
              //     item.onPress();
              // }}
            />
          ))}
        </CellGroup>
      </Modal>
    );
  };

  const renderNative = () => {
    return (
      <MenuView
        actions={props.items}
        onPressAction={({ nativeEvent }) => {
          const id = nativeEvent.event;
          const item = find(props.items, { id });

          if (item?.onPress) {
            item.onPress();
          } else {
            if (props.onPress) {
              props.onPress({ nativeEvent });
            }
          }
        }}
      >
        {props.children}
      </MenuView>
    );
  };

  return Platform.select({
    native: renderNative(),
    default: renderWeb(),
  });
};
