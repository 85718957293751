import React, { Component, ReactNode } from "react";
import {
  Text,
  Platform,
  Image,
  ImageSourcePropType,
  Pressable,
  TouchableHighlight,
  Animated,
  TouchableOpacity,
} from "react-native";

import { PointerInteractionView } from "@thefunbots/react-native-pointer-interactions";

let ContextMenu;

if (Platform.OS !== "web") {
  ContextMenu = require("react-native-context-menu-view").default;
}

interface Action {
  title: string;
  systemIcon?: string;
  destructive?: boolean;
  disabled?: boolean;
  inlineChildren?: boolean;
  actions?: Array<Action>;
}

interface Props {
  children: any;
  onPress?: any;
  style?: any;
  containerStyle?: any;
  disabled?: boolean;
  actions?: Action[];
  onPressAction?: any;
  scaleSize?: number;
  pointerMode?: string;
}

export default ({
  children,
  onPress,
  style,
  containerStyle,
  disabled,
  actions,
  onPressAction,
  scaleSize = 0.97,
  pointerMode = "automatic",
}: Props) => {
  const scaleInAnimated = new Animated.Value(0);
  const scaleOutAnimated = new Animated.Value(0);
  const activeOpacity = 0.7;
  const animationDuration = 100;

  const SCALE = {
    // this defines the terms of our scaling animation.
    getScaleTransformationStyle(
      animated: Animated.Value,
      startSize: number = 1,
      endSize: 0.99
    ) {
      const interpolation = animated.interpolate({
        inputRange: [0, 1],
        outputRange: [startSize, endSize],
      });
      return {
        transform: [{ scale: interpolation }],
      };
    },
    // This defines animation behavior we expext onPressIn
    pressInAnimation(
      animated: Animated.Value,
      duration: number = animationDuration
    ) {
      animated.setValue(0);
      Animated.timing(animated, {
        toValue: 1,
        duration,
        useNativeDriver: true,
      }).start();
    },
    // This defines animatiom behavior we expect onPressOut
    pressOutAnimation(
      animated: Animated.Value,
      duration: number = animationDuration
    ) {
      animated.setValue(1);
      Animated.timing(animated, {
        toValue: 0,
        duration,
        useNativeDriver: true,
      }).start();
    },
  };

  const renderMain = () => {
    const disabledStyle = disabled
      ? {
          opacity: 0.25,
        }
      : {};
    return (
      <PointerInteractionView style={containerStyle} pointerMode={pointerMode}>
        <TouchableOpacity
          onPress={onPress}
          onPressIn={() => {
            disabled ? null : SCALE.pressInAnimation(scaleOutAnimated);
          }}
          onPressOut={() => {
            SCALE.pressOutAnimation(scaleOutAnimated);
          }}
          style={[
            SCALE.getScaleTransformationStyle(scaleOutAnimated, 1, scaleSize),
            // {
            //   justifyContent: "center",
            //   alignContent: "center",
            //   alignItems: "center",
            // },
            style,
            disabledStyle,
          ]}
          activeOpacity={activeOpacity}
          disabled={disabled}
        >
          {children}
        </TouchableOpacity>
      </PointerInteractionView>
    );
  };

  if (Platform.OS === "ios" || Platform.OS === "android") {
    if (actions && actions.length) {
      return (
        <PointerInteractionView style={containerStyle} pointerMode="lift">
          <ContextMenu
            actions={actions}
            onPress={onPressAction}
            style={{ flex: 1 }}
            //style={[style]}
          >
            {renderMain()}
          </ContextMenu>
        </PointerInteractionView>
      );
    } else {
      return renderMain();
    }
  } else {
    return renderMain();
  }
};
