import React, { useRef, useState } from "react";
import { Alert, Platform, Pressable, SafeAreaView } from "react-native";
import { Button, ImageBox } from "@app/ui";
import * as DocumentPicker from "expo-document-picker";
import { instance } from "@app/config/api/api";
import * as FileSystem from "expo-file-system";
import { useAuth } from "@app/user/hooks/user";
import { Environment } from "@app/config";
import { FileSystemSessionType, FileSystemUploadType } from "expo-file-system";
import { Modalize } from "react-native-modalize";
import { Portal } from "react-native-portalize";
import * as ImagePicker from "expo-image-picker";

interface Props {
  uploadUrl: string;
  attributeName?: string;
  image?: string;
  width?: number;
  height?: number;
  children?: any;
  aspectRatio?: number;
}
interface FileInfo {
  uri: any;
  name: string;
  size: Number;
}

export default (props: Props) => {
  const [fileInfo, setFileInfo] = useState<FileInfo>();
  const [uploadProgress, setUploadProgress] = useState<number>();
  const [isUploading, setIsUploading] = useState(false);
  const accessToken = useAuth().accessToken;
  const modalizeRef = useRef<Modalize>(null);

  const renderImage = () => {
    const customStyle = isUploading
      ? {
          opacity: 0.5,
          //transform: { scale: 0.5 },
        }
      : {};
    return (
      <ImageBox
        source={{ uri: props.image }}
        borderWidth={0}
        aspectRatio={props.aspectRatio || 1}
        style={[customStyle]}
      />
    );
  };

  const renderContent = () => {
    return props.children || renderImage();
  };

  const selectFile = () => {
    DocumentPicker.getDocumentAsync({
      type: "image/*",
      copyToCacheDirectory: false,
      multiple: false,
    }).then(({ type, uri, name, size }) => {
      if (type === "success") {
        uploadFile(uri, name);
        //setFileInfo({ uri, name, size });
      }
    });

    modalizeRef.current?.close();
  };

  const uploadFileAsync = (uri, name, type?) => {
    setIsUploading(true);
    const uploadUrl = Environment.apiUrl + props.uploadUrl;

    FileSystem.uploadAsync(uploadUrl, uri, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-Tenant": "groovr",
      },
      httpMethod: "PUT",
      sessionType: FileSystemSessionType.FOREGROUND,
      uploadType: FileSystemUploadType.MULTIPART,
      fieldName: "cover",
      mimeType: "image/jpg",
    })
      .then(() => {})
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const uploadFile = (uri, name, type?) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      },
    };
    // config.headers = {
    //   "Content-Type": "multipart/form-data",
    // };
    const formData = new FormData();
    // console.warn(uri);
    //    formData.append(props.attributeName || "file", { uri, name });

    if (Platform.OS === "web") {
      formData.append(props.attributeName || "file", uri);
    } else {
      formData.append(props.attributeName || "file", {
        uri: Platform.OS === "android" ? `file:///${uri}` : uri,
        type: "image/" + name.toLowerCase().split(".").pop(),
        name: name,
      });
    }

    setIsUploading(true);
    instance
      .post(props.uploadUrl, formData, config)
      .then((data) => {
        setImage(data);
      })
      .catch(() => {})
      .finally(() => {
        setIsUploading(false);
      });
  };

  const selectCameraRoll = () => {
    ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    })
      .then(({ cancelled, height, width, uri }) => {
        if (!cancelled) {
          uploadFile(uri, "image.jpg");
        }
      })
      .catch((e) => console.error(e))
      .finally(() => modalizeRef.current?.close());
  };

  const onPress = () => {
    modalizeRef.current?.open();
    //selectFile();
  };

  return (
    <>
      <Pressable
        onPress={onPress}
        style={[
          {
            width: props.width,
            aspectRatio: props.aspectRatio,
            backgroundColor: "#444",
            borderRadius: 4,
          },
        ]}
      >
        {renderContent()}
      </Pressable>
      <Portal>
        <Modalize
          ref={modalizeRef}
          adjustToContentHeight={true}
          handlePosition={"inside"}
        >
          <SafeAreaView style={{ margin: 20 }}>
            <Button
              text={"Documents"}
              onPress={selectFile}
              style={{ marginBottom: 12 }}
            />
            <Button text={"Photo Lib"} onPress={selectCameraRoll} />
          </SafeAreaView>
        </Modalize>
      </Portal>
    </>
  );
};
