import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { Text } from "@app/ui";

interface Props {}

export default (props: Props) => {
  return (
    <Text
      style={[
        { fontSize: 12, color: "red", marginVertical: 8, marginHorizontal: 4 },
      ]}
    >
      {props.children}
    </Text>
  );
};
