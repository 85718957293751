import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ScrollView, View } from "react-native";
import { BoxResponsive, CellGroup, CellItem, Spinner, Text } from "@app/ui";
import { useNavigation } from "@react-navigation/native";
import { useApiRequest } from "@app/config/api/api";
import { find } from "lodash";

interface Props {
  id: any;
}

export default (props: Props) => {
  const navigation = props.navigation || useNavigation();

  const [{ data, loading, error }, fetch] = useApiRequest(
    {
      url: `podcast/${props.route.params.id}/manager/chapters`,
      method: "GET",
      //data: { youtube_id: youtubeId },
    },
    { manual: false }
  );

  if (error) {
    return <Text>Error</Text>;
  }

  if (loading) {
    return <Spinner />;
  }

  if (!data) {
    return <Text>No data</Text>;
  }

  return (
    <ScrollView style={{ flex: 1 }}>
      <BoxResponsive>
        <CellGroup title="Content" key="content">
          {data.data.map((item) => {
            const coverImage = find(item?.media, { type: "cover" });

            return (
              <CellItem
                key={item.id}
                title={item.name}
                subtitle={"Session"}
                accessoryText={item.status}
                description={"Upload a new chapter or session"}
                image={coverImage}
                imageBackgroundColor={"#444"}
                footer={() => (
                  <>
                    <Text>Created at: {item.created_at}</Text>
                    <Text>Published at: {item.published_at}</Text>
                  </>
                )}
                disclosure={true}
                // symbol="collection-upload-solid"
                onPress={() => {
                  navigation.push("PodcastEditorChapter", {
                    id: item.id,
                    podcast_id: item.podcast_id,
                  });
                }}
              />
            );
          })}
        </CellGroup>
      </BoxResponsive>
    </ScrollView>
  );
};
