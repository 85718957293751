import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Text, Theme } from "@app/ui";
import { Platform, SafeAreaView, View, Animated } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import tinycolor from "tinycolor2";
import { BlurView } from "expo-blur";

interface Props {
  statusBar?: boolean;
  navigationBar?: boolean;
  children?: any;
  safeArea?: boolean;
  leftBar?: any;
  rightBar?: any;
  containerStyle?: any;
  backgroundColor?: any;
  scrollSize?: number;
  scrollOffset?: number;
  scrollPosition?: Animated.Value;
  blur?: boolean;
}

export default ({
  statusBar = true,
  navigationBar = true,
  children,
  safeArea = false,
  leftBar,
  rightBar,
  containerStyle,
  backgroundColor,
  scrollOffset = 0,
  scrollSize = 44,
  scrollPosition, // = new Animated.Value(0),
  blur = false,
}: Props) => {
  const statusBarInset = useSafeAreaInsets();

  const renderStatusBar = () => {
    return <View style={{ height: statusBarInset.top }} />;
  };

  const renderNavigationBarDefaultContent = () => {
    const sideStyle = {
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
    };
    return (
      <View style={{ flex: 1, flexDirection: "row" }}>
        {leftBar && <View style={[sideStyle]}>{leftBar()}</View>}
        <View
          style={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        ></View>
        {rightBar && <View style={[sideStyle]}>{rightBar()}</View>}
      </View>
    );
  };

  const renderNavigationBar = () => {
    return (
      <View
        style={{
          marginVertical: 0,
          marginHorizontal: 8,
          ...Platform.select({
            ios: {
              height: 47,
            },
            android: {
              height: 52,
            },
          }),
        }}
      >
        {children ? children : renderNavigationBarDefaultContent()}
      </View>
    );
  };

  const backgroundColorAlpha = tinycolor(backgroundColor)
    .setAlpha(0)
    .toRgbString();

  const backgroundColorCom = scrollPosition?.interpolate({
    inputRange: [
      scrollOffset,
      Math.max(scrollOffset, scrollSize + scrollOffset),
    ],
    outputRange: [backgroundColorAlpha, backgroundColor],
    extrapolate: "clamp",
  });

  const opacityCom = scrollPosition?.interpolate({
    inputRange: [
      scrollOffset,
      Math.max(scrollOffset, scrollSize + scrollOffset),
    ],
    outputRange: [0, 1],
  });

  let containerStyleComputed = {
    ...containerStyle,
  };

  if (backgroundColor && backgroundColorCom && scrollPosition) {
    containerStyleComputed = {
      ...containerStyleComputed,
      backgroundColor: backgroundColorCom,
    };
  }

  const renderItemsNoBlur = () => {
    return (
      <Animated.View
        style={[
          containerStyleComputed,
          containerStyle,
          // { backgroundColor: Theme.colors.background },
        ]}
      >
        {statusBar && renderStatusBar()}
        {navigationBar && renderNavigationBar()}
      </Animated.View>
    );
  };

  const renderItemsBlur = () => {
    return (
      <Animated.View style={[{ opacity: opacityCom }, containerStyle]}>
        <BlurView intensity={100} tint="dark">
          {statusBar && renderStatusBar()}
          {navigationBar && renderNavigationBar()}
        </BlurView>
      </Animated.View>
    );
  };

  const renderItems = () => {
    return shouldUseBlur() ? renderItemsBlur() : renderItemsNoBlur();
  };

  const shouldUseBlur = () => {
    return Platform.OS === "ios" && blur;
  };

  return safeArea ? (
    <SafeAreaView>{renderItems()}</SafeAreaView>
  ) : (
    renderItems()
  );
};
