import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import {
  AvatarUpload,
  BoxResponsive,
  CellGroup,
  CellItem,
  Spinner,
  Text,
} from "@app/ui";
import { useNavigation } from "@react-navigation/native";
import { find } from "lodash";
import { useApiRequest } from "@app/config/api/api";

interface Props {
  id: any;
}

export default (props: Props) => {
  const navigation = props.navigation || useNavigation();
  const url = `podcast/${props.route.params.id}/manager`;

  const [{ data, loading, error }, fetch] = useApiRequest(
    {
      url: url,
      method: "GET",
    },
    { manual: false }
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Text>Error</Text>;
  }

  const coverImage = find(data?.data?.media, { type: "cover" });

  return (
    <BoxResponsive>
      <View style={{ marginHorizontal: 20 }}>
        <AvatarUpload
          image={coverImage?.thumb_url}
          uploadUrl={url}
          attributeName={"cover"}
          width={120}
        />
      </View>
      <CellGroup title="Content" key="content">
        <CellItem
          title="Upload"
          description={"Upload a new chapter or session"}
          disclosure={true}
          symbol="collection-upload-solid"
          onPress={() => {
            navigation.push("PodcastUpload", {
              id: props.route.params.id,
            });
          }}
        />
        <CellItem
          title="Chapters"
          description={"Manage chapters"}
          disclosure={true}
          symbol="music-collection-solid"
          onPress={() => {
            navigation.push("PodcastEditorChaptersList", {
              id: props.route.params.id,
            });
          }}
        />
      </CellGroup>
    </BoxResponsive>
  );
};
