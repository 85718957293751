"use strict";
import React, { Component, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Linking,
  Dimensions,
  Platform,
} from "react-native";
import { Modal, Card, Button, StyleSheet, Theme } from "@app/ui";
import * as Facebook from "expo-facebook";
import { setCredentials, postSocialLogin } from "@app/auth/services/data";
import Auth from "@app/auth/services/auth.service";
import { connect, useDispatch, useSelector } from "react-redux";
import * as authActions from "@app/user/actions/auth.action";
import * as AppleAuthentication from "expo-apple-authentication";
import * as GoogleSignIn from "expo-google-sign-in";
import * as WebBrowser from "expo-web-browser";
import {
  makeRedirectUri,
  ResponseType,
  useAuthRequest,
} from "expo-auth-session";
import { Environment } from "@app/config";
import { useAuthenticatedUser } from "@app/user";

enum LoginAuthProvider {
  Internal = "app",
  Facebook = "facebook",
  Apple = "apple",
}

interface Props {
  isVisible: boolean;
  onClose?: Function;
}

const LoginAuthProvidersDiscovery = {
  facebook: {
    authorizationEndpoint: "https://www.facebook.com/v9.0/dialog/oauth",
    tokenEndpoint: "https://graph.facebook.com/v9.0/oauth/access_token",
  },
};

WebBrowser.maybeCompleteAuthSession();
const useProxy = Platform.select({ web: false, default: true });

const LoginModal = ({ isVisible, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const user = useAuthenticatedUser();

  const discovery = LoginAuthProvidersDiscovery[LoginAuthProvider.Facebook];
  const facebookService = Environment.services.facebook;

  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: facebookService.appId,
      scopes: facebookService.scopes,
      responseType: ResponseType.Token,
      // For usage in managed apps using the proxy
      redirectUri: makeRedirectUri({
        useProxy: false,
        // For usage in bare and standalone
        // Use your FBID here. The path MUST be `authorize`.
        native: "fb" + facebookService?.appId + "://authorize",
      }),
      extraParams: {
        // Use `popup` on web for a better experience
        display: Platform.select({ web: "popup" }),
        // Optionally you can use this to rerequest declined permissions
        auth_type: "rerequest",
      },
    },
    discovery
  );

  React.useEffect(() => {
    if (response?.type === "success") {
      setIsLoading(true);
      const { access_token } = response.params;
      console.warn("FACEBOOK OK", response.params);
      postSocialLogin("facebook", access_token).then(handleLoginResponse);
    }
  }, [response]);

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const loginWithApple = async () => {
    setIsLoading(true);
    let accessToken = undefined;

    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });

      accessToken = credential.identityToken;

      // signed in
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        // handle that the user canceled the sign-in flow
      } else {
        // handle other errors
        console.error(e);
      }
      setIsLoading(false);
    }

    if (accessToken) {
      postSocialLogin("apple", accessToken).then(handleLoginResponse);
    }

    setIsLoading(false);
  };

  const loginWithFacebookWeb = () => {
    promptAsync({ useProxy, windowFeatures: { width: 700, height: 600 } });
  };

  const loginWithFacebook = () => {
    setIsLoading(true);
    Facebook.initializeAsync({
      appId: facebookService.appId,
    }).then(() => {
      Facebook.logInWithReadPermissionsAsync({
        permissions: facebookService?.scopes,
      }).then(
        (result) => {
          if (result.type === "success") {
            postSocialLogin("facebook", result.token).then(handleLoginResponse);
          } else {
            setIsLoading(false);
          }
        },
        (error) => {
          alert(`Login fail with error: ${error}`);
          setIsLoading(false);
        }
      );
    });
  };

  const loginWithGoogle = async () => {
    setIsLoading(true);

    try {
      await GoogleSignIn.askForPlayServicesAsync();
      const { type, user } = await GoogleSignIn.signInAsync();
      if (type === "success") {
        const user = await GoogleSignIn.signInSilentlyAsync();
        postSocialLogin("google", user.auth.accessToken).then(
          handleLoginResponse
        );
      }
    } catch ({ message }) {
      alert("login: Error:" + message);
    }
    setIsLoading(false);
  };

  const handleLoginResponse = (response) => {
    if (response.data.access_token) {
      Auth.getInstance()
        .saveCredentials(response.data.access_token)
        .then(() => {
          dispatch(authActions.setLoggedIn(response.data.access_token));

          close();
        });
    } else {
      setIsLoading(false);
      Alert.alert("Ups, something happened");
      console.error(response);
    }
  };

  return (
    <Modal isVisible={isVisible} onClose={close}>
      <Text
        style={{
          paddingVertical: 24,
          textAlign: "center",
          color: Theme.colors.text, //StyleSheet.value("$textColor"),
          fontFamily: Theme.font.medium,
          fontSize: 16,
        }}
      >
        Continue with:
      </Text>
      <View>
        {Platform.OS === "ios" && (
          <View style={{ marginBottom: 16 }}>
            <AppleAuthentication.AppleAuthenticationButton
              buttonType={
                AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN
              }
              buttonStyle={
                AppleAuthentication.AppleAuthenticationButtonStyle.BLACK
              }
              cornerRadius={4}
              style={{ height: 44 }}
              onPress={loginWithApple}
            />
          </View>
        )}
        <View style={{ marginBottom: 16 }}>
          <Button
            onPress={() => {
              Platform.OS === "web"
                ? loginWithFacebookWeb()
                : loginWithFacebook();
            }}
            isLoading={isLoading}
            text="Login with Facebook"
            containerStyle={{ marginBottom: 16 }}
          />
        </View>
        <View style={{ marginBottom: 16 }}>
          <Button
            onPress={() => {
              Platform.OS === "web" ? loginWithGoogle() : loginWithGoogle();
            }}
            isLoading={isLoading}
            text="Login with Google"
          />
        </View>
      </View>
    </Modal>
  );
};

const stylesText = StyleSheet.create({});

// function mapStateToProps(state, ownProps) {
//   return {
//     loggedIn: state.auth.logged_in,
//     user: state.auth.user,
//     loading: state.auth.loading,
//   };
// }
// function mapDispatchToProps(dispatch) {
//   return {
//     auth: bindActionCreators(authActions, dispatch),
//   };
// }

export default LoginModal;
