import { Navigation } from "@app/core";

import PlayerScreen from "./components/player_screen";
import MusicDemoScreen from "./components/demo";
import CoversCarousel from "./components/cover_carousel";
import ProgressSlider from "./components/progress_slider";
import ProgressLine from "./components/progress_line";
import PodcastCellItem from "./components/podcast_cell_item";
import MusicQueueScreen from "./components/queue_list";
import MusicHomeScreen from "./screens/music_home";
import MusicStylePickerScreen from "./screens/styles_picker";
import PodcastScreen from "./screens/podcast";
import PodcastEditorScreen from "./screens/podcast_editor";
import PodcastUploadScreen from "./screens/podcast_upload";
import PodcastEditorChaptersListScreen from "./screens/podcast_chapters_list";
import PodcastEditorChapterScreen from "./screens/podcast_chapter_editor";
import PodcastHeader from "./components/podcast_header";
import PodcastChapterItem from "./components/podcast_chapter_item";
import PodcastChapterList from "./components/podcast_chapter_list";
import PlayerMiniCompat from "./components/player_mini_compat";
import PlayerScreenCompat from "./screens/player_compat";
import VolumeSlider from "@app/music/components/volume_slider";
import PlayButton from "./components/play_button";
import PlaybackPositionSync from "./components/progress_update_sync";
import PodcastCreateScreen from "./screens/create";
import {
  TurboProvider,
  TurboScreen,
  TurboScreens,
} from "@app/core/providers/ModuleProvider";
import { Dimensions } from "react-native";

const StackPresentationType =
  Dimensions.get("window").width > 810 ? "fullScreenModal" : "formSheet";

const Screens: TurboScreen[] = [
  {
    name: "MusicHome",
    component: MusicHomeScreen,
    options: {
      ...Navigation.screens.options.headerTransparentOnScrollNative,
    },
    path: "/music",
  },
  { name: "MusicDemo", component: MusicDemoScreen },
  {
    name: "MusicPlayer",
    component: PlayerScreenCompat,
    options: {
      title: "",
      headerShown: false,
      //backgroundColor: "red", // Theme.colors.chrome,
      cardStyle: { backgroundColor: "white" },
      // stackPresentation: screenProperties.screen.large
      //   ? "fullScreenModal"
      //   : "formSheet",
      stackPresentation: StackPresentationType,
      animationEnabled: true,
    },
  },
  { name: "MusicQueue", component: MusicQueueScreen },
  { name: "MusicStylePicker", component: MusicStylePickerScreen },
  {
    name: "Podcast",
    component: PodcastScreen,
    options: {
      ...Navigation.screens.options.headerTransparentOnScroll,
    },
    path: "podcast/:id",
  },
  {
    name: "PodcastCreate",
    component: PodcastCreateScreen,
    options: {
      title: "Create Podcast",
    },
    path: "podcast/create",
  },
  {
    name: "PodcastEditor",
    component: PodcastEditorScreen,
    path: "podcast/:id/edit",
  },
  {
    name: "PodcastEditorChaptersList",
    component: PodcastEditorChaptersListScreen,
  },
  {
    name: "PodcastEditorChapter",
    component: PodcastEditorChapterScreen,
  },
  {
    name: "PodcastUpload",
    component: PodcastUploadScreen,
  },
];

const MusicProvider: TurboProvider = {
  screens: {
    screens: Screens,
  },
  key: "music",
};

export {
  Screens,
  CoversCarousel,
  PlayerScreen,
  MusicDemoScreen,
  ProgressSlider,
  ProgressLine,
  MusicQueueScreen,
  MusicHomeScreen,
  MusicStylePickerScreen,
  PlayButton,
  PodcastCellItem,
  PodcastScreen,
  PodcastEditorScreen,
  PodcastEditorChaptersListScreen,
  PodcastEditorChapterScreen,
  PodcastUploadScreen,
  PodcastHeader,
  PodcastChapterList,
  PodcastChapterItem,
  PlayerMiniCompat,
  PlayerScreenCompat,
  VolumeSlider,
  PlaybackPositionSync,
  MusicProvider,
};
