import { Environment } from "@app/config";
import axios from "axios";
import useAxios, {
  configure as configureAxios,
  makeUseAxios,
} from "../../utils/hooks/axios";
import { trimStart } from "lodash";
import DeviceInfo from "react-native-device-info";
import * as ExpoDevice from "expo-device";
import { Base64 } from "js-base64";
import { Platform } from "react-native";
import Constants from "expo-constants";

let defaultHeaders = {
  "Access-Control-Allow-Origin": "*",
};

const filterDeviceValue = (value?: string) => {
  return value === "unknown" || value === "" ? null : value;
};

const uid =
  filterDeviceValue(DeviceInfo.getUniqueId()) || Constants.installationId;

const info = {
  platform: {
    //type: Platform.OS,
    type: filterDeviceValue(DeviceInfo.getDeviceType()),
    name:
      Platform.OS === "web" ? ExpoDevice.osName : DeviceInfo.getSystemName(), //filterDeviceValue(DeviceInfo.getBaseOsSync()), //ExpoDevice.osName, //DeviceInfo.getSystemName(),
    version: ExpoDevice.osVersion, // DeviceInfo.getSystemVersion(),
    build: DeviceInfo.getBuildId(),
  },
  app: {
    package_name: filterDeviceValue(DeviceInfo.getBundleId()),
    version: filterDeviceValue(DeviceInfo.getVersion()),
    build: filterDeviceValue(DeviceInfo.getBuildNumber()),
  },
  device: {
    model: filterDeviceValue(DeviceInfo.getModel()),
    manufacturer: filterDeviceValue(DeviceInfo.getManufacturerSync()),
    name: filterDeviceValue(DeviceInfo.getDeviceNameSync()),
    is_emulator: DeviceInfo.isEmulatorSync(),
    uid: uid,
    year_class: ExpoDevice.deviceYearClass,
    jailbroken: null,
  },
  install: {
    referer: filterDeviceValue(DeviceInfo.getInstallReferrerSync()),
    first_time: DeviceInfo.getFirstInstallTimeSync(),
    installer: filterDeviceValue(DeviceInfo.getInstallerPackageNameSync()),
  },
};

if (__DEV__) {
  //console.warn("UID: " + uid);
  //console.log("Device Info", info);
}

const headers = {
  "X-Info": Base64.encode(JSON.stringify(info)),
  "X-Tenant": Environment.id,
};

const instance = axios.create({
  baseURL: Environment.apiUrl,
  //timeout: 30000,
  responseType: "json",
  headers: defaultHeaders,
  withCredentials: true,
  headers,
  /* headers: {
        'Accept': 'application/json',
        'X-App' : 'Makinaio',
        'Authorization': global.authy.accessToken,
      } */
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      //console.warn("SHIT");
      //delete instance.defaults.headers.common.Authorization;
      //Auth.getInstance().logout();
    }

    return Promise.reject(error);
  }
);

const useApiRequest = makeUseAxios({ axios: instance });

const url = (path?: string) => {
  return Environment.host + trimStart(path, "/");
};

const dashboardUrl = (path: string = "/") => {
  return (
    Environment.dashboardUrl +
    trimStart(path.replace(Environment.dashboardUrl, ""), "/")
  );
};

const configure = (accessToken?: string) => {
  if (instance && accessToken) {
    // instance.defaults.headers.common.Groovr = `From the groove to the beat`;
    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
};

const clear = () => {
  if (instance) {
    delete instance.defaults.headers.common.Authorization;
  }
};

export { instance, url, dashboardUrl, configure, clear, useApiRequest };
