import { Appearance, SuperAppearance } from "@app/music/screens/player_compat";
import tinycolor from "tinycolor2";
import { clone } from "lodash";

export default (appearance: Appearance): SuperAppearance => {
  let superAppearance: SuperAppearance = clone(appearance);
  const color = tinycolor(appearance.colors.primary);
  superAppearance.theme = color.isDark() ? "dark" : "light";
  superAppearance.colors.text = color.isDark() ? "#ffffff" : "#000000";
  superAppearance.text = {
    primary: color.isDark() ? "#ffffff" : "#000000",
    secondary: tinycolor(appearance.colors.secondary).isDark()
      ? "#ffffff"
      : "#000000",
    tertiary: tinycolor(appearance.colors.tertiary).isDark()
      ? "#ffffff"
      : "#000000",
  };

  return superAppearance;
};
