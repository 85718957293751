//import { DarkTheme } from "@react-navigation/native";
import Theme from "./components/theme/theme";
import ExtendTheme from "./components/theme/ExtendedTheme";
import { StyleSheet } from "react-native";
import Avatar from "./components/avatar/avatar";
import AvatarUpload from "./components/avatar/avatar_upload";
import BackgroundGradient from "./components/background/gradient";
import BackButton from "./components/button/back_button";
import BoxResponsive from "./components/box/responsive_box";
import Button from "./components/button/button";
import Actionable from "./components/button/actionable";
import ScaledButton from "./components/button/scaled_button";
import HeaderButton from "./components/button/header_button";
import HeaderButtonGroup from "./components/button/header_button_group";
import HeaderBar from "./components/navigator/header_bar";
import Card from "./components/card/card";
import CellGroup from "./components/cellgrid/cell_group";
import CellItem from "./components/cellgrid/cell_item";
import Modal from "./components/modal/super_modal";
import Spinner from "./components/spinner/spinner";
import ImageFit from "./components/image/image_fit";
import ImageBox from "./components/image/image_box";
import { Image } from "react-native"; //"react-native-fast-image";
import SidebarHeader from "./components/sidebar/sidebar_header";
import SidebarButton from "./components/sidebar/sidebar_button";
import Text from "./components/text/text";
import TurboSymbol from "./components/icons/symbol";
import { tailwind, getColor } from "./tailwind";
import createStackNavigator from "./components/navigator/stack";
import Feed from "./feed/feed";
import FeedApi from "./feed/feed_api";
import GlassView from "./components/view/glass";
import ExtendedView from "./components/view/extended_view";
import superAppearance from "@app/ui/utils/superAppearance";
import FormRow from "./forms/form_row";
import TextInput from "./forms/text_input";
import SelectInput from "./forms/select_input";
import StatePlaceholder from "./components/state/state";
import TextError from "./forms/text_error";
import { useToast } from "react-native-toast-notifications";
import BadgePill from "./components/badge/pill";
import ToastProvider from "./providers/ToastProvider";
import ContextMenu from "./components/menu/ContextMenu";

const Providers = {
  ToastProvider,
};

export {
  Avatar,
  AvatarUpload,
  Actionable,
  BackgroundGradient,
  BackButton,
  BadgePill,
  BoxResponsive,
  Button,
  Card,
  CellGroup,
  CellItem,
  createStackNavigator,
  Theme,
  ExtendTheme,
  GlassView,
  HeaderButton,
  HeaderButtonGroup,
  HeaderBar,
  ScaledButton,
  Image,
  ImageBox,
  ImageFit,
  Modal,
  Spinner,
  StyleSheet,
  SidebarHeader,
  SidebarButton,
  tailwind,
  getColor,
  Text,
  TurboSymbol,
  Feed,
  FeedApi,
  superAppearance,
  FormRow,
  TextInput,
  SelectInput,
  StatePlaceholder,
  TextError,
  useToast,
  ExtendedView,
  Providers,
  ContextMenu,
};
