"use strict";
import React, {
  Component,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  BackHandler,
  Dimensions,
  NativeEventSubscription,
  Platform,
  SafeAreaView,
  TouchableWithoutFeedback,
  View,
  Text,
  StyleSheet,
  Pressable,
} from "react-native";

import { Modalize } from "react-native-modalize";

import { BlurView } from "expo-blur";
import * as ReactNativeHapticFeedback from "expo-haptics";
import { ImpactFeedbackStyle } from "expo-haptics";
import { BoxResponsive, Card } from "@app/ui";
import getBackgroundColor from "expo-blur/build/getBackgroundColor";
import { Portal } from "react-native-portalize";

interface Props {
  isVisible: boolean;
  modalProps?: ModalProps;
  blur?: boolean;
  children?: ReactNode;
  onClose?: Function;
  onOpen?: Function;
  hapticFeedback?: boolean;
}

interface State {
  isVisible: boolean;
  isLoading: boolean;
  blur: boolean;
}

export default (props: Props) => {
  const modalRef = useRef<Modalize>(null);
  const [isVisible, setIsVisible] = useState<boolean>(props.isVisible);
  useEffect(() => {
    setIsVisible(props.isVisible);
    if (props.isVisible) {
      modalRef.current?.open();
      if (props.hapticFeedback && Platform.OS !== "web") {
        ReactNativeHapticFeedback.impactAsync(ImpactFeedbackStyle.Light);
      }
      props.onOpen && props.onOpen();
    } else {
      modalRef.current?.close();
      props.onClose && props.onClose();
    }
  }, [props.isVisible]);

  const close = () => {
    //modalRef.current?.close();
    props.onClose && props.onClose();
  };

  return (
    <Portal>
      <Modalize
        ref={modalRef}
        adjustToContentHeight={true}
        handlePosition={"inside"}
        onClosed={close}
        modalStyle={{
          backgroundColor: "transparent",
          maxWidth: 800,
          //alignSelf: "center",
        }}
      >
        <Card blur={false} rounded={true}>
          {props.children}
        </Card>
      </Modalize>
    </Portal>
  );
};

class CustomModal extends Component<Props, State> {
  backHandler: NativeEventSubscription;

  static defaultProps: Props = {
    blur: true,
    hapticFeedback: false,
    isVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: this.props.isVisible,
      blur: Platform.OS === "ios" ? true : this.props.blur,
      isLoading: false,
    };

    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",

      this.handleBackPress.bind(this)
    );
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.isVisible !== state.isVisible) {
      //console.warn('CHANGES ', { visible: props.isVisible });
      if (props.isVisible && Platform.OS !== "web") {
        ReactNativeHapticFeedback.impactAsync(ImpactFeedbackStyle.Light);
      }
      return {
        isVisible: props.isVisible,
      };
    }

    return null;
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.backHandler.remove();
  }

  handleBackPress() {
    if (this.state.isVisible) {
      this.close();
      return true;
    }

    return false;
  }

  close() {
    //this.setState({ isVisible: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  renderNative() {
    let modalProps = this.props.modalProps || {};
    if (this.state.blur && Platform.OS === "ios") {
      modalProps.customBackdrop = (
        <Pressable
          style={[StyleSheet.absoluteFill]}
          onPress={this.close.bind(this)}
        >
          <BlurView
            style={[StyleSheet.absoluteFill]}
            tint="dark"
            intensity={100}
          ></BlurView>
        </Pressable>
      );
      modalProps.backdropOpacity = 0.95;
    }

    let style = { margin: 0 };
    if (Dimensions.get("window").width < 500) {
      style.justifyContent = "flex-end";
    }

    if (Platform.OS === "web") {
      if (this.props.isVisible) {
        style = {
          ...style,
          display: "block",
        };
      } else {
        style = {
          ...style,
          display: "none",
        };
      }
      // console.warn("modal", { visible: this.props.isVisible });

      // modalProps.customBackdrop = (
      //   <TouchableWithoutFeedback onPress={this.close.bind(this)}>
      //     <View style={{}}></View>
      //   </TouchableWithoutFeedback>
      // );
    }
    const modalRef = useRef<Modalize>(null);

    const modalStyle = {
      //backgroundColor: "red",
    };

    return (
      <Portal>
        <Modalize
          ref={modalRef}
          adjustToContentHeight={true}
          modalStyle={modalStyle}
          childrenStyle={modalStyle}
        >
          {this.props.children}
        </Modalize>
      </Portal>
    );

    return (
      <Modal
        isVisible={this.state.isVisible}
        onBackdropPress={this.close.bind(this)}
        onSwipeComplete={this.close.bind(this)}
        onModalShow={() => (this.props.onOpen ? this.props.onOpen() : null)}
        swipeThreshold={120}
        style={style}
        backdropTransitionOutTiming={0}
        backdropOpacity={0.8}
        hideModalContentWhileAnimating={Platform.OS === "android"}
        useNativeDriver={false}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        swipeDirection="down"
        {...modalProps}
      >
        <BoxResponsive borderRadius={0}>{this.props.children}</BoxResponsive>
      </Modal>
    );
  }

  renderWeb() {
    return (
      <WebModal
        isOpen={this.props.isVisible}
        //onAfterOpen={this.close.bind(this)}
        //shouldCloseOnOverlayClick={true}
        onRequestClose={this.close.bind(this)}
        closeTimeoutMS={300}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,.8)",
          },
          content: {
            backgroundColor: "transparent",
            border: "none",
            flex: 1,
            display: "flex",
            margin: 0,
            // top: 0,
            // left: 0,
            // right: 0,
            // bottom: 0,
            // margin: 0,
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
          onClick={this.close.bind(this)}
        >
          <BoxResponsive borderRadius={0}>{this.props.children}</BoxResponsive>
        </div>
      </WebModal>
    );
  }

  render() {
    return Platform.OS === "web" ? this.renderWeb() : this.renderNative();
  }
}

//export default CustomModal;
