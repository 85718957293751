import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { ScaledButton, Text, Theme } from "@app/ui";
import { LinearGradient } from "expo-linear-gradient";

interface Props {
  name: string;
  slug: string;
  style?: any;
  onPress?: any;
  colors?: string[];
}

export default ({
  name,
  slug,
  style,
  colors = ["#ff512f", "#dd2476"],
  onPress,
}: Props) => {
  return (
    <ScaledButton onPress={onPress} key={slug || name}>
      <LinearGradient
        style={{
          height: 15,
          borderRadius: 8,
          paddingHorizontal: 8,
          ...style,
        }}
        colors={colors}
        start={{ x: 0.5, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        <Text
          style={{
            textTransform: "uppercase",
            lineHeight: 16,
            fontSize: 9,
            fontFamily: Theme.fonts.titles.medium,
          }}
        >
          {name}
        </Text>
      </LinearGradient>
    </ScaledButton>
  );
};
