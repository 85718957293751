"use strict";
import React, { Component, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Linking,
  Dimensions,
  SafeAreaView,
  Pressable,
} from "react-native";
import {
  TouchableOpacity,
  BorderlessButton,
  RectButton,
  BaseButton,
  ScrollView,
} from "react-native-gesture-handler";
import { includes } from "lodash";
import {
  StyleSheet,
  CellGroup,
  CellItem,
  BoxResponsive,
  Button,
  BackButton,
  ImageBox,
  Text,
  Avatar,
} from "@app/ui";
import { Modalize } from "react-native-modalize";
import { Host, Portal } from "react-native-portalize";
import LoginModal from "@app/auth/components/login.modal";
import { connect, useDispatch, useSelector } from "react-redux";
import * as authActions from "@app/user/actions/auth.action";

import DeviceInfo from "react-native-device-info";
import { dashboardUrl } from "@app/config/api/api";
import { Environment, isWeb } from "@app/config";
import { useAuthenticatedUser, useHasAppFeature } from "@app/user/hooks/user";
import { useQueryClient } from "react-query";

interface Props {
  componentId: string;
  user?: Object;
  loggedIn: boolean;
}

interface State {
  loginModalVisible: boolean;
}

const MeScreen = (props: Props) => {
  const { navigation } = props;
  const dispatch = useDispatch();
  const shouldBeMe = props.route?.me || false;

  const user = useAuthenticatedUser();

  const isLoggedIn = user?.authenticated;

  const [loginModalVisible, setLoginModalVisible] = useState(false);
  //const [isMe, setIsMe] = useState(user?.id === );

  const canUsePayments = useHasAppFeature("payments");

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: user?.profile?.name || "",
      //headerShown: false,
      headerRight: () => (
        <>
          <BackButton
            onPress={() =>
              props.navigation.push("UserSettings", {
                screen: "UserSettings",
                stackPresentation: "formSheet",
              })
            }
            symbol={"settings-solid"}
          />
          {/*<BackButton*/}
          {/*  onPress={() =>*/}
          {/*    props.navigation.push("Modal", {*/}
          {/*      screen: "StreamBroadcast",*/}
          {/*      stackPresentation: "formSheet",*/}
          {/*    })*/}
          {/*  }*/}
          {/*  symbol={"broadcast-solid"}*/}
          {/*  title={"Debug"}*/}
          {/*/>*/}
        </>
      ),
      //headerLeft: () => null,
    });
  }, [props.navigation, user]);

  const renderLoginModal = () => {
    return (
      <LoginModal
        isVisible={loginModalVisible}
        onClose={() => {
          setLoginModalVisible(false);
        }}
      />
    );
  };

  const renderUserHeader = () => {
    const avatarSize = 88;
    return (
      <View style={{ marginHorizontal: 20, marginVertical: 8 }}>
        <View>
          <View style={{ width: avatarSize }}>
            <Avatar
              size={avatarSize}
              name={user?.profile.name}
              image={user?.profile?.avatar}
            />
          </View>
        </View>
      </View>
    );
  };

  const renderUserDescription = () => {
    return (
      <View style={{ marginHorizontal: 20, marginVertical: 8 }}>
        <Text>{user?.profile?.name}</Text>
      </View>
    );
  };

  const renderLoggedIn = () => {
    return (
      <BoxResponsive>
        {renderUserHeader()}
        {renderUserDescription()}
      </BoxResponsive>
    );
  };

  const renderNotLoggedIn = () => {
    return (
      <View style={{ paddingHorizontal: 26, paddingTop: 8 }}>
        <Text style={{ color: "#fff" }}>Not Logged In</Text>
        <Pressable onPress={() => setLoginModalVisible(true)}>
          <Text style={styles.link}>Login</Text>
        </Pressable>
        {renderLoginModal()}
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={{ flex: 1 }}>
        {isLoggedIn && user && renderLoggedIn()}
        {!isLoggedIn && renderNotLoggedIn()}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  link: {
    marginVertical: 16,
    color: "blue",
    //color: "$textColor",
  },
});

export default MeScreen;

//export default connect(mapStateToProps, mapDispatchToProps)(MeScreen);
