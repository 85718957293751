import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ImageBox, ScaledButton, Text, Theme } from "@app/ui";
import { View } from "react-native";

export enum ActivityType {
  None = "none",
  Viewed = "viewed",
  On = "on",
  Broadcasting = "broadcasting",
}

export enum StatusDot {
  Hidden = "hidden",
  Off = "off",
  On = "on",
  Connecting = "connecting",
}

interface Props {
  image?: any;
  size?: number;
  borderSize?: number;
  activity?: ActivityType;
  initials?: string;
  shadow?: boolean;
  separatorSize?: number;
  separatorColor?: string;
  onPress?: any;
  name?: string;
  statusDot?: StatusDot;
}

export default ({
  image,
  size = 32,
  borderSize = 3,
  activity = ActivityType.None,
  shadow = false,
  separatorColor = "#000",
  separatorSize = 2,
  onPress,
  name,
  statusDot = StatusDot.Hidden,
}: Props) => {
  const containerSize = size;
  const separatorSizeFinal = borderSize > 0 ? separatorSize : 0;
  const separatorContainerSize = containerSize - separatorSizeFinal * 2;
  const imageSize = size - borderSize * 2 - separatorSize * 2;

  const getInitials = (name) => {
    let initials = name.split(" ");

    if (initials.length > 1) {
      initials = initials.shift().charAt(0) + initials.pop().charAt(0);
    } else {
      initials = name.substring(0, 2);
    }

    return initials.toUpperCase();
  };

  const renderImage = () => {
    const avatarName = name ? getInitials(name) : "";
    const avatarNameSize = imageSize / 2.5;

    return (
      <View
        style={{
          width: separatorContainerSize,
          height: separatorContainerSize,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: separatorColor,
          borderRadius: separatorContainerSize / 2,
        }}
      >
        {image ? (
          <ImageBox
            source={{ uri: image }}
            width={imageSize}
            height={imageSize}
            aspectRatio={1}
            borderRadius={imageSize / 2}
            borderWidth={0}
          />
        ) : (
          <View
            style={{
              width: imageSize,
              height: imageSize,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#444",
              borderRadius: imageSize / 2,
            }}
          >
            <Text
              style={{
                fontSize: avatarNameSize,
                letterSpacing: 3,
                textAlign: "center",
                lineHeight: imageSize,
                fontFamily: Theme.fonts.text.medium,
              }}
              numberOfLines={1}
            >
              {avatarName}
            </Text>
          </View>
        )}
      </View>
    );
  };

  const renderView = () => {
    let style = {
      backgroundColor: "transparent",
    };

    if (activity === ActivityType.Viewed) {
      style = {
        backgroundColor: "rgba(255,255, 255, .5)",
      };
    } else if (activity === ActivityType.On) {
      style = {
        backgroundColor: "orange",
      };
    } else if (activity === ActivityType.Broadcasting) {
      style = {
        backgroundColor: "green",
      };
    }

    return (
      <View
        style={{
          width: containerSize,
          height: containerSize,
          borderRadius: containerSize / 2,
          backgroundColor: style.backgroundColor,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {onPress ? (
          <ScaledButton onPress={onPress} pointerMode={"lift"}>{renderImage()}</ScaledButton>
        ) : (
          renderImage()
        )}
      </View>
    );
  };

  return renderView();
};
