import { instance, configure, clear } from "@app/config/api/api";

export function getConversations(): any {
  return instance.request<ServerData>({
    url: "messenger/conversations",
    params: {},
  });
}

export function getConversation(id: number): any {
  return instance.request<ServerData>({
    url: "messenger/conversation/" + id,
    params: {},
  });
}

export function postMessage(conversation: number): any {
  return instance.request<ServerData>({
    method: "POST",
    url: "messenger/conversation/" + conversation,
    params: {},
  });
}
