import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import {
  Pressable,
  Platform,
  View,
  ImageSourcePropType,
  ImageStyle,
  NativeEventEmitter,
  StyleSheet,
  ViewStyle,
  StyleProp,
  Text,
} from "react-native";
import { Feed, HeaderButton, Spinner } from "@app/ui";
import { withApi } from "@app/config";
import { instance, instance as api } from "@app/config/api/api";
import { concat, get } from "lodash";
import { renderItemByName } from "@app/ui/feed/items";
import { QueryKey, useQuery } from "react-query";
import { useAuth } from "@app/user/hooks/user";

interface Props {
  path: string;
  responsePath?: string;
  params?: Object;
  header?: string;
  navigation?: any;
  scrollBar?: boolean;
  queryKey: QueryKey;
  navigationHeaderFake?: boolean;
}

const Item = (props: Props) => {
  //const [loading, setLoading] = useState(false);
  //const [currentPage, setCurrentPage] = useState(1);
  // const [data, setData] = useState([]);
  // const [error, setError] = useState(false);
  // const [loadMore, setLoadMore] = useState(false);
  // const [firstLoad, setFirstLoad] = useState(true);
  const responsePath = props.responsePath || "data";

  const useFeed = () => {
    return useQuery(
      props.queryKey,
      async () => {
        const { data } = await instance.get(props.path, {
          params: props.params,
        });
        const dataItems = get(data, responsePath);

        const headerItem = get(data, "data.header");

        return { items: dataItems, header: headerItem };
      },
      { enabled: true }
    );
  };

  const auth = useAuth();
  const { data, isLoading, refetch, isError } = useFeed();

  useEffect(() => {
    refetch();
  }, [auth.accessToken]);

  const headerC = data?.header
    ? renderItemByName(data.header?.type, data.header?.data)
    : null;

  return isLoading ? (
    <View style={{ flex: 1 }}>
      <Spinner />
    </View>
  ) : (
    <Feed
      data={data?.items}
      header={headerC}
      loadMore={false}
      loading={isLoading}
      error={isError}
      //page={currentPage}
      refreshControl={false}
      getItems={refetch}
      //navigationHeader={true}
      navigationHeaderFake={props.navigationHeaderFake}
    />
  );
};

export default Item;
