import Environment from "./environment/environment";
import NavigationLinking from "./navigation/linking";
import { Platform } from "react-native";
import HeaderWeb from "./navigation/headerWeb";
import withApi from "./api/withApi";
import ApiData from "./api/apiData";
import { AppContext } from "./context/AppContext";

const isElectron = window && window.process && window.process.type;
const isWeb = Platform.OS === "web";
const isWebNotElectron = isWeb && !isElectron;

export {
  Environment,
  NavigationLinking,
  isElectron,
  isWeb,
  isWebNotElectron,
  HeaderWeb,
  withApi,
  ApiData,
  AppContext,
};
