import * as React from "react";
import { View, StyleSheet } from "react-native";
import { Theme, Text } from "@app/ui";
import { RectButton } from "react-native-gesture-handler";

interface Props {
  text: string;
  size: "normal" | "big";
}

export default ({ text }: Props) => {
  return (
    <View>
      <Text style={[styles.headerText]}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {},
  headerText: {
    fontFamily: Theme.font.medium,
    fontSize: 15,
    marginVertical: 8,
    marginLeft: 8,
    textTransform: "uppercase",
    opacity: 0.4,
  },
  headerTextBig: {
    fontSize: 32,
  },
});
