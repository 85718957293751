import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import FontAwesome5Pro from "react-native-vector-icons/FontAwesome5Pro";
import { Theme, TurboSymbol } from "@app/ui";
import * as React from "react";
import { Environment } from "@app/config";

export default ({ scene, previous, navigation }) => {
  const { options } = scene.descriptor;
  const title =
    options.headerTitle !== undefined
      ? options.headerTitle
      : options.title !== undefined
      ? options.title
      : scene.route.name;

  //console.log(navigation);
  const canGoBack = navigation.canGoBack();

  return (
    <View
      style={{
        width: "100%",
        height: 64,
        backgroundColor: "transparent",
        justifyContent: "center",
        paddingHorizontal: 24,
        paddingVertical: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "left",
        WebkitUserSelect: "none",
        WebkitAppRegion: "drag",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "left",
          flex: 0.8,
        }}
      >
        {canGoBack && (
          <Pressable
            onPress={navigation.goBack}
            style={[
              styles.navigationButton,
              canGoBack ? null : styles.navigationButtonDisabled,
            ]}
          >
            <FontAwesome5Pro
              name="chevron-left"
              light
              size={22}
              color={Theme.colors.text}
            />
          </Pressable>
        )}

        {/*<Pressable onPress={history.forward} style={[styles.navigationButton]}>*/}
        {/*  <FontAwesome5Pro*/}
        {/*    name="chevron-right"*/}
        {/*    light*/}
        {/*    size={22}*/}
        {/*    color={Theme.colors.text}*/}
        {/*  />*/}
        {/*</Pressable>*/}

        <View style={{ flexGrow: 1 }}>
          <Text
            style={{
              color: "#fff",
              fontSize: 18,
              fontFamily: Theme.font.medium,
            }}
          >
            {title}
          </Text>
        </View>
      </View>
      <View style={{ flex: 0.2 }}>
        <View style={[styles.searchInputContainer]}>
          <TurboSymbol
            name="search-solid"
            size="12"
            style={[styles.searchInputIcon]}
            color={Theme.colors.text}
          />
          <TextInput
            placeholder={"Search on " + Environment.name + "..."}
            style={[styles.searchInputText]}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  navigationButton: {
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
    width: 32,
    height: 54,
    opacity: 1,
  },
  navigationButtonDisabled: {
    opacity: 0.5,
    ...Platform.select({
      web: {
        cursor: "default",
      },
    }),
  },
  navigationButtonIcon: {},
  searchInputContainer: {
    backgroundColor: Theme.colors.chromeHigh,
    marginTop: -6,
    paddingHorizontal: 16,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
  },
  searchInputText: {
    height: 38,
    lineHeight: 38,
    minWidth: 160,
    color: Theme.colors.text,
    fontFamily: Theme.font.regular,
    flex: 1,
    ...Platform.select({
      web: {
        textIndent: 12,

        outlineWidth: 0,
      },
    }),
  },
  searchInputIcon: {
    opacity: 0.5,
  },
});
