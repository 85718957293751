import * as Linking from "expo-linking";
import { Platform } from "react-native";
import { getPathFromState, getStateFromPath } from "@react-navigation/native";
import { Environment } from "@app/config";

const prefix =
  Platform.OS === "web" ? [Linking.makeUrl("/")] : Environment.linking;

const myScreens = {
  screens: {
    /**
     * Main routes
     */
    Root: "/",

    // PageScreen: {
    //   path: "page/:slug",
    //   // parse: {
    //   //   slug: (slug) => slug.replace(/^@/, ""),
    //   // },
    //   // screens: {
    //   //   Settings: "edit",
    //   // },
    // },

    NotificationsList: "notifications",
    UserProfile: { path: "me" },

    /**
     * Tabs
     */
    HomeTab: {
      initialRouteName: "Home",
      screens: {
        Home: "home",
      },
    },
    MusicTab: {
      initialRouteName: "MusicHome",
    },
    NewsTab: {
      initialRouteName: "NewsList",
    },
    GamesTab: {
      initialRouteName: "GamesList",
    },
    CollectionsTab: {
      initialRouteName: "GamesCollectionsList",
    },
    ActivityTab: {
      initialRouteName: "ChatConversationsList",
    },
    MeTab: {
      initialRouteName: "UserProfile",
    },
  },
};

const config = {
  ...myScreens,
};

const navigationLinking = {
  prefixes: prefix,
  config,
  getStateFromPathX: (path, options) => {
    console.warn("path", path);

    if (path.startsWith("podcast/")) {
      // return {
      //   routes: [{}],
      // };
    }

    //     if (path.startsWith("/page")) {
    // //      console.warn("HOLA");
    // //      alert(path);
    //       return {
    //         routes: [
    //           {
    //             name: "PageScreen",
    //           },
    //         ],
    //       };
    //     }

    const data = getStateFromPath(path, options);
    console.warn("path_data", data);

    return data;
  },
  getPathFromStateX(state, config) {
    //alert(state);
    const path = getPathFromState(state, config);
    //console.warn("FROM STATE return " + path, state);

    return path;
  },
};

export default navigationLinking;
