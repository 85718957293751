import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Platform, View } from "react-native";
import {
  Avatar,
  BackButton,
  BackgroundGradient,
  ContextMenu,
  HeaderBar,
  ImageBox,
  Text,
  Theme,
} from "@app/ui";
import {
  useAuth,
  useHasAppFeature,
  useHasAppRole,
  useHasAppRoles,
} from "@app/user/hooks/user";
import { useNavigation } from "@react-navigation/native";
import ContentCreateModal from "@app/studio/common/screens/ContentCreateModal";

interface Props {}

export default (props: Props) => {
  const navigation = props.navigation || useNavigation();
  const auth = useAuth();
  const avatarSize = 52;
  const avatarUrl =
    auth?.user?.profile?.avatar ||
    "https://dsc.cloud/9b3e32/yyEkeJ6otZ5wc3UrL89acTUDGfTcSsUYq2MmQzsS6tfyTcxxYSY1HftqKMsFbnPAxkuFkYL4YR5zHztwuQDT8n3uAV40wL0QhOTp.png";
  const canStream = useHasAppFeature("stream");
  const canDebug = useHasAppFeature("debug");
  const canUsePoints = useHasAppFeature("points");
  const canAdmin = useHasAppRole("admin");

  const [createModalVisible, setCreateModalVisible] = useState(false);

  const renderNavigationBar = () => {
    const points = auth?.user?.points?.amount || 0;
    // const pointsAmount =
    //   Platform.OS === "ios" ? Intl.NumberFormat().format(points) : points;
    const pointsAmount = points;

    return;

    return (
      <HeaderBar
        safeArea={true}
        statusBar={false}
        rightBar={() => (
          <>
            {/*{canDebug && __DEV__ && (*/}
            {/*  <BackButton*/}
            {/*    onPress={() => navigation.push("DebugList", { demo: true })}*/}
            {/*    symbol={"debug-solid"}*/}
            {/*  />*/}
            {/*)}*/}
            {canAdmin && (
              <ContextMenu
                items={[
                  {
                    id: "stream",
                    title: "Stream",
                    image: "broadcast-solid",
                    onPress: () => navigation.navigate("StreamBroadcast"),
                  },
                  {
                    id: "create_page",
                    title: "Create Page",
                    onPress: () => navigation.navigate("PageCreate"),
                  },
                  {
                    id: "create_podcast",
                    title: "Create Podcast",
                    onPress: () => navigation.navigate("PodcastCreate"),
                  },
                ]}
              >
                <BackButton
                  symbol={"game-crosshair-solid"}
                  onPress={() => null}
                />
              </ContextMenu>
            )}
            {/*{canStream && (*/}
            {/*  <BackButton*/}
            {/*    onPress={() => navigation.push("StreamBroadcast", {})}*/}
            {/*    symbol={"broadcast-solid"}*/}
            {/*    title={"Stream"}*/}
            {/*  />*/}
            {/*)}*/}
            {canUsePoints && (
              <BackButton
                onPress={() => navigation.push("Wallet", { demo: true })}
                title={pointsAmount}
                symbol={"groovr-solid"}
              />
            )}
            {/*<BackButton*/}
            {/*  onPress={() =>*/}
            {/*    navigation.push("Modal", {*/}
            {/*      screen: "StreamBroadcast",*/}
            {/*      stackPresentation: "formSheet",*/}
            {/*    })*/}
            {/*  }*/}
            {/*  symbol={"dots-solid"}*/}
            {/*/>*/}
          </>
        )}
      />
    );
  };

  return (
    <>
      {/*<BackgroundGradient color={"#444"} height={600} marginTop={-120} />*/}
      {/*{renderNavigationBar()}*/}
      <View
        style={{
          marginTop: 16,
          marginHorizontal: 20,
          marginBottom: 20,
          flex: 1,
          flexDirection: "row",
        }}
      >
        <View style={{ marginRight: 12 }}>
          <Avatar
            image={avatarUrl}
            onPress={() => navigation.push("UserSettings")}
            size={avatarSize}
          />
        </View>
        <View style={{ justifyContent: "center" }}>
          <Text
            style={{
              fontFamily: Theme.fonts.titles.medium,
              fontSize: 17,
              lineHeight: 20,
              letterSpacing: -0.3,
            }}
          >
            Good afternoon, {auth?.user?.profile?.name}
          </Text>
          <Text
            style={{
              fontFamily: Theme.fonts.titles.regular,
              fontSize: 13,
              lineHeight: 20,
              opacity: 0.5,
            }}
          >
            Welcome to GROOVR
          </Text>
        </View>
      </View>
    </>
  );
};
