import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Alert, ScrollView, View } from "react-native";
import {
  BoxResponsive,
  Button,
  FormRow,
  Spinner,
  StatePlaceholder,
  Text,
  TextInput,
} from "@app/ui";
import * as yup from "yup";
import { Formik } from "formik";

import { SelectInputItem } from "@app/ui/forms/select_input";
import { StackActions, useNavigation } from "@react-navigation/native";
import { instance, useApiRequest } from "@app/config/api/api";
const validationSchema = yup.object().shape({
  name: yup.string().required().label("Name").min(4).max(64).nullable(),
  slug: yup.string().required().label("Username").nullable(),
  url: yup.string().url().nullable(),
});

interface Props {}

export default (props: Props) => {
  const url = "page/" + props.route.params.id + "/manager";
  const [{ data: item, loading, error }, postCreate] = useApiRequest(
    {
      url: url,
      method: "GET",
    },
    { manual: false }
  );

  const page = item?.data;

  const publishForm = (data, actions) => {
    //console.warn("form", data);
    instance
      .post(url, data)
      .then((response) => {
        actions.setSubmitting(false);
        Alert.alert("Saved");
      })
      .catch((e) => {
        if (e.response.status === 422) {
          actions.setErrors(e.response.data?.errors);
        } else {
          Alert.alert("unknown error");
        }
        actions.setSubmitting(false);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  if (!page) {
    return <StatePlaceholder text={"Error"} />;
  }

  return (
    <Formik
      initialValues={{ name: page?.name, slug: page.slug, url: page.url }}
      onSubmit={(values, actions) => publishForm(values, actions)}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <ScrollView style={{ flex: 1 }}>
          <BoxResponsive>
            <FormRow>
              <TextInput
                name="name"
                placeholder="Name"
                formik={formik}
                value={formik.values.name}
              />
            </FormRow>
            <FormRow>
              <TextInput
                name="slug"
                placeholder="Username / Slug"
                formik={formik}
                value={formik.values.slug}
              />
            </FormRow>
            <FormRow>
              <TextInput
                name="url"
                placeholder="URL / Website"
                formik={formik}
                value={formik.values.url}
                keyboardType={"url"}
                autoCapitalize={"none"}
                textContentType={"URL"}
              />
            </FormRow>
            <FormRow>
              <Button
                text="Save"
                onPress={formik.handleSubmit}
                isLoading={formik.isSubmitting}
              />
            </FormRow>
          </BoxResponsive>
        </ScrollView>
      )}
    </Formik>
  );
};
