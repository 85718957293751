import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { instance } from "@app/config/api/api";

export const useIsFollowing = (
  type: string,
  id: string,
  fetch: boolean = true
) => {
  const fn = async ({}) => {
    const path = type + "/" + id + "/following";
    return instance.get(path);
  };

  return useQuery(["following", type, id], fn, {
    enabled: fetch,
    select: (data) => {
      return data?.data?.data;
    },
  });
};

export const useFollowMutation = (type: string, id: any) => {
  const queryClient = useQueryClient();
  const queryKey = ["following", type, id];

  const fn = ({ follow, notify }) => {
    const path = type + "/" + id + "/follow";
    return follow ? instance.post(path) : instance.delete(path);
  };

  return useMutation(fn, {
    //mutationKey: queryKey,
    // onSuccess: (data, variables, context) => {
    //   //console.warn("k", ["following", variables.type, variables.id]);]
    //   // console.warn("qk", queryKey);
    //   // console.warn("data", data?.data?.data);
    //
    //   queryClient.setQueryData(queryKey, data?.data?.data);
    // },
    // onMutate: (variables) => {
    //   console.warn("v", variables);
    //   queryClient.setQueryData(queryKey, (previous) => {
    //     return { test: 1 };
    //   });
    //
    //   return { is_following: variables.follow };
    // },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(queryKey, data);
    },
    onError: (error, variables, context) => {
      console.error("e", error);
    },
  });
};

export const follow = (type: string, id: any) => {};
