"use strict";
import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Dimensions,
  Alert,
  Pressable,
  Platform,
} from "react-native";
import { StyleSheet, Theme, Image, ImageBox, Actionable } from "@app/ui";
import { getGames } from "@app/games/services/data";
import { concat, find } from "lodash";
import { isWeb } from "@app/config";
import {
  useContentCompactWidth,
  useContentWidth,
  useIsLargeScreen,
  useNumberOfColumnsForFullScreen,
} from "@app/utils/hooks/screen_dimensions";

interface Props {
  navigation: any;
  query?: Object;
}

interface State {
  loading: boolean;
  loadMore: boolean;
  page: number;
  games: Array<any>;
  displayMode: GameListDisplayMode;
  displayModelColumnOptions: GameListDisplayModelColumnOptions;
}

interface GameListDisplayModelColumnOptions {
  showTitles: boolean;
  numberOfColumns: number;
}

enum GameListDisplayMode {
  List,
  Column,
}

const GamesScreen = (props: Props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     loading: false,
  //     page: 1,
  //     games: [],
  //     loadMore: true,
  //     displayMode: GameListDisplayMode.Column,
  //     displayModelColumnOptions: {
  //       showTitles: true,
  //       numberOfColumns: numberOfColumns
  //     },
  //   };
  // }

  const deviceWidth = useContentWidth();
  const itemSize = useIsLargeScreen() ? 180 : 120;
  const coverSize = itemSize - 16;
  const rowSize = Math.floor(deviceWidth / itemSize);
  const numberOfColumns = useNumberOfColumnsForFullScreen(itemSize);

  const { navigation } = props;
  const { query } = props.route.params || {};
  const title = props.route.params?.title || "Games";
  //const navigation = useNavigation();

  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [games, setGames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayMode, setDisplayMode] = useState(GameListDisplayMode.Column);
  const [displayModelColumnOptions, setDisplayModelColumnOptions] = useState({
    showTitles: true,
    numberOfColumns,
  });

  useLayoutEffect(() => {
    navigation.setOptions({
      title: title,
    });
  });

  useEffect(() => {
    if (!loading) getMyGames(currentPage);
  }, [currentPage]);

  const getMyGames = (page: number) => {
    if (page > 1) {
      setLoading(true);
    }

    getGames(page, query).then((response) => {
      const { data } = response;

      const mergedGames = page === 1 ? data.data : concat(games, data.data);

      setLoadMore(data.links.next ? true : false);
      setGames(mergedGames);
      setCurrentPage(page);
      setLoading(false);

      // this.setState({
      //   loading: false,
      //   loadMore: data.links.next ? true : false,
      //   games: games,
      // });
      // `data` is of type ServerData, correctly inferred
    });
  };

  const handleLoadMore = () => {
    if (loadMore) {
      //!this.state.loading &&
      setCurrentPage(currentPage + 1);
      // method for API call
    }
  };

  const renderSeparator = () => {
    return (
      <View
        style={{
          height: 2,
          width: "100%",
          //backgroundColor: "#fff",
          borderBottomColor: "#CED0CE",
          borderBottomWidth: 0,
        }}
      />
    );
  };

  const renderFooter = () => {
    //it will show indicator at the bottom of the list when data is loading otherwise it returns null
    if (!loading) return null;
    return <ActivityIndicator size="large" style={{ margin: 32 }} />;
  };

  const renderGameRow = ({ item }) => {
    return (
      <Pressable
        key={"game-" + game.id}
        underlayColor={Theme.colors.text}
        onPress={() => openGame(item)}
      >
        <View
          style={{
            flexDirection: "row",
            paddingHorizontal: 16,
            paddingVertical: 8,
            alignItems: "center",
          }}
        >
          {find(item.media, { type: "cover" }) && (
            <Image
              source={{
                uri: find(item.media, { type: "cover" }).thumb_url,
              }}
              style={{
                borderRadius: 4,
                height: 72,
                width: 72,
                marginRight: 16,
                borderWidth: 0.5,
                borderColor: "rgba(255,255,255,.3)",

                //aspectRatio: _.find(item.media, { type: "cover" }).aspect_ratio,
              }}
              backgroundColor={
                find(item.media, { type: "cover" }).colors.primary
              }
            />
          )}
          <View style={{ flex: 1 }}>
            <Text style={styles.gamesTitleText}>{item.name}</Text>
            <Text style={styles.gamesDescriptionText} numberOfLines={3}>
              {item.summary}
            </Text>
          </View>
        </View>
      </Pressable>
    );
  };

  const renderGameCell = ({ item }) => {
    return (
      <Actionable
        style={{
          display: "flex",
          flex: 1 / numberOfColumns,
          alignItems: "space-between",
          borderRadius: 8,
          padding: 4,
        }}
        onPress={() => openGame(item)}
      >
        <View
          style={{
            padding: 8,
            alignItems: "center",
            flex: 1,
            justifyContent: "flex-end",
          }}
        >
          {find(item.media, { type: "cover" }) && (
            <ImageBox
              source={{
                uri: find(item.media, { type: "cover" }).thumb_url,
              }}
              // sourceThumb={{
              //   uri: find(item.media, { type: "cover" }).thumb_url,
              // }}
              width={coverSize}
              aspectRatio={find(item.media, { type: "cover" }).aspect_ratio}
              backgroundColor={
                find(item.media, { type: "cover" }).colors.primary
              }
              // style={{
              //   borderRadius: 2,
              //   width: 72,
              //   height: 72 * _.find(item.media, { type: "cover" }).aspect_ratio,
              //   borderWidth: 0.5,
              //   borderColor: "rgba(255,255,255,.3)",
              //   //aspectRatio: _.find(item.media, { type: "cover" }).aspect_ratio,
              //   backgroundColor: _.find(item.media, { type: "cover" })!.colors!
              //     .primary,
              // }}
            />
          )}
          {displayModelColumnOptions.showTitles && (
            <Text
              style={[styles.gamesTitleText, styles.gamesTitleTextColumn]}
              numberOfLines={2}
              adjustsFontSizeToFit={true}
            >
              {item.name}
            </Text>
          )}
        </View>
      </Actionable>
    );
  };

  const openGame = (item) => {
    navigation.push("GameDetail", {
      id: item.id,
      ...Platform.select({ native: { gameOriginal: item } }),
    });
  };

  if (loading && !games) {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {/* <ActivityIndicator style={{ color: "#000" }} /> */}
      </View>
    );
  }

  if (displayMode == GameListDisplayMode.Column) {
    return (
      <View style={{ flex: 1 }}>
        <FlatList
          data={games}
          numColumns={numberOfColumns}
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={() => getMyGames(1)}
            />
          }
          // columnWrapperStyle={{
          //   flex: 1,
          //   justifyContent: "space-around",
          // }}
          key={"games-" + numberOfColumns}
          renderItem={renderGameCell}
          keyExtractor={(item, index) => index.toString()}
          ItemSeparatorComponent={() => (
            <View
              style={{
                height: 4,
                flex: 1,
                backgroundColor: "rgba(255,255,255,.1)",
                //marginTop: -18,
              }}
            ></View>
          )}
          ListFooterComponent={renderFooter}
          onEndReachedThreshold={0.4}
          onEndReached={handleLoadMore}
        />
      </View>
    );
  } else {
    return (
      <View style={{ flex: 1 }}>
        <FlatList
          data={games}
          numColumns={1}
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={() => getMyGames(1)}
            />
          }
          renderItem={renderGameRow}
          keyExtractor={(item, index) => index.toString()}
          ItemSeparatorComponent={renderSeparator}
          ListFooterComponent={renderFooter}
          onEndReachedThreshold={0.4}
          onEndReached={handleLoadMore}
        />
      </View>
    );
  }
};

const styles = StyleSheet.create({
  gamesTitleText: {
    fontSize: 17,
    //fontWeight: 'bold',
    fontFamily: Theme.font.medium, //"$fontFamilyMedium",
    alignItems: "center",
    color: Theme.colors.text, // "$textColor",
    letterSpacing: -0.7,
  },
  gamesDescriptionText: {
    fontSize: 14,
    alignItems: "center",
    marginTop: 4,
    color: Theme.colors.text, // "$textColor",
    fontFamily: Theme.font.regular, // "$fontFamilyRegular",
  },
  gamesTitleTextColumn: {
    fontFamily: Theme.font.regular, // "$fontFamilyRegular",
    letterSpacing: -0.3,
    fontSize: 12,
    marginTop: 8,
    textAlign: "center",
    lineHeight: 14,
    height: 32,
  },
});

export default GamesScreen;
