import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Platform, SafeAreaView, View } from "react-native";
import { Sidebar, SidebarActivity } from "@app/layout";
import AppNavigator from "@app/config/navigation/stack";
import { Theme, Text } from "@app/ui";
import { useScreenProperties } from "@app/utils/hooks/screen_dimensions";
import { Environment } from "@app/config";
import { useAuth, useMe } from "@app/user/hooks/user";
import { Host, Portal } from "react-native-portalize";
import { AuthenticatedUserContext } from "@app/user/hooks/user";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/redux/initialState";
import {
  PlaybackPositionSync,
  PlayerMiniCompat,
  PlayerScreenCompat,
} from "@app/music";
import { Modalize } from "react-native-modalize";
import { useNavigation } from "@react-navigation/native";
import { TurboApp } from "@app/core";
import {useAppContext} from "@app/config/context/AppContext";

let PlayerBar: JSX.IntrinsicAttributes | null = null;
if (Platform.OS === "web" && Environment.features.music.enabled) {
  PlayerBar = require("@app/music/components/player_bar").default;
}

interface Props {
  onIsReady?: any;
  turboApp: typeof TurboApp;
}

export default (props: Props) => {
  const appContext = useAppContext()
  const { status, data, error, isFetching, refetch } = useMe(true);
  const screenProperties = useScreenProperties();
  const useMusicPlayer = Environment.features.music.enabled;
  const isLoggedIn = data?.authenticated;
  const accessToken = useSelector(
    (state: RootState) => state.auth.access_token
  );
  const playerModalRef = useRef<Modalize>(null);

  useEffect(() => {
    //console.warn("accessToken", accessToken);
    refetch();
  }, [accessToken]);

  if (status === "loading") {
    return (
      <View>
        <Text>Loading</Text>
      </View>
    );
  }

  return (
    <AuthenticatedUserContext.Provider value={data}>
      <Host>
        <View style={{ flex: 1, backgroundColor: "black" }}>
          <View
            style={{
              flex: 1,
              borderWidth: 0,
              borderColor: "red",
              //marginTop: 64,
              backgroundColor: Theme.colors.background,
              // borderTopEndRadius: 8,
              // borderTopLeftRadius: 8,
              overflow: "hidden",
              flexDirection: "row",
            }}
          >
            {screenProperties.screen.large && <Sidebar />}
            <AppNavigator
              leftMenu={screenProperties.menu.left}
              //leftMenuComponent={() => <Sidebar />}
              musicPlayer={useMusicPlayer}
              screenProperties={screenProperties}
              user={data}
              turboApp={props.turboApp}
            />
            {appContext.navigation.rightMenu && isLoggedIn && (
              <SafeAreaView
                style={{
                  backgroundColor: "#000", // Theme.colors.background,
                  borderLeftColor: Theme.colors.chrome,
                  borderLeftWidth: 0.5,
                  width: "28%", //screenProperties.menu.sideBarWidth,
                  maxWidth: 460,
                  //flexGrow: 0.5,
                  //flex: 1,
                }}
              >
                <SidebarActivity />
              </SafeAreaView>
            )}
          </View>
        </View>
        {/*{Platform.OS === "web" &&*/}
        {/*  useMusicPlayer &&*/}
        {/*  screenProperties?.screen?.large && (*/}
        {/*    <View*/}
        {/*      style={{*/}
        {/*        height: 88,*/}
        {/*        backgroundColor: Theme.colors.chrome,*/}
        {/*        borderTopColor: Theme.colors.separator,*/}
        {/*        borderTopWidth: 1,*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <PlayerBar />*/}
        {/*    </View>*/}
        {/*  )}*/}
        {useMusicPlayer && screenProperties.screen.large && (
          <>
            <PlayerMiniCompat
              // navigation={navigation}
              modalRef={playerModalRef}
            />
            <PlaybackPositionSync />

            <PlayerScreenCompat modalize={true} playerRef={playerModalRef} />
          </>
        )}
      </Host>
    </AuthenticatedUserContext.Provider>
  );
};
