import { combineReducers } from "redux";
import auth from "@app/user/reducers/auth.reducer";
import player from "@app/music/reducers/player.reducer";
import navigation from "@app/config/navigation/reducers/navigation.reducer";

const rootReducer = combineReducers({
  auth,
  player,
  navigation,
});

export default rootReducer;
