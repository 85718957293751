import { instance, configure, clear } from "@app/config/api/api";

export function getGame(id: number): any {
  return instance.request<ServerData>({
    url: "game/" + id,
    params: {},
  });
}

export function getUserHasGameInCollection(id: number): any {
  return instance.request<ServerData>({
    url: "game/" + id + "/user-status",
    params: {},
  });
}

export function addGameToUserCollection(id: number): any {
  return instance.request<ServerData>({
    url: "game/" + id + "/add",
    params: {},
    method: "post",
  });
}

export function getGames(page: number, params: Object = {}): any {
  const data = { ...{ page }, ...params };
  return instance
    .request<ServerData>({
      url: "games",
      params: data,
    })
    .catch((e) => console.error(e));
}

export function getMyCollections(page: number, params: Object = {}): any {
  const data = { ...{ page }, ...params };
  return instance
    .request<ServerData>({
      url: "me/collections",
      params: data,
    })
    .catch((e) => console.error(e));
}

export function getCollection(id: number): any {
  return instance.request<ServerData>({
    url: "collection/" + id,
    params: {},
  });
}

export function getPlatforms(page?: number): any {
  return instance
    .request<ServerData>({
      url: "platforms",
      params: {},
    })
    .catch((e) => console.error(e));
}

export function getPlatform(id: number): any {
  return instance.request<ServerData>({
    url: "platform/" + id,
    data: {},
  });
}

export function getProductCode(code: string): any {
  return instance.request<ServerData>({
    url: "games/scan/",
    data: { code },
    method: "post",
  });
}
