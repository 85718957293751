import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ScrollView, View } from "react-native";
import {
  AvatarUpload,
  BackButton,
  BoxResponsive,
  CellGroup,
  CellItem,
  Spinner,
  Text,
} from "@app/ui";
import { useNavigation } from "@react-navigation/native";
import { find } from "lodash";
import { useApiRequest } from "@app/config/api/api";

interface Props {
  id: any;
}

export default (props: Props) => {
  const navigation = props.navigation || useNavigation();
  const url = `page/${props.route.params.id}/manager`;

  const [{ data, loading, error }, fetch] = useApiRequest(
    {
      url: url,
      method: "GET",
    },
    { manual: false }
  );

  const coverImage = find(data?.data?.media, { type: "cover" });
  const heroImage = find(data?.data?.media, { type: "hero" });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Text>Error</Text>;
  }

  const page = data?.data;
  const podcasts = page.header?.data?.podcasts;

  return (
    <ScrollView>
      <BoxResponsive>
        <View style={{ marginHorizontal: 20 }}>
          <AvatarUpload
            image={heroImage?.url}
            uploadUrl={url}
            attributeName={"hero"}
            //width={120}
            aspectRatio={heroImage?.aspect_ratio}
          />
          <Text>Hero</Text>
        </View>

        <View style={{ marginHorizontal: 20 }}>
          <AvatarUpload
            image={coverImage?.url}
            uploadUrl={url}
            attributeName={"cover"}
            width={120}
            aspectRatio={coverImage?.aspect_ratio}
          />
          <Text>Avatar</Text>
        </View>

        <CellGroup title="Profile">
          <CellItem
            title="Status"
            accessoryText={page?.status}
            onPress={() => null}
          />
          <CellItem
            title="Info"
            disclosure={true}
            onPress={() =>
              navigation.push("PageDetailsEditor", { id: page?.id })
            }
          />
        </CellGroup>

        <CellGroup
          title={"Podcasts"}
          accessory={() => (
            <BackButton
              symbol={"game-crosshair-solid"}
              onPress={() =>
                navigation.push("PodcastCreate", { page: page?.id })
              }
            />
          )}
        >
          {podcasts.map((podcast) => {
            const cover = find(podcast.media, { type: "cover" });
            return (
              <CellItem
                title={podcast?.name}
                accessoryText={podcast?.status}
                onPress={() =>
                  navigation.push("PodcastEditor", {
                    id: podcast.id,
                  })
                }
                image={cover?.thumb_url}
                disclosure={true}
              />
            );
          })}
        </CellGroup>
      </BoxResponsive>
    </ScrollView>
  );
};
