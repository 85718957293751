"use strict";
import React, { Component, ReactNode } from "react";
import { StyleProp, View } from "react-native";
import {
  useContentMaxWidth,
  useContentWidth,
} from "@app/utils/hooks/screen_dimensions";

interface Props {
  children?: ReactNode;
  borderRadius?: number;
  contentWidth?: number;
  overflow?: string;
  style?: StyleProp<any>;
}

interface State {}

const BoxResponsive = ({
  children,
  borderRadius = 0,
  overflow = "visible",
  contentWidth,
  style,
  ...props
}: Props) => {
  return (
    <View
      {...props}
      style={[
        style,
        {
          maxWidth: contentWidth || useContentMaxWidth(),
          alignSelf: "center",
          width: useContentWidth(),
          //flex: 1,
          borderRadius: borderRadius,
          //overflow: this.props.overflow,
        },
      ]}
    >
      {children}
    </View>
  );
};

export default BoxResponsive;
