import { Track } from "@app/music/models/track";
import { Appearance, SuperAppearance } from "@app/music/screens/player_compat";
import { Theme } from "@app/ui";
import { Root } from "postcss";

export interface RootState {
  auth: AuthState;
  player: PlayerState;
  navigation: NavigationState;
}

export interface AuthState {
  logged_in: boolean;
  loading: boolean;
  access_token?: string;
  user?: AuthUserState;
}

export interface AuthUserState {
  id: number;
  authenticated: boolean;
  profile: UserProfileState;
  accounts: Array<UserProfileState>;
  features: Array<string>;
}

export interface UserProfileState {
  name: string;
  username?: string;
  country?: string;
  avatar?: string;
}

export interface UserAccountState {
  platform: string;
  account_id: string;
  public?: boolean;
}

export interface PlayerState {
  queue: Track[];
  playback: PlayerPlaybackState;
  appearance: SuperAppearance;
}

export interface PlayerPlaybackState {
  isPlaying: boolean;
  isBuffering: boolean;
  state: string;
  currentTrack?: Track;
  previousTrack?: Track;
  nextTrack?: Track;
}

const auth: AuthState = {
  logged_in: false,
  loading: false,
  access_token: undefined,
  user: undefined,
};

export const defaultAppearance: SuperAppearance = {
  theme: "dark",
  colors: {
    primary: Theme.colors.chrome,
    secondary: Theme.colors.text,
    tertiary: "red",
    text: "#fff",
  },
  text: {
    primary: "#fff",
    secondary: "#fff",
    tertiary: "#fff",
  },
};

const player: PlayerState = {
  queue: [],
  playback: {
    isPlaying: false,
    isBuffering: false,
    state: null,
    currentTrack: null,
    previousTrack: null,
    nextTrack: null,
  },
  appearance: defaultAppearance,
};

export interface NavigationState {
  layout?: {};
  state?: any;
}

const navigation: NavigationState = {
  layout: {},
  state: null,
};

const rootState: RootState = {
  auth: auth,
  player: player,
  navigation: navigation,
};

export default rootState;
