import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { GlassView, Text } from "@app/ui";
import { ToastProvider } from "react-native-toast-notifications";

export default ({ children }) => {
  return (
    <ToastProvider
      placement="top"
      offsetTop={44}
      offsetBottom={22}
      renderToast={(options) => {
        const height = 44;
        return (
          <View
            style={{
              borderRadius: height / 2,
              height,
              overflow: "hidden",
              margin: 1,
            }}
          >
            <GlassView
              intensity={100}
              style={{
                opacity: 1,
                borderRadius: 44,
                height,
                paddingHorizontal: 8,
                minWidth: 120,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text>{options.message}</Text>
            </GlassView>
          </View>
        );
      }}
    >
      {children}
    </ToastProvider>
  );
};
