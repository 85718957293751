/* eslint-disable global-require */
/* eslint-disable no-undef */
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./rootReducer";
import initialState, { RootState } from "@app/config/redux/initialState";
import { persistStore, persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: "user",
  // Storage Method (React Native)
  storage: AsyncStorage,
  // // Whitelist (Save Specific Reducers)
  whitelist: ["auth"],
  // // Blacklist (Don't Save Specific Reducers)
  // blacklist: [''],
};

const logger = createLogger({
  stateTransformer: (state: RootState) => {
    if (state.auth!.access_token) {
      state.auth.access_token = "---";
    }

    delete state._persist;

    return state;
  },
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = [thunk];
//const Reactotron = require("@app/config/reactotron/reactroton").default;

if (__DEV__) {
  //const reduxImmutableStateInvariant = require("redux-immutable-state-invariant").default();
  //const createDebugger = require("redux-flipper").default;

  middleware = [...middleware, logger];
} else {
  middleware = [...middleware];
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore() {
  return createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)) //, Reactotron.createEnhancer())
  );
}

const store = configureStore();

export default store;
