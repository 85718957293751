import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Animated, Platform, SafeAreaView, View } from "react-native";
import { GlassView, Text, Theme, TurboSymbol } from "@app/ui";
import * as NavigationBar from "expo-navigation-bar";
import { BottomTabBarProps } from "@react-navigation/bottom-tabs/lib/typescript/src/types";
import {
  PlaybackPositionSync,
  PlayerMiniCompat,
  PlayerScreenCompat,
} from "@app/music";
import { Portal } from "react-native-portalize";
import {
  BottomTabBar,
  createBottomTabNavigator,
} from "@react-navigation/bottom-tabs";
import {
  useAuthenticatedUser,
  useHasAppFeature,
  usePlayerStatus,
} from "@app/user/hooks/user";
import { Environment } from "@app/config";
import { Modalize } from "react-native-modalize";
import FontAwesome5Pro from "react-native-vector-icons/FontAwesome5Pro";
import {
  ActivityStackScreen,
  CollectionsStackScreen,
  GamesStackScreen,
  HomeStackScreen,
  MusicStackScreen,
  NewsStackScreen,
  UsersStackScreen,
} from "@app/config/navigation/screens";
import {
  useLeftMenu,
  useScreenProperties,
} from "@app/utils/hooks/screen_dimensions";
import { useSafeAreaInsets } from "react-native-safe-area-context";

let PlayerBar: JSX.IntrinsicAttributes | null = null;
if (Platform.OS === "web" && Environment.features.music.enabled) {
  PlayerBar = require("@app/music/components/player_bar").default;
}

const Tab =
  Platform.OS === "android"
    ? createBottomTabNavigator() //createMaterialBottomTabNavigator()
    : createBottomTabNavigator();

const showTabLabels = false;

interface Props {
  musicPlayer: boolean;
}

export default ({ musicPlayer = true }: Props) => {
  const leftMenu = useLeftMenu();

  const playerStatus = usePlayerStatus();
  const authenticatedUser = useAuthenticatedUser();
  const canChat = useHasAppFeature("chat");
  const [tabUserName, setTabUserName] = useState(
    authenticatedUser?.profile?.name
  );

  React.useEffect(() => {
    const name = authenticatedUser?.profile?.name || "Me";
    setTabUserName(name);
  }, [authenticatedUser]);

  React.useLayoutEffect(() => {
    if (Platform.OS === "android") {
      NavigationBar.setBackgroundColorAsync(
        playerStatus?.appearance?.colors?.primary
      );
    }
  }, [playerStatus?.appearance?.colors?.primary]);

  const playerModalRef = useRef<Modalize>(null);
  const screenProperties = useScreenProperties();
  const insets = useSafeAreaInsets();
  const iOSBarHeight = 50 + insets.bottom;

  const tabOptions = {
    tabBarHideOnKeyboard: true,
    tabBarShowLabel: showTabLabels,
    tabBarActiveTintColor: "#fff",
    tabBarInactiveTintColor: "#ffffff",
    // style: {
    //   marginTop: 64,
    // },
    tabBarLabelStyle: {
      fontFamily: Theme.font.medium,
    },
    tabBarStyle: {
      backgroundColor: playerStatus?.appearance?.colors?.primary,
      ...Platform.select({
        android: {
          height: 60,
        },
        ios: {
          backgroundColor: "transparent",
          height: iOSBarHeight,
        },
      }),
      display: leftMenu ? "none" : "flex",
    },
  };

  const customBar = ({
    state,
    descriptors,
    navigation,
    insets,
  }: BottomTabBarProps) => {
    const tabStyle = leftMenu
      ? {
          height: 0,
          overflow: "hidden",
          visibility: "hidden",
          opacity: 0,
          display: "none",
        }
      : { display: "flex" };

    return (
      <Animated.View
        style={{
          backgroundColor: playerStatus?.appearance?.colors?.primary,
          borderTopWidth: 0,
          //borderColor: "rgba(255,255,255,0.15)",
          //borderTopLeftRadius: 14,
          //borderTopRightRadius: 14,
        }}
      >
        <GlassView tint={Theme.dark ? "dark" : "light"} intensity={0}>
          {musicPlayer && screenProperties.screen.mobile && (
            <>
              <PlayerMiniCompat
                //height={screenProperties.screen.large ? 60 : 60}
                navigation={navigation}
                modalRef={playerModalRef}
              />
              <PlaybackPositionSync />
              <Portal>
                {/*<Modalize*/}
                {/*  ref={playerModalRef}*/}
                {/*  panGestureAnimatedValue={animated}*/}
                {/*  withHandle={false}*/}
                {/*  handlePosition={"inside"}*/}
                {/*  childrenStyle={{*/}
                {/*    margin: 0,*/}
                {/*    padding: 0,*/}
                {/*  }}*/}
                {/*  modalStyle={{*/}
                {/*    backgroundColor: "transparent",*/}
                {/*    // margin: 0,*/}
                {/*    //borderWidth: 1,*/}
                {/*    borderColor: "yellow",*/}
                {/*    // marginTop: 0,*/}
                {/*    // marginBottom: 0,*/}
                {/*    minHeight: "100%",*/}
                {/*  }}*/}
                {/*  rootStyle={{*/}
                {/*    // borderWidth: 1,*/}
                {/*    // borderColor: "red",*/}
                {/*    // zIndex: 9999,*/}
                {/*    // position: "absolute",*/}
                {/*    flex: 1,*/}
                {/*  }}*/}
                {/*  modalTopOffset={0}*/}
                {/*>*/}
                <PlayerScreenCompat
                  modalize={true}
                  playerRef={playerModalRef}
                />
              </Portal>
            </>
          )}
          {/*{musicPlayer &&*/}
          {/*  Platform.OS === "web" &&*/}
          {/*  screenProperties.screen.mobile && (*/}
          {/*    <View style={{ height: 60 }}>*/}
          {/*      <PlayerBar />*/}
          {/*    </View>*/}
          {/*  )}*/}
          {/*{screenProperties.screen.large && (*/}
          {/*  <SafeAreaView*/}
          {/*    style={{ backgroundColor: Theme.colors.chrome, borderWidth: 0 }}*/}
          {/*  />*/}
          {/*)}*/}

          <BottomTabBar
            state={state}
            descriptors={descriptors}
            navigation={navigation}
            insets={insets}
            activeTintColor={playerStatus.appearance.colors.text}
            // inactiveTintColor={tinycolor(playerStatus.appearance.colors.text)
            //   .setAlpha(0.3)
            //   .toRgbString()}
            showLabel={showTabLabels}
            labelStyle={{
              fontFamily: Theme.font.regular,
              ...Platform.select({
                android: {
                  fontSize: 11,
                  top: -4,
                },
              }),
            }}
            style={{
              backgroundColor: playerStatus?.appearance?.colors?.primary, // "transparent",
              borderTopColor: Theme.colors.chromeHigh,
              borderTopWidth: 0,
              ...Platform.select({
                web: {},
                android: {
                  height: 60,
                },
                ios: {},
              }),
              ...tabStyle,
            }}
          />
        </GlassView>
      </Animated.View>
    );
  };

  return (
    <Tab.Navigator
      tabBar={customBar}
      //tabBarOptions={tabOptions}
      screenOptions={({ route }) => ({
        ...tabOptions,
        showLabel: showTabLabels,
        keyboardHidesTabBar: true,
        headerShown: false,
        tabBarIcon: ({ color, size, focused }) => {
          let iconSize = showTabLabels ? 22 : 26;
          if (Platform.OS === "web") {
            //iconSize = 0;
          }
          const useTk = true;

          interface IconsMap {
            [name: string]: string;
          }

          const icons: IconsMap = {
            HomeTab: "rectangle-portrait", //"home-alt",
            MusicTab: "album-collection", //"headphones",
            NewsTab: "newspaper", //"headphones",
            GamesTab: "alien-monster", //"gamepad",
            CollectionsTab: "box", //"layer-group", //"gamepad",

            ActivityTab: "comment",
            MeTab: "smile", //"user-circle",
          };

          const iconsTk: IconsMap = {
            HomeTab: "home2-line",
            MusicTab: "music-collection-solid",
            NewsTab: "news-solid",
            GamesTab: "pacman-solid", //"gamepad",
            CollectionsTab: "collections-solid", //"layer-group", //"gamepad",
            ActivityTab: "messenger-solid",
            MeTab: Environment.id + "-solid", //"user-circle",
          };

          let iconName = useTk ? iconsTk[route.name] : icons[route.name];
          const iconColor = playerStatus?.appearance?.colors?.text || "#fff";
          const opacity = focused ? 1 : 0.3;

          if (useTk) {
            return (
              <TurboSymbol
                name={iconName}
                size={iconSize}
                color={iconColor}
                style={{ opacity }}
              />
            );
          }

          return focused ? (
            <FontAwesome5Pro
              name={iconName}
              solid
              size={iconSize}
              // color={playerStatus?.appearance?.colors?.text}
            />
          ) : (
            <FontAwesome5Pro
              name={iconName}
              light
              size={iconSize}
              // color={playerStatus?.appearance?.colors?.text}
              style={{ opacity: 0.5 }}
            />
          );
        },
      })}
    >
      {Environment.features.music.enabled &&
        authenticatedUser?.authenticated && (
          <Tab.Screen
            name="HomeTab"
            component={HomeStackScreen}
            options={{ tabBarLabel: "For me" }}
          />
        )}
      {Environment.features.games.enabled && (
        <Tab.Screen
          name="NewsTab"
          component={NewsStackScreen}
          options={{ tabBarLabel: "News" }}
        />
      )}
      {Environment.features.games.enabled && (
        <Tab.Screen
          name="GamesTab"
          component={GamesStackScreen}
          options={{ tabBarLabel: "Games" }}
        />
      )}
      {Environment.features.games.enabled && (
        <Tab.Screen
          name="CollectionsTab"
          component={CollectionsStackScreen}
          options={{ tabBarLabel: "Collections" }}
        />
      )}
      {Environment.features.music.enabled && (
        <Tab.Screen
          name="MusicTab"
          component={MusicStackScreen}
          options={{ tabBarLabel: "Discover" }}
        />
      )}
      {canChat && (
        <Tab.Screen
          name="ActivityTab"
          component={ActivityStackScreen}
          options={{
            tabBarBadge: null,
            tabBarLabel: "Messages",
          }}
        />
      )}
      <Tab.Screen
        name="MeTab"
        component={UsersStackScreen}
        options={{ tabBarLabel: tabUserName }}
      />
    </Tab.Navigator>
  );
};
