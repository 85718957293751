"use strict";
import React, { Component, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Linking,
  Dimensions,
  SafeAreaView,
  Pressable,
} from "react-native";
import {
  TouchableOpacity,
  BorderlessButton,
  RectButton,
  BaseButton,
  ScrollView,
} from "react-native-gesture-handler";
import { includes } from "lodash";
import {
  StyleSheet,
  CellGroup,
  CellItem,
  BoxResponsive,
  Button,
} from "@app/ui";
import { Modalize } from "react-native-modalize";
import { Host, Portal } from "react-native-portalize";
import LoginModal from "@app/auth/components/login.modal";
import { connect, useDispatch, useSelector } from "react-redux";
import * as authActions from "@app/user/actions/auth.action";

import DeviceInfo from "react-native-device-info";
import { dashboardUrl } from "@app/config/api/api";
import { Environment, isWeb } from "@app/config";
import {
  useAuthenticatedUser,
  useHasAppFeature,
  useHasAppRole,
} from "@app/user/hooks/user";
//import codePush, { LocalPackage } from "react-native-code-push";

interface Props {
  componentId: string;
  user?: Object;
  loggedIn: boolean;
}

interface State {
  loginModalVisible: boolean;
}

const MeScreen = (props: Props) => {
  const { navigation } = props;
  const dispatch = useDispatch();

  const user = useAuthenticatedUser();
  const isLoggedIn = user?.authenticated;

  const [loginModalVisible, setLoginModalVisible] = useState(false);

  const canUsePayments = useHasAppFeature("payments");
  const isAdmin = useHasAppRole("admin");

  const renderLoginModal = () => {
    return (
      <LoginModal
        isVisible={loginModalVisible}
        onClose={() => {
          setLoginModalVisible(false);
        }}
      />
    );
  };

  const getAppVersionString = () => {
    let main =
      DeviceInfo.getApplicationName() +
      " v" +
      DeviceInfo.getVersion() +
      "+" +
      DeviceInfo.getBuildNumber();

    if (codePushInfo) {
      main += " ~ " + codePushInfo.appVersion + " " + codePushInfo.label;
      console.warn("push", codePushInfo);
    }

    return main;
  };

  const [appVersionString, setAppVersionString] = useState(
    getAppVersionString()
  );
  const [codePushInfo, setCodePushInfo] = useState(null);

  // codePush.getUpdateMetadata().then((update?: LocalPackage) => {
  //   setCodePushInfo(update);
  // });

  const canOpenAdministration = () => {
    return useHasAppRole("admin");
  };

  const askForLogout = () => {
    if (isWeb) {
      if (confirm("Are you sure?")) {
        dispatch(authActions.logout());
      }
    } else {
      Alert.alert("Logout", "Are You Sure?", [
        {
          text: "No",
          style: "cancel",
        },
        {
          text: "Yes",
          onPress: () => dispatch(authActions.logout()),
        },
      ]);
    }
  };

  const renderLoggedIn = () => {
    return (
      <BoxResponsive>
        <CellGroup title="My Account" key="account">
          <CellItem
            title="My Profile"
            accessoryText={user?.profile?.name || "User"}
            disclosure={true}
            iconName="smile"
            onPress={() => null}
          />
          {canUsePayments && (
            <CellItem
              title="My Music"
              disclosure={true}
              symbol="payment-solid"
              onPress={() => navigation.push("MusicStylePicker")}
            />
          )}
          {canUsePayments && (
            <CellItem title="Wallet" disclosure={true} symbol="payment-solid" />
          )}
          {canUsePayments && (
            <CellItem
              title="My Subscriptions"
              disclosure={true}
              symbol="payment-solid"
            />
          )}
          <CellItem
            title="Configuration"
            disclosure={true}
            symbol="settings-line"
          />
          {/*{canUsePayments && (*/}
          {/*  <CellItem*/}
          {/*    title="Payment Methods"*/}
          {/*    disclosure={true}*/}
          {/*    symbol="payment-solid"*/}
          {/*  />*/}
          {/*)}*/}
        </CellGroup>

        {canOpenAdministration() && (
          <CellGroup title="Manager" key="manager">
            <CellItem
              title="My Pages"
              disclosure={true}
              symbol="pages-solid"
              onPress={() => navigation.push("PagesMine")}
            />
            <CellItem
              title="My Podcasts"
              disclosure={true}
              symbol="pages-solid"
            />

            {/*<CellItem*/}
            {/*  title="Manage"*/}
            {/*  onPress={() =>*/}
            {/*    navigation.push("WebView", {*/}
            {/*      url: dashboardUrl("/"),*/}
            {/*    })*/}
            {/*  }*/}
            {/*  //iconName={"solar-panel"}*/}
            {/*  symbol={"manager-line"}*/}
            {/*  disclosure={true}*/}
            {/*/>*/}

            {/* <CellItem
            title="Large items goes here without multi line hahahahah"
            accessoryText="Hola que tal"
          /> */}
          </CellGroup>
        )}

        <CellGroup title="About" key="about">
          <CellItem
            title="Credits"
            iconName="search"
            onPress={() => Linking.openURL("https://" + Environment.host)}
          />
          <CellItem
            title="Terms & Conditions"
            iconName="file-contract"
            onPress={() =>
              Linking.openURL("https://" + Environment.host + "/terms")
            }
          />
          <CellItem
            title="Privacy Policy"
            iconName="user-secret"
            onPress={() =>
              Linking.openURL("https://" + Environment.host + "/privacy")
            }
          />
        </CellGroup>

        <CellGroup key="other">
          <CellItem title="Logout" onPress={askForLogout} />
        </CellGroup>

        <CellGroup title={getAppVersionString()} key="app_version" />
      </BoxResponsive>
    );
  };

  const renderNotLoggedIn = () => {
    return (
      <View style={{ paddingHorizontal: 26, paddingTop: 8 }}>
        <Text style={{ color: "#fff" }}>Not Logged In</Text>
        <Pressable onPress={() => setLoginModalVisible(true)}>
          <Text style={styles.link}>Login</Text>
        </Pressable>
        {renderLoginModal()}
      </View>
    );
  };

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     dispatch(authActions.getMe());
  //   }
  // }, [isLoggedIn]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={{ flex: 1 }}>
        {isLoggedIn && user && renderLoggedIn()}
        {!isLoggedIn && renderNotLoggedIn()}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  link: {
    marginVertical: 16,
    color: "blue",
    //color: "$textColor",
  },
});

export default MeScreen;

//export default connect(mapStateToProps, mapDispatchToProps)(MeScreen);
