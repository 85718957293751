import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { Track } from "@app/music/models/track";
import store from "@app/config/redux/store";
import { useSelector } from "react-redux";

const setupPlayer = () => {};

const addTrack = (track: Track) => {
  return store.dispatch({
    type: "add_track",
    track,
  });
};

const usePlayerQueue = () => {
  return useSelector((state) => state.player.queue);
};

export { addTrack, setupPlayer, usePlayerQueue };
